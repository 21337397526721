import React from "react";
import _ from "lodash";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { internationals,local } from "../../../data/advisors";

const Advisors = () => {
 
  return (
    <div className='py-36 w-full flex flex-col items-center '>
     
        <div className="flex flex-col justify-center items-center  md:w-[90%] xl:w-[90%] space-y-10">
            <p className="text-[30px] sm:text-[24px] xs:text-[20px] font-bold text-[#0863AA]">Academic advisors</p>
            <p className="text-[18px] sm:text-[15px] xs:text-[14px] mx-10 text-justify">The Benadir University Distinguished Advisory Board is an advisory body, comprised of University alumni and friends, dedicated to supporting and advancing the mission of and highest standards of excellence in the University. University Advisors act as informed advisors to the Trustees and administration on issues of strategic importance through active membership, as requested by the University, in advisory, virtually visiting, and review boards for major programs of the University. Advisors serve as a University resource by volunteering leadership, expertise, and national & international academic support to the areas of the University in which they serve. Advisors also act as ambassadors and promote the interests of the University worldwide.</p>
        </div>
    <div  className="text-black text-justify my-10">
    <div className="md:mx-10 xl:mx-10">
    <Tabs className="w-ful ">
      <TabList className="flex flex-row justify-center font-bold  w-full ">
        <Tab selectedClassName="bg-[#0863AA] text-white" className="px-10 py-2 text-xl sm:text-[20px] xs:text-[13px] text-[#0863AA] hover:cursor-pointer  hover:bg-[#0863AA] hover:text-white">Local advisors</Tab>
        <Tab selectedClassName="bg-[#0863AA] text-white" className="px-10 py-2 text-xl xs:text-[13px] text-[#0863AA] hover:cursor-pointer hover:bg-[#0863AA] hover:text-white">International advisors</Tab>
      </TabList>
      <TabPanel>
      <div className="grid md:grid-cols-3 xl:grid-cols-4 grid-cols-1 gap-[100px] my-10  xs:place-items-center sm:place-items-center ">
            {
                local.map((a) =>(
            <div key={a.id} className="py-3 flex flex-col space-y-3 text-[14px]">
                <img src={a.avatar} alt="avatar" className="rounded-md h-[270px] w-[200px]" />
                <div className="">
                <p>{a.name}</p>
                { a.occ.map((o)=>(
                    <>
                    <span key={o.id} className="">
                        {o.title}<br/>{o.place}
                    </span>
                    <br/>
                    </>
                    )) }
            </div>  
            </div>  
                ))
            }
        </div>
       
      </TabPanel>
      <TabPanel>
      <div className="grid md:grid-cols-3 xl:grid-cols-4 grid-cols-1 gap-[100px] my-10 xs:place-items-center sm:place-items-center">
            {
                internationals.map((a) =>(
            <div key={a.id} className="py-3 flex flex-col space-y-3 text-[14px]">
                <img src={a.avatar} alt="avatar" className="rounded-md h-[320px] w-[250px]" />
                <div className=" ">
                <p>{a.name}</p>
                { a.occ.map((o)=>(
                    <>
                    <span key={o.id} className="">
                        {o.title}<br/>{o.place}
                    </span>
                    <br/>
                    </>
                    )) }
            </div>  
            </div>  
                ))
            }
        </div>
      
      </TabPanel>
  </Tabs>
    </div>
</div>
</div>
);
};

export default Advisors;
