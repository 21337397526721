import React, { useState, useEffect } from "react";
import { useLocation, useNavigate,Link } from "react-router-dom";
import { IoMdClose } from "react-icons/io";
import * as yup from "yup";
import api from "../../../config.json";
import { Formik, Field } from "formik";
import { RenderUploadButton } from "../../general/renderSingleFile";
import { RenderMultipleUploadButton } from "../../general/renderMultipleFiles";
import { updateActivity } from "../../../services/activityServices";
import {programs} from "../../../data/programs"
import PageTitle from "../../general/pageTitle";



const FILE_SIZE = 1024;
const MAX_FILE_SIZE = 1024 * 1024 * 3;
function EditActivity() {
  const { state } = useLocation();
  const [titleImageFile, setTitleImageFile] = useState();
  const [imageLists, setImageLists] = useState([]);

  const navigate = useNavigate();

  const validFileExtensions = {
    image: ["jpg", "png", "jpeg"],
  };

  function isValidFileType(fileName, fileType) {
    return (
      fileName &&
      validFileExtensions[fileType].indexOf(fileName.split(".").pop()) > -1
    );
  }

  const validationSchema = yup.object().shape({
    faculty: yup.string("select faculty").required("faculty is required"),
    department: yup.string("Enter department").required("department is required"),
    title: yup.string("Enter news title").required("title is required"),
    content: yup.string("Enter news content").required("content is required"),
    updatedby: yup.string("Enter updated by").required("Updated by required"),
    titleImage: yup
      .mixed()
      .required("upload title image.")
      .test("is-valid-type", "Not a valid image type", (value) =>
        isValidFileType(value && value.name, "image")
      )
      .test(
        "FILE_SIZE",
        "Max allowed size is 3MB",
        (value) => !value || (value && value.size <= MAX_FILE_SIZE)
      ),

    imageList: yup
      .mixed()
      .required("upload atleas one image.")
      .test("is-valid-type", "Not a valid image type", (value) => {
        if (!value || (value && value?.length > 0)) {
          for (let i = 0; i < value.length; i++) {
            if (!isValidFileType(value && value[i].name, "image")) {
              return false;
            }
          }
        }
        return true;
      })
      .test("FILE_SIZE", "Max allowed size is 3MB", (value) => {
        if (!value || (value && value?.length > 0)) {
          for (let i = 0; i < value.length; i++) {
            if (value[i].size <= MAX_FILE_SIZE) {
              return true;
            }
          }
        }
        return false;
      }),
  });

  // const url = "http://localhost:4000/";
  const url = api.url;

  function GetFilename(url) {
    if (url) return url.substring(url.lastIndexOf("\\") + 1);
    return "";
  }

  useEffect(() => {
    async function createFile(url, path) {
      if (path !== null || path !== undefined) {
        const fileName = GetFilename(path);
        const path1 = path.replace(/\\/g, "/");
        const response = await fetch(url + path1);
        const data = await response.blob();
        let metadata = {
          type: data.type || "image/jpeg",
        };
        let file = new File([data], fileName, metadata);
        setTitleImageFile(file);
      }
    }
    createFile(url, state.activity.titleImageUrl);

    async function createFileList(url, path) {
      setImageLists([]);
      if (path !== null || path !== undefined) {
        var images = [];

        path.forEach(async (e) => {
          const fileName = GetFilename(e.imageUrls);
          const path1 = e.imageUrls.replace(/\\/g, "/");
          const response = await fetch(url + path1);
          const data = await response.blob();
          let metadata = {
            type: data.type || "image/jpeg",
          };
          let file = new File([data], fileName, metadata);
          images.push(file);
        });

        setImageLists(images);
      }
    }
    createFileList(url, state.activity.imageList);
  }, []);

  return (
    <div>
      <PageTitle title="Edit activity" subTitle="activity" />
      <div className="flex flex-col justify-center p-4 ">
        <Formik
          enableReinitialize={true}
          initialValues={{
            faculty: state.activity.faculty,
            department: state.activity.department,
            title: state.activity.title,
            tag: state.activity.tag,
            titleImage: titleImageFile,
            content: state.activity.activityContent,
            imageList: imageLists,
            updatedby: "",
          }}
          validationSchema={validationSchema}
          onSubmit={async (values, actions) => {
            if (values.faculty === "--select faculty--") {
              actions.setSubmitting(false);
              actions.setErrors({ faculty: `please select correct faculty` });
              return;
            }
            const resp = await updateActivity({
              activityId: state.activity.activityId,
              _id: state.activity._id,
              ...values,
            });
            navigate("/myactivity");
          }}
        >
          {({
            values,
            setFieldValue,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
            <form
            onSubmit={handleSubmit}
            className="w-full flex justify-start flex-col items-start border shadow-sm rounded-xl"
          >
            <div className="flex justify-between items-center py-2.5 px-4 border-b w-full">
                  <h3 className="font-bold text-gray-800">
                    Edit activity
                  </h3>
                  <Link
                    to="/myactivity"
                    className="inline-flex flex-shrink-0 justify-center items-center h-8 w-8 rounded-md text-gray-500 hover:text-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 focus:ring-offset-white transition-all text-sm"
                  >
                    <IoMdClose />
                  </Link>
                </div>
              <div className="p-4 w-full">

              
            <div className="w-full mt-10">
                    <select
                      name="faculty"
                      value={values.faculty}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="form-select w-full"
                    >
                      <option value="--select faculty--">
                        --select faculty--
                      </option>
                      {programs[0].lists.map( (n,i) => (
                        <option key={i} value={n.program}>{n.program}</option>
                        ))}
                      
                    </select>
                    {errors.faculty && touched.faculty && errors.faculty}
                  </div>
                 
            <div className="w-full mt-10">
              <input
                placeholder="Enter department"
                name="department"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.department}
                className="placeholder:italic placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
              />
              {errors.department && touched.department && errors.department}
            </div>
            <div className="w-full mt-10">
              <input
                placeholder="Enter activity title"
                name="title"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.title}
                className="placeholder:italic placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
              />
              {errors.title && touched.title && errors.title}
            </div>

            <div className="w-full mt-10">
              <input
                placeholder="Enter activity tag"
                name="tag"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.tag}
                className="placeholder:italic placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
              />
              {errors.tag && touched.tag && errors.tag}
            </div>
            <div className="my-10 border-2 shadow-lg w-full p-3">
              {RenderUploadButton(
                setFieldValue,
                values,
                errors,
                "titleImage",
                "image"
              )}
              {errors.titleImage && touched.titleImage && errors.titleImage}
            </div>
            {/* <Tabtest handleBlur={handleBlur} handleChange={handleChange} touched={touched} values={values} errors={errors} className="w-full" /> */}
            <div className="w-full mt-10">
              <textarea
                className="my-2 placeholder:italic placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                placeholder="activity Content"
                name="content"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.content}
                rows="8"
                cols="50"
              />
              {errors.content && touched.content && errors.content}
            </div>
            <div className="my-10 border-2 shadow-lg w-full p-3">
              {RenderMultipleUploadButton(
                setFieldValue,
                values,
                errors,
                "imageList",
                "image"
              )}
              {errors.imageList && touched.imageList && errors.imageList}
              <hr className="my-3" />

              {values.imageList && (
                <div className="flex flex-row overflow-x-auto">
                  {[...values.imageList].map((f, i) => (
                    <div key={i} className="m-3 flex ">
                      <div className="flex w-fit justify-between mr-5  absolute bg-white  p-2 text-black font-bold">
                        {f.size > 1024 && f.size < 1000000 ? (
                          <span className="italic">{`${Math.round(
                            parseFloat(f.size) / FILE_SIZE
                          )} KB`}</span>
                        ) : (
                          <span className="italic">{`${Math.round(
                            parseFloat(f.size) / (1024 * 1024)
                          )} MB`}</span>
                        )}
                      </div>

                      <img
                        alt="not fount"
                        className="w-[550px]"
                        src={URL.createObjectURL(f)}
                      />
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div className="w-full mt-10">
              <input
                placeholder="Published by"
                name="updatedby"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.updatedby}
                className="placeholder:italic placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
              />
              {errors.updatedby &&
                touched.updatedby &&
                errors.updatedby}
            </div>
            <div className="w-full mt-12 space-y-3">
              <button
                type="submit"
                disabled={isSubmitting}
                className="bg-blue-500 text-[18px] font-medium text-white py-3 w-fit px-5  focus:outline-none rounded-[3px] uppercase hover:bg-[#465B53]"
              >
                Save
              </button>
            </div>
            </div>
          </form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default EditActivity;
