import React from "react";
import { FaAngleRight } from "react-icons/fa";


function PageTitle({ title, subTitle }) {
  return (
    <div className="flex justify-between items-center mx-4 my-5 mb-6 w-full font-base ">
      <h4 className="text-slate-900 text-lg font-medium">{title}</h4>
      <div className="md:flex xl:flex hidden items-center gap-3 text-sm font-semibold">
        <a href="" className="text-sm font-medium text-slate-700">
          BU
        </a>
        <FaAngleRight className="text-lg flex-shrink-0 text-slate-400" />
        <a href="" className="text-sm font-medium text-slate-700">
          {subTitle}
        </a>
        <FaAngleRight className="text-lg flex-shrink-0 text-slate-400" />
        <a
          href=""
          className="text-sm font-medium text-slate-700"
          aria-current="page"
        >
          {title}
        </a>
      </div>
    </div>
  );
}

export default PageTitle;
