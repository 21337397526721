import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { GrFormView } from "react-icons/gr";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { AiOutlineClose,AiOutlineWarning } from 'react-icons/ai';
import { deleteNews} from "../../../services/newsServices"
import api from "../../../config.json";
import _ from "lodash"

const EventCard = ({event}) => {
  const [togle, setTogle] = useState(false);
  const [confirm, setConfirm] = useState("");
  const [currentNews, setCurrentNews] = useState()
  const [cofirmError, setCofirmError] = useState('')

const Navigate = useNavigate()
  // const sortedNews = event.sort((a, b) => b.id - a.id);
  const sortedNews = _.orderBy(event, 'createdAt', 'desc');
  // const url = "http://localhost:4000/"
  const url = api.url

  const handleDelete = (n) => {
    setTogle(!togle)
    setCurrentNews(n)
  }
  const handleDelateNews = async(n) => {
    if(n){
    if( confirm === n.title){
      setCofirmError("")
      const resp = await deleteNews(n.newsId)
      setTogle(!togle)
      } 
      else{
        setCofirmError('pleaes confirm deletion!')
      }
    }
  }

  const showModel = () =>{
    setConfirm("")
    setCofirmError('')
    setTogle(!togle)
  }

  return (
    <>
    <div className="">
      {/* no name */}
      <div className="grid md:grid-cols-3 xl:grid-cols-4 sm:grid-cols-2 mx-4 md:mx-10 xl:mx-10 my-10 gap-4">
        {sortedNews.map((n) => (
          <div
            key={n.newsId}
            className="bg-white rounded-lg shadow-lg hover:border-2 hover:border-[#000] "
          >
            <div className="w-full flex justify-end">
              {/* <HiDotsVertical size={20} className="absolute mr-1 mt-1" /> */}
              <div className="absolute bg-white mr-2 mt-2 py-2 px-2 space-y-2 rounded-md">
                <Link to={`/event/${n.slug}`} state={{ event: n }}>
                  <GrFormView
                    size={20}
                    className="cursor-pointer m-1 hover:border-2 hover:shadow-md"
                  />
                </Link>
                <hr />
                <Link to={`/edit-event`} state={{ event: n }}>
                  <FaEdit
                    size={20}
                    className="cursor-pointer m-1 hover:border-2 hover:shadow-md"
                  />
                </Link>
                <hr />
                <Link onClick={() => handleDelete(n)}>
                  <MdDelete
                    size={20}
                    className="cursor-pointer m-1 hover:border-2 hover:shadow-md"
                  />
                </Link>
              </div>
            </div>
            <img
              src={url + n.titleImageUrl}
              className="rounded-t-lg object-cover md:w-[500px] md:h-[300px] xl:w-[500px] xl:h-[300px]"
              alt="not found"
            />
            <div className="px-6 mt-8 mb-10">
              <p className="font-bold text-[16px] md:text-[1.5rem] line-clamp-1">
                {n.title}
              </p>
              <p className="text-star  font-normal tracking-wider line-clamp-2">
                {n.newsContent}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
    <div className={`fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm flex justify-center items-center z-30 ease-in duration-300 ${togle ? "" : "hidden"}`} >

{/* <div className='bg-[#EDF4F4] xs:w-full  w-[300px] max:w-[500px]  overflow-auto pt-4 fixed '> */}
  <div className='flex flex-col justify-center '>

  <div className="flex flex-col bg-white border shadow-sm rounded-xl">
                                            <div className="flex justify-between items-center py-2.5 px-4 border-b">
                                                <h3 className="font-bold text-gray-800">
                                                    Delete event
                                                </h3>
                                                <button data-fc-dismiss type="button" className="inline-flex flex-shrink-0 justify-center items-center h-8 w-8 rounded-md text-gray-500 hover:text-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 focus:ring-offset-white transition-all text-sm" onClick={showModel}>
                                                    <AiOutlineClose className="text-xl" />
                                                </button>
                                            </div>
                                            <div className="p-4 overflow-y-auto flex flex-row">
                                              <div className="mx-10">
                                              <AiOutlineWarning  className="text-7xl text-danger"/>
                                              </div>
                                              <div className="mx-7">
                                                <p className="mt-1 text-gray-800">
                                                    Do you want to delete this event titled:
                                                    <br />
                                                    <span className="font-semibold">
                                                    {currentNews && currentNews.title}
                                                    </span>
                                                </p>
                                              </div>
                                            </div>
                                            
                                            <div className="w-full px-10 my-3">
                                              <input required
                                                placeholder="write the event title here "
                                                name="titleImageCaption"
                                                onChange={(e) => setConfirm(e.target.value)}
                                                value={confirm}
                                                className="placeholder:italic placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                                              />
                                              <div className="mx-4 my-2">
                                                  {cofirmError}
                                              </div>
                                            </div>
                                            <div className="flex justify-end items-center gap-x-2 py-2.5 px-4 border-t">
                                                <button data-fc-dismiss type="button" className="btn bg-secondary text-white" onClick={showModel}>
                                                    No
                                                </button>
                                                <button data-fc-dismiss type="button" className="btn bg-warning text-white" onClick={() => handleDelateNews(currentNews)}>
                                                    Yes
                                                </button>
                                               
                                            </div>
                                        </div>

  </div> 
  {/* </div/> */}

  </div>
    </>
  );
};

export default EventCard;
