import {med,cs,edu,Eng,Shl,Vet,Agr,EM,Den,Geo,ms,ss_ps, ss_pa,HS_ML, HS_Nur, HS_Nutr, HS_PHR,HS_Mw,HS_PH} from "./foundationSemesters"

export const courses = [

    {id: 1, faculty: "Faculty of Medicine and Surgery", desc:"The Foundation Year of the Faculty of Medicine and Surgery is an essential preparatory program that equips aspiring medical students with fundamental knowledge in sciences like biology and chemistry, alongside crucial study and communication skills. It bridges the gap between secondary education and medical school, offering practical experiences such as lab sessions and clinical skills workshops. This year also fosters resilience and adaptability, essential for the demanding nature of medical education, ensuring students are well-prepared for their medical journey.", dep:[
        {id: 1, deptName: "Department of Medicine and Surgery", desc: "",
            courses: [
                {id: 1, head: "Semester 1 - courses", sem1: med.Sem1},
                {id: 2,head: "Semester 2 - courses", sem2: med.Sem2}
            ]
        }
    ]},

    {id: 2, faculty: "Faculty of Computer Science and IT", desc:"The Foundation Year in the Faculty of Computer Science and IT equips students with essential knowledge in programming, mathematics, and basic computer science principles. It bridges the gap between high school and advanced IT studies, focusing on developing problem-solving and technical skills. This year ensures students are well-prepared for the challenges of a computer science degree.", dep:[
        {id: 1, deptName: "Department of Computer Science", desc: "",
            courses: [
                {id: 1, head: "Semester 1 - courses", sem1: cs.Sem1},
                {id: 2,head: "Semester 2 - courses", sem2: cs.Sem2}
            ]
        }
    ]},
    {id: 3, faculty: "Faculty of Education", desc:"In the Foundation Year of the Faculty of Education, students will explore educational theories, learning and development, and curriculum design. They will develop practical teaching strategies, digital literacy, and an understanding of diversity and inclusion in classrooms. Additionally, students will gain hands-on experience through classroom observations and enhance their communication and reflective practice skills.", dep:[
        {id: 1, deptName: "Department of Education", desc: "",
            courses: [
                {id: 1, head: "Semester 1 - courses", sem1: edu.Sem1},
                {id: 2,head: "Semester 2 - courses", sem2: edu.Sem2}
            ]
        }
    ]},
    {id: 4, faculty: "Faculty of Engineering and Technology", desc:"In the Foundation Year of the Faculty of Engineering and Technology, students will learn fundamental principles of mathematics, physics, and chemistry. They will be introduced to basic engineering concepts, problem-solving techniques, and computer programming. Additionally, students will develop practical skills through laboratory experiments and gain an understanding of the role of engineering in society.", dep:[
        {id: 1, deptName: "Department of Engineering and Technology", desc: "",
            courses: [
                {id: 1, head: "Semester 1 - courses", sem1: Eng.Sem1},
                {id: 2,head: "Semester 2 - courses", sem2: Eng.Sem2}
            ]
        }
    ]},
    {id: 5, faculty: "Faculty of Sharia and Law", desc:"In the Foundation Year of the Faculty of Sharia and Law, students will study the basics of Islamic jurisprudence, legal theory, and principles of Sharia. They will also explore the foundations of civil and criminal law, legal ethics, and critical thinking. Additionally, students will develop skills in legal research, analysis, and effective communication.", dep:[
        {id: 1, deptName: "Department of Sharia and Law", desc: "",
            courses: [
                {id: 1, head: "Semester 1 - courses", sem1: Shl.Sem1},
                {id: 2,head: "Semester 2 - courses", sem2: Shl.Sem2}
            ]
        }
    ]},
    {id: 6, faculty: "Faculty of Veterinary Science", desc:"In the Foundation Year of the Faculty of Veterinary Science, students will study the basics of animal biology, anatomy, and physiology. They will learn about animal health, nutrition, and disease prevention. Additionally, students will gain practical skills through laboratory work and develop an understanding of veterinary ethics and communication.", dep:[
        {id: 1, deptName: "Department of Veterinary Science", desc: "",
            courses: [
                {id: 1, head: "Semester 1 - courses", sem1: Vet.Sem1},
                {id: 2,head: "Semester 2 - courses", sem2: Vet.Sem2}
            ]
        }
    ]},
    {id: 7, faculty: "Faculty of Agriculture", desc:"In the Foundation Year of the Faculty of Agriculture, students will study the basics of plant and animal sciences, soil science, and agricultural technology. They will learn about sustainable farming practices, crop production, and livestock management. Additionally, students will gain practical experience through hands-on activities and fieldwork, preparing them for advanced agricultural studies.", dep:[
        {id: 1, deptName: "Department of Agriculture ", desc: "",
            courses: [
                {id: 1, head: "Semester 1 - courses", sem1: Agr.Sem1},
                {id: 2,head: "Semester 2 - courses", sem2: Agr.Sem2}
            ]
        }
    ]},
    {id: 8, faculty: "Faculty of Economics and Management", desc:"In the Foundation Year of the Faculty of Economics and Management, students will learn fundamental concepts in economics, management principles, and financial accounting. They will develop analytical skills, understand market dynamics, and explore organizational behavior. Additionally, students will gain practical experience through case studies and projects.", dep:[
        {id: 1, deptName: "Department of Economics and Management", desc: "",
            courses: [
                {id: 1, head: "Semester 1 - courses", sem1: EM.Sem1},
                {id: 2,head: "Semester 2 - courses", sem2: EM.Sem2}
            ]
        }
    ]},
    {id: 9, faculty: "Faculty of Dentistry", desc:"In the Foundation Year of the Faculty of Dentistry, students will learn the basics of oral anatomy, dental materials, and patient care. They will study biological sciences relevant to dentistry, including microbiology and biochemistry. Additionally, students will gain introductory clinical skills, focusing on preventive dentistry and oral health education.", dep:[
        {id: 1, deptName: "Department of Dentistry", desc: "",
            courses: [
                {id: 1, head: "Semester 1 - courses", sem1: Den.Sem1},
                {id: 2,head: "Semester 2 - courses", sem2: Den.Sem2}
            ]
        }
    ]},
    {id: 10, faculty: "Faculty of Geoscience and Environment", desc:"In the Foundation Year of the Faculty of Geoscience and Environment, students will study the Earth's structure, natural resources, and environmental processes. They will learn about climate change, sustainable practices, and geospatial technologies. The curriculum includes hands-on fieldwork, lab experiments, and the development of analytical skills to address environmental challenges.", dep:[
        {id: 1, deptName: "Department of Geoscience and Environment", desc: "",
            courses: [
                {id: 1, head: "Semester 1 - courses", sem1: Geo.Sem1},
                {id: 2,head: "Semester 2 - courses", sem2: Geo.Sem2}
            ]
        }
    ]},
    {id: 11, faculty: "Faculty of Marine Science and Nautical Studies", desc:"In the Foundation Year of the Faculty of Marine Science and Nautical Studies, students will learn marine biology fundamentals, oceanography, and navigation techniques. They will explore marine ecosystems, environmental conservation, and maritime safety. Practical skills in seamanship and hands-on experience with marine technology will also be emphasized.", dep:[
        {id: 1, deptName: "Department of Marine Science and Nautical Studies", desc: "",
            courses: [
                {id: 1, head: "Semester 1 - courses", sem1: ms.Sem1},
                {id: 2,head: "Semester 2 - courses", sem2: ms.Sem2}
            ]
        }
    ]},
    {id: 12, faculty: "Faculty of Social Science", desc:"In the Foundation Year of the Faculty of Social Science, students will explore key concepts in sociology, psychology, and political science. They will develop critical thinking and research skills, gain an understanding of social issues and human behavior, and learn about the impact of societal structures on individuals and communities. Practical experience and interdisciplinary perspectives will prepare them for advanced study in social sciences. Currenlty, the faculty has two departments which are Political Science and Public Administration", dep:[
        {id: 1, deptName: "Department of Political Science", desc: "In the Foundation Year of the Department of Political Science, students will study the fundamentals of political theory, comparative politics, and international relations. They will explore political institutions, public policy, and governance systems. Additionally, students will develop critical thinking, research, and analytical skills through practical assignments and discussions on contemporary political issues.",
            courses: [
                {id: 1, head: "Semester 1 - courses", sem1: ss_ps.Sem1},
                {id: 2,head: "Semester 2 - courses", sem2: ss_ps.Sem2}
            ]
        },
        {id: 2, deptName: "Department of Public Administration", desc: "In the Foundation Year of the Department of Public Administration, students will study the principles of public administration, governance, and policy-making. They will learn about organizational behavior, public sector management, and financial administration. Additionally, students will develop skills in critical thinking, communication, and ethical decision-making essential for public service roles.",
        courses: [
            {id: 1, head: "Semester 1 - courses", sem1: ss_pa.Sem1},
            {id: 2,head: "Semester 2 - courses", sem2: ss_pa.Sem2}
        ]
    },

    ]},

    {id: 13, faculty: "Faculty of Health Science", desc:"In the Foundation Year of the Faculty of Health Science, students will learn the basics of human anatomy, physiology, and health systems. They'll explore foundational principles of public health, medical ethics, and health research methods. Practical skills in laboratory techniques and patient care will also be developed, preparing students for advanced studies in various health science disciplines.", dep:[
        {id: 1, deptName: "Department of Nursing", desc: "In the Foundation Year of the Department of Nursing, students will learn the basics of human anatomy and physiology, nursing theory and practice, and foundational patient care skills. They will also be introduced to health assessment techniques, medical terminology, and the principles of evidence-based practice. Emphasis is placed on developing communication and critical thinking skills essential for nursing.",
            courses: [
                {id: 1, head: "Semester 1 - courses", sem1: HS_Nur.Sem1},
                {id: 2,head: "Semester 2 - courses", sem2: HS_Nur.Sem2}
            ]
        },
        {id: 2, deptName: "Department of Public Health", desc: "In the Foundation Year of the Department of Public Health, students will learn the basics of epidemiology, biostatistics, and health promotion. They will explore public health principles, disease prevention, and health systems management. The curriculum includes understanding health behaviors, community health, and global health issues. Practical skills in data analysis and public health research are also emphasized.",
        courses: [
            {id: 1, head: "Semester 1 - courses", sem1: HS_PH.Sem1},
            {id: 2,head: "Semester 2 - courses", sem2: HS_PH.Sem2}
        ]
        },
        {id: 3, deptName: "Department of Medical Laboratory Science", desc: "In the Foundation Year of the Medical Laboratory Science department, students will gain a solid understanding of basic biological and chemical principles, laboratory techniques, and safety protocols. They will study human anatomy, physiology, and microbiology, along with the fundamentals of clinical laboratory procedures and diagnostic testing. This year also emphasizes developing analytical skills and a strong foundation in medical ethics and professional conduct.",
        courses: [
            {id: 1, head: "Semester 1 - courses", sem1: HS_ML.Sem1},
            {id: 2,head: "Semester 2 - courses", sem2: HS_ML.Sem2}
        ]
        },
        {id: 4, deptName: "Department of Midwifery", desc: "In the Foundation Year of the Department of Midwifery, students will gain foundational knowledge in reproductive health, prenatal care, and childbirth. They'll learn about the anatomy and physiology of pregnancy, stages of labor, and techniques for supporting women during childbirth. Additionally, emphasis will be placed on developing communication skills essential for effective patient interaction. Fieldwork opportunities may provide hands-on experience in clinical settings, preparing students for their future roles as compassionate and skilled midwives.",
        courses: [
            {id: 1, head: "Semester 1 - courses", sem1: HS_Mw.Sem1},
            {id: 2,head: "Semester 2 - courses", sem2: HS_Mw.Sem2}
        ]
        },
        {id: 5, deptName: "Department of Pharmacy", desc: "In the Department of Pharmacy's Foundation Year, students delve into core principles of pharmaceutical sciences, including drug composition, pharmacology, and pharmacokinetics. They gain foundational knowledge in biology, chemistry, and human anatomy to understand the fundamentals of drug interactions and their effects on the body. Practical lab work hones skills in compounding medications and understanding dosage forms. Ethical considerations in pharmaceutical practice and healthcare systems are also explored, setting the stage for advanced study and professional practice in pharmacy.",
        courses: [
            {id: 1, head: "Semester 1 - courses", sem1: HS_PHR.Sem1},
            {id: 2,head: "Semester 2 - courses", sem2: HS_PHR.Sem2}
        ]
        },
        {id: 6, deptName: "Department of Nutrition", desc: "In the Foundation Year of the Department of Nutrition, students delve into the fundamental principles of nutrition science, understanding human physiology and metabolism. They explore the role of nutrients in health and disease, learning about dietary guidelines and nutritional assessment methods. Practical skills are honed through kitchen labs, where students apply knowledge in meal planning and food preparation. Social and cultural aspects of food are examined, fostering an appreciation for diverse dietary practices. The curriculum emphasizes critical thinking and evidence-based approaches to nutrition education and intervention..",
        courses: [
            {id: 1, head: "Semester 1 - courses", sem1: HS_Nutr.Sem1},
            {id: 2,head: "Semester 2 - courses", sem2: HS_Nutr.Sem2}
        ]
        },

    ]},

]

