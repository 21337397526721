import React from "react";
import { Link, useParams } from "react-router-dom";
import api from "../../../config.json";
const url = api.url;

function Items({ currentItems }) {
  return (
    <div className="my-8 py-2 grid grid-cols-4 sm:grid-cols-2 xs:grid-cols-1 gap-5  w-[70%] ">
      {currentItems &&
        currentItems.map((s) => (
          <div
            key={s._id}
            className="flex flex-col  items-center  space-y-1 rounded-lg border-2 w-fit h-fit"
          >
            <img
              src={s.staff && url + s.staff.avatar}
              alt="avatar"
              className="h-[250px] w-[250px] "
            />
            <div className="flex flex-col p-5">
              <Link
                to={`${s.staffId}`}
                state={{ staff: s }}
                className="font-semibold underline cursor-pointer text-blue-600"
              >
                {s.staff && s.staff.title}. {s.staffName}
              </Link>
              <p className="text-base">{s.occupation}</p>
              <p className="text-base">{s.staff && s.staff.email}</p>
            </div>
          </div>
        ))}
    </div>
  );
}

export default Items;
