import React from 'react'

const Layout = ({children}) => {
  return (
    <>
    <div style={{ minHeight: "100vh"}} className='w-[95%] '>
        {children}
      </div>
    </>
  )
}

export default Layout