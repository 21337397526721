import React, { useState,useEffect } from "react";
import { getAllDepts } from "../../../services/departmentServices";

function CheckList(props) {

  const [checkedList, setCheckedList] = useState([]);
  const [depts, setDepts] = useState([])


  useEffect( () => {

    async function fetchData() {
        const response = await getAllDepts();
        setDepts(response.data);
      }
      fetchData();
  },[depts])
 
  const handleSelect = (event) => {
    const value = event.target.value;
    const isChecked = event.target.checked;
 
    if (isChecked) {
      //Add checked item into checkList
      setCheckedList([...checkedList, value]);
    } else {
      //Remove unchecked item from checkList
      const filteredList = checkedList.filter((item) => item !== value);
      setCheckedList(filteredList);
    }
  };
 
  return (
    <div className=" w-full bg-green-300">
      <div className="card">
        <div className="card-header">
          <p className="title">Select Departments </p>
        </div>
 
        <div className=" flex flex-row my-3 mx-3">
          <label className="text-[15px] ">You Selected: ( </label>
          {checkedList.length >-1 &&  checkedList.map((item, index) => {
            return (
              <div className="text-[15px]">
                <p className="chip-label">{item},{" "}</p>
              </div>
            );
          })}
          <label className="text-[15px] "> )</label>
        </div>
 
        <div className="px-5 pb-3 text-[15px]">
          {depts.map((d, index) => {
            return (
              <div key={d.code} className="">
                <input
                className="rounded-sm mx-2"
                  type="checkbox"
                  name="languages"
                  value={d.code}
                  onChange={handleSelect}
                />
                <label className="text-[15px]">{d.department}</label>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
 
export default CheckList;