import React from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

function Tabtest({handleBlur,handleChange,touched, values, errors}) {
  return (
    <div>
        <Tabs className="w-full border-2">
            <TabList className="flex flex-row font-bold bg-[#E8EDED] w-fit ">
              <Tab selectedClassName="bg-[#0863AA] text-white" className="px-10 py-2 text-base text-black hover:cursor-pointer hover:text-[#0863AA] ">English</Tab>
              <Tab selectedClassName="bg-[#0863AA] text-white" className="px-10 py-2 text-base text-black hover:cursor-pointer hover:text-[#0863AA] ">العربية</Tab>
              <Tab selectedClassName="bg-[#0863AA] text-white" className="px-10 py-2 text-base text-black hover:cursor-pointer hover:text-[#0863AA] ">Somali</Tab>
            </TabList>
            <TabPanel>
            <div className="w-full mt-2">
                <input
                  placeholder="Enter news title in english"
                  name="title"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.title}
                  className="placeholder:italic placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                />
                {errors.title && touched.title && errors.title}
              </div>
              <div className="w-full mt-10">
                <textarea
                  className="my-2 placeholder:italic placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                  placeholder="News Content"
                  name="content"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.content}
                  rows="8"
                  cols="50"
                />
                {errors.content && touched.content && errors.content}
              </div>
            </TabPanel>
            <TabPanel>
            <div className="w-full mt-10">
                <input
                  placeholder="Enter news title  in arabic"
                  name="title"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.title}
                  className="placeholder:italic placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                />
                {errors.title && touched.title && errors.title}
              </div>
              <div className="w-full mt-10">
                <textarea
                  className="my-2 placeholder:italic placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                  placeholder="News Content"
                  name="content"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.content}
                  rows="8"
                  cols="50"
                />
                {errors.content && touched.content && errors.content}
              </div>
            </TabPanel>
            <TabPanel>
            <div className="w-full mt-10">
                <input
                  placeholder="Enter news title  in somalia"
                  name="title"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.title}
                  className="placeholder:italic placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                />
                {errors.title && touched.title && errors.title}
              </div>
              <div className="w-full mt-10">
                <textarea
                  className="my-2 placeholder:italic placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                  placeholder="News Content"
                  name="content"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.content}
                  rows="8"
                  cols="50"
                />
                {errors.content && touched.content && errors.content}
              </div>
            </TabPanel>
        </Tabs>
    </div>
  )
}

export default Tabtest