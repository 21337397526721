import React from 'react'
import NewsSlider from '../../dashboard/pages/newsslider';




const innovation = () => {
const backet = 'https://bu-page-assets.s3.ap-northeast-1.amazonaws.com/static-assets';

  const imageList = [
    { id: 1, url: backet + "/news/news01/1.jpg"},
    { id: 2, url: backet + "/news/news01/2.jpg"},
    { id: 3, url: backet + "/news/news01/3.jpg"},
  ]

  return (
    <div className=' h-screen'>
      <div className='flex flex-col items-center h-screen justify-center py-20'>

        <p className='font-semibold text-3xl my-3'>The page is under construction..</p>
      <div className='flex justify-center items-center w-full '>
          <NewsSlider list={imageList}/>
      </div>
      </div>
      
    </div>
  )
}

export default innovation