import React from 'react'

function Applyonline() {

    const contacts = {
        title: "Benadir University",
        address: "Wadada Jaamacadda Benadir,",
        street: "Behind saybiayno Hodan",
        city: "Mogadishu - Somalia",
        tell: "Admision Help Line: +2018",
        email: "Email: admission@bu.edu.so",
      };
    
  return (
    <div className="relative xl:pt-[95px] md:pt-[95px] sm:pt-[95px] xs:pt-[75px] flex items-center justify-center  overflow-hidden rounded-lg bg-gradient-to-r from-blue-600 to-indigo-800 p-6 shadow-2xl">
      {/* Decorative elements */}
      <div className="absolute -right-10 -top-10 h-32 w-32 rounded-full bg-white/10"></div>
      <div className="absolute -bottom-8 -left-8 h-24 w-24 rounded-full bg-white/10"></div>
      
      {/* Main content */}
      <div className="relative z-10 text-center">
        <h3 className="mb-3 text-xl font-bold text-white md:text-2xl">
          <span className="mr-2 inline-block animate-bounce">📢</span>
          Important Notice
        </h3>
        
        <div className="mb-4 flex items-center justify-center">
          <div className="mr-3 h-1 w-12 bg-yellow-400"></div>
          <p className="text-lg font-semibold text-white">
          To apply, candidates need to come to the admission office in person.
          </p>
          <div className="ml-3 h-1 w-12 bg-yellow-400"></div>
        </div>
        
        <div className="text-[#0863AA] bg-white border-2 solid border-[#0863AA] rounded-xl py-10 px-10 flex flex-col justify-center items-center space-y-2 mt-5">
          <p className="py-2 text-xl xs:text-base">{contacts.title}</p>
          <p className="py-2 text-xl xs:text-base">{contacts.address}</p>
          <p className="py-2 text-xl xs:text-base">{contacts.street}</p>
          <p className="py-2 text-xl xs:text-base">{contacts.city}</p>
          <p className="py-2 text-xl xs:text-base">{contacts.tell}</p>
          <p className="py-2 text-xl xs:text-base ">{contacts.email}</p>
        </div>
      </div>
      
      {/* Corner accents */}
      <div className="absolute top-0 left-0 h-12 w-12 border-l-2 border-t-2 border-yellow-400"></div>
      <div className="absolute bottom-0 right-0 h-12 w-12 border-r-2 border-b-2 border-yellow-400"></div>
    </div>
  )
}

export default Applyonline


