import React, { useState } from 'react';
import {courses} from "../../../data/foundationCourses"

// Accordion Item Component
const AccordionItem = ({ course, isOpen, onClick }) => {
  return (
    <div className="border-b border-gray-200">
      <button
        className="w-full px-4 py-2 text-left text-gray-800 bg-gray-100 focus:outline-none"
        onClick={onClick}
      >
        <div className="flex items-center justify-between">
        
          <p className="text-xl font-base font-semibold sm:text-sm xs:text-base my-2"><span className="mr-3">{course.id}.</span>{course.faculty}</p>
             
          <span>{isOpen ? '-' : '+'}</span>
        </div>
      </button>
      {isOpen && (
        <div className='border px-1 xl:px-2 '>
         <p className="text-base xs:text-sm mt-2">{course.desc}</p>
        <div className="bg-white">
                <div className="mx-1 my-10">
                  {course.dep.map( d => (
                    <div key={d.id}>
                      <p className="text-base my-4 mt-10 font-base font-semibold">{d.deptName}</p>
                      <p>{d.desc}</p>

                      <div className="grid grid-cols-2 sm:grid-cols-1 xs:grid-cols-1 gap-5">
                        {d.courses.map( cr=> (
                          <div key={cr.id}>
                            <p className="text-sm font-semibold font-sans mx-10 my-3">{cr.head}</p>
                            <div className="">
                            <table className="border">
                                {cr.id === 1 && (<thead className="border-b-2 w-full text-sm font-bold py-5 bg-indigo-200">
                                  <tr className="space-x-3">
                                    <th className="w-[20px]  px-2">S#</th>
                                    <th className="w-[350px] pl-1">Course Title</th>
                                    <th className="w-[45px]">LH</th>
                                    <th className="w-[45px]">PH</th>
                                    <th className="w-[45px]">CH</th>
                                    <th className="w-[45px]">CU</th>
                                  </tr>
                                </thead>)}
                                <tbody>
                                {
                                cr.sem1?.map( cs =>(
                                  <tr key={cs.id} className="border">
                                    <td className="w-[20px]  text-center">{cs.id}</td>
                                    <td className="w-[350px] border-l-2 pl-2">{cs.title}</td>
                                    <td className="w-[45px] border-l-2 text-center">{cs.LH}</td>
                                    <td className="w-[45px] border-l-2 text-center">{cs.PH}</td>
                                    <td className="w-[45px] border-l-2 text-center">{cs.CH}</td>
                                    <td className="w-[45px] border-l-2 text-center">{cs.CU}</td>
                                  </tr>
                                ))
                              }
                                </tbody>
                              </table>
                            </div>
                            <table className="border">
                            {cr.id === 2 && (<thead className="border-b-2 w-full text-sm font-bold py-5 bg-indigo-200">
                                  <tr className="space-x-3">
                                    <th className="w-[20px] px-1">S#</th>
                                    <th className="w-[350px] pl-1">Course Title</th>
                                    <th className="w-[45px]">LH</th>
                                    <th className="w-[45px]">PH</th>
                                    <th className="w-[45px]">CH</th>
                                    <th className="w-[45px]">CU</th>
                                  </tr>
                                </thead>)}
                               
                                <tbody>
                                {
                                cr.sem2?.map( cs =>(
                                 
                                  <tr key={cs.id} className="border">
                                    <td className="w-[20px]  text-center">{cs.id}</td>
                                    <td className="w-[350px] border-l-2 pl-1">{cs.title}</td>
                                    <td className="w-[45px] border-l-2 text-center">{cs.LH}</td>
                                    <td className="w-[45px] border-l-2 text-center">{cs.PH}</td>
                                    <td className="w-[45px] border-l-2 text-center">{cs.CH}</td>
                                    <td>{cs.CU}</td>
                                  </tr>
                                ))
                              }
                                </tbody>
                              </table>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
        </div>
        </div>
      )}
      
    </div>
    
  );
};

// Accordion Component
const AccordianFoundation = ({ items }) => {
  const [openIndex, setOpenIndex] = useState(null);

  const handleClick = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="text-black text-justify w-full  mt-10  flex  justify-center items-center  " >
        <div className="flex flex-col md:w-[70%] xl:w-[70%] w-[90%] space-y-1 mb-5">
      {courses.map((c,index) => (
        <AccordionItem
          key={c.id}
          course={c}
          isOpen={openIndex === index}
          onClick={() => handleClick(index)}
        />
      ))}
    </div>
    </div>
  );
};

export default AccordianFoundation;