import React from "react";
import { IoMdClose } from "react-icons/io";
import { Link } from "react-router-dom";
import PageTitle from "../../general/pageTitle";

function UpdatePublication(props) {
  return (
    <>
      <PageTitle title="Update Publications" subTitle="Staff" />

      <div className=" w-full overflow-auto  ">
        <div className="flex flex-col justify-center p-4 ">
          <form className="flex flex-col bg-white border shadow-sm rounded-xl">
            <div className="flex justify-center items-center py-2.5 px-4 border-b">
              <div className="flex flex-row items-center">
                <label
                  htmlFor="validationDefault01"
                  className="text-gray-800 text-sm font-medium  mx-2"
                >
                  Email
                </label>
                <input
                  type="text"
                  className="form-input rounded-2xl md:w-[300px]"
                  id="validationDefault01"
                  required
                />
              </div>
              <button
                data-fc-dismiss
                type="button"
                className="btn bg-primary text-white mx-2"
              >
                Find
              </button>
            </div>
            <div className="p-4 overflow-y-auto">
              <div className="flex justify-end items-center gap-x-2 py-2.5 px-4 border-t my-4">
                <button
                  data-fc-dismiss
                  type="button"
                  className="btn bg-secondary text-white"
                >
                  Cancel
                </button>
                <a className="btn bg-primary text-white" href="#">
                  Save
                </a>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default UpdatePublication;
