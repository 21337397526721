import xoolaha from "./xoolaha.pdf"
import research1 from "./research1.pdf"
import research2 from "./riskAssesmentVet.pdf"
import research3 from "./world_habitats_day.pdf"
import research4 from "./CAEB2025.pdf"
export  const journal = [
    {id: 1, title: "Who published what on Somali health issues?", subtitile:"Forming the policy for SHAJ through a bibliometric study", doi: "https://doi.org/10.36368/shaj.v2i1.281",fileUrl: research1},
    {id: 2, title: "Warsidaha Cilmiga Xoolaha",subtitile: "Somali Veterinary Journal", doi: "https://1drv.ms/w/s!AlYIk8U1LHPdnEQegtKXkf2hAVjC",fileUrl: xoolaha},
    {id: 3, title: "Preventive Veterinary Medicine",subtitile: "Risk assessment and cost-effectiveness of animal health certification methods for livestock export in Somalia", doi: "http://dx.doi.org/10.1016/j.prevetmed.2014.01.003",fileUrl: research2},
    {id: 4, title: "WORLD HEPATITIS DAY",subtitile: "The Hepatitis Landscape: A Silent Threat", doi: "",fileUrl: research3},
    {id: 5, title: "NAVIGATING GLOBAL CHALLENGES AND OPPORTUNITIES CONFERENCE",subtitile: "Interdisciplinary - Hybrid Conference", doi: "",fileUrl: research4},
    ]

  
  