import React, {useState} from "react";
import {   Link } from "react-router-dom";
import { TbPointFilled } from 'react-icons/tb';
import {pubs} from '../../../data/pubs'
import research from "../../../assets/images/RESEARCH-01.png"
import SectionCollapse from "./section";
import _ from "lodash"



const sample = "https://bu-page-assets.s3.ap-northeast-1.amazonaws.com/static-assets/others/ractor.jpg";


const Publications = props => {
    

  const pubss = _.orderBy(pubs, 'year', 'desc');



  return (
         <div  className="mx-7">

        <div className='relative w-full '>
            <img src={research} className='w-full h-[20%]   bg-cover bg-center'/>
        </div>
         

      <div className="flex flex-col justify-center items-center w-full text-black my-3 font-montserrat">
        <div className="flex flex-col space-y-3 ">
          <p className="text-2xl ">Benadir University Research publications</p>
          <p className="leading-7 text-justify">Welcome to Benadir University Research Publication, where knowledge meets innovation and insight drives progress.<br/><br/>As an esteemed institution dedicated to academic excellence and scholarly exploration, Benadir University proudly presents a platform for disseminating cutting-edge research across a spectrum of disciplines. At Benadir University Research Publication, we foster a culture of intellectual curiosity and scholarly rigor, providing a forum for scholars, researchers, and academics to showcase their pioneering work and contribute to the advancement of their respective fields.<br/><br/>Our publication endeavors to uphold the highest standards of academic integrity and peer-reviewed excellence, ensuring that each article and study published undergoes rigorous evaluation by experts in the field. Through our diverse array of research topics and interdisciplinary approach, we aim to catalyze dialogue, inspire innovation, and foster collaboration both within our academic community and beyond.<br/><br/>Join us on a journey of discovery and exploration as we delve into the frontiers of knowledge and chart new territories in research and scholarship. Welcome to Benadir University Research Publication, where ideas take flight and knowledge knows no bounds.</p>
        </div>
        <div className="h-full flex flex-col overflow-hidden xl:my-3  w-full ">
          {pubss && pubss.map((d) => (
            <SectionCollapse key={d.id} title={d.year} >
              {/* {d.desc} */}
              <ol className="list-decimal xs:px-3">
             { d.item.map( r => (
                    <div key={r.id} className="flex md:ml-10 xl:ml-10 px-2 ">
                    <li>
                    <a href={`${r.doi}`} target="_blank" rel="noopener noreferrer" className="  leading-8 flex flex-row  py-2 font-inriaserif md:font-semibold xl:font-semibold">
                     <span className="hover:underline hover:text-[#0863AA] text-black text-base"> {r.title}</span>
                    <span className="md:mx-10 xl:mx-10 xs:hidden sm:hidden">{r.dept}</span>
                    </a>
                    </li>
                  </div>
                )
            ) }
            </ol>
            </SectionCollapse>
          ))}
        </div>
      </div>
       
         
         
        
       
      </div>

  );
};

export default Publications;