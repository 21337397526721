import React from "react";
import Events from "../components/sections/events";
import News from "../components/sections/News";
import MenuOptions from "../components/sections/menuOptions";
import SliderMenuOptions from "../components/sections/SliderMenuOptions";
import Carousels from "../components/sections/Carousels";
import Messages from "../components/sections/messages";
import PageLinks from "../components/sections/pagesLinks";
import OurPartners from "../components/sections/ourpartners";
import EventSlide from "./pages/events/EventSlide";

const Home = () => {
  return (
    <div>
      <Carousels />
      <MenuOptions />
      <PageLinks />

      <div className="bg-[#edecec]">
        <News />
        {/* <Events /> */}
      </div>
      <EventSlide />

      <Messages />

      {/* <Conferences /> */}
      <SliderMenuOptions />
      <OurPartners />
    </div>
  );
};

export default Home;
