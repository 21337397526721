import React, { useState, useEffect } from "react";
import api from "../../../config.json"
// import { posts } from "../../data/posts";
import { Link,useParams } from "react-router-dom";
// import { news } from "../../data/news";
import { getAllActivity} from "../../../services/activityServices"
import _ from "lodash"
import Paginate from "./paginate"

const FacultyActivity = () => {
  // const sortedNews = news.sort((a, b) => b.id - a.id);
  const [sortedActivity, setSortedActivity] = useState([]);

  const { slug } = useParams();
  
  const url = api.url
  useEffect( () => {

    async function fetchData() {
        const response = await getAllActivity();
        const activity = response.data
        const filteredActivity = _.filter(activity, ['facultySlug', slug])

        const sortedActivity = _.orderBy(filteredActivity, 'createdAt', 'desc');
        setSortedActivity(sortedActivity);
      }
      fetchData();
  },[])



  return (
    <div className="text-black text-justify my-10 flex flex-col justify-center items-center ">
      {sortedActivity.length > 0 && (<Paginate items={sortedActivity} slug={slug} />)}
    </div>
  );
};


export default FacultyActivity