import React, {useState} from 'react'
import { RiFacebookFill } from 'react-icons/ri';

import { IoLogoTwitter } from 'react-icons/io';
import { FaYoutube } from 'react-icons/fa';
import { FaInstagram } from 'react-icons/fa';
import { Link } from 'react-router-dom';


const backet = 'https://bu-page-assets.s3.ap-northeast-1.amazonaws.com/static-assets';

const gps = backet + '/others/gps.png';
const aps = backet + '/others/aps.png';



const Footer = () => {
  const [email, setEmail] = useState('')
  const getIntoach = [
    {id: 1, menu: "Contact", link: "bucontacts"},
    {id: 2, menu: "Location", link: "location"},
    {id: 3, menu: "BU News", link: "news"},
    {id: 4, menu: "Brochure", link: "brochure"},
    {id: 5, menu: "Brand Document", link: "branddocument"},
    {id: 6, menu: "Alumni", link: "alumni"},
    {id: 7, menu: "Apply Online", link: "appllyonline"},
  ]

  const userFullLinks = [
    {id: 1, menu: "Innovation Hub", link: "innovation"},
    {id: 2, menu: "Research", link: "research/publications"},
    {id: 3, menu: "Calender", link: "callender"},
    {id: 4, menu: "Convacation", link: "convacation"},
    {id: 5, menu: "Careers", link: "careers"},
    {id: 6, menu: "Forms", link: "forms"},
    {id: 7, menu: "Login", link: "login"},
  ]


  const handleClick = () => {
    //call subscribe 
  }
  return (
    
    <div className='flex flex-col bg-[#0863aa] text-white'>

  
    <div className='flex md:flex-row sm:flex-col xs:flex-col justify-around  '>
      {/* column one */}
      <div className='flex flex-col mx-3 my-10 '>
        <p className='text-[20px] font-semibold my-3 capitalize'>Get in touch</p>
        {getIntoach.map( (l) => (
          <Link className='my-1 text-[15px] ' to={`/${l.link}`} key={l.id}>{l.menu}</Link>
          ) )}
      </div>

          {/* column two */}
      <div className='flex flex-col mx-3 my-10 '>
        <p className='text-[20px] font-semibold my-3 capitalize'>Usefull Links</p>
        {userFullLinks.map( (l) => (
          <Link className='my-1 text-[15px] ' to={`/${l.link}`} key={l.id} relative="route">{l.menu}</Link>
        ) )}
        <div className='md:py-2 flex xs:flex-col md:space-x-2  items-center xs:items-start xs:justify-center'>
          <a href="https://apps.apple.com/th/app/benadir-university/id1593186984">
          <img src={aps} alt="app store"  className='h-[40px] '/>
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.fedena.benadir&hl=pt&gl=US">
          <img src={gps} alt="play store"   className='h-[60px] '/>
          </a>
        </div>
      </div>
      
        {/* column three */}
      <div className='flex flex-col mx-3 my-10 '>
        <p className='text-[20px] font-semibold my-3'>Subscribe Us!</p>
          <input type="email" 
          placeholder="Email Address"
          name="email"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          className='text-[#0863aa] border-2 border-gray-300 py-2 px-2 w-full focus:outline-none rounded-[3px]'
          />
          <button
          onClick={handleClick}
          className='text-[#0863aa] w-full font-semibold bg-white my-2 py-3 px-3 capitalize'>Subscribe
          </button>
          <div className='md:mt-5 py-2 flex flex-col space-x-2  text-white '>
          <p className='text-[20px] font-bold capitalize'>Connect with US</p>
          <div className='flex flex-row justify-start   my-3 text-[#0863aa] '>
            <a href="https://www.instagram.com/benadiruniv/" target="_blank" rel="noopener noreferrer" className='bg-white p-2 '>
              <FaInstagram size={30} />
            </a>
            <a href="https://www.facebook.com/BenadirUniv" target="_blank" rel="noopener noreferrer" className='bg-white p-2'>
              <RiFacebookFill size={30} />
            </a>
            <a href="https://twitter.com/BenadirUniv" target="_blank" rel="noopener noreferrer" className='bg-white p-2'>
              <IoLogoTwitter size={30} />
            </a>
            <a href="https://www.youtube.com/@benadiruniv" target="_blank" rel="noopener noreferrer"  className='bg-white p-2'>
              <FaYoutube size={30} />
            </a>
          </div>
          </div>
      </div>

    </div>
    <div className='bg-[#b6c9e9fe] h-[1px] md:w-full sm:w-full xs:w-full'></div>
    <div className='flex justify-center items-center md:text-[15px] sm:text-[12px] xs:text-[10px] py-2'>
          <p>Copyright &copy; 2023 Benadir University. All  Rights Reserved</p>
    </div>
  </div>
   
  )
}

export default Footer