import React, { useState, useEffect } from 'react';
import { BsArrowLeftCircleFill } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import axios from "axios";

const Hemisverification = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [stid, setStid] = useState('');
  const [hemisData, setHemisData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [noRecordFound, setNoRecordFound] = useState(false); // New state for "no record found"
  const [hasSearched, setHasSearched] = useState(false); // New state to track if a search has been performed
  const navigate = useNavigate();

  useEffect(() => {
    if (searchTerm) {
      fetchData(searchTerm);
    }
  }, [searchTerm]);

  const fetchData = async (studentID) => {
    setLoading(true);
    setNoRecordFound(false); // Reset "no record found" state
    setHemisData([]); // Clear previous data
    try {
      const response = await axios.get(`https://staffserver.bu.edu.so/api/hemis/${studentID}`);
      
      if (response.data === "not found!" || response.data.length === 0) {
        setNoRecordFound(true); // Set "no record found" if the response is empty
      } else {
        setHemisData(response.data); // Set data if records are found
      }
    } catch (err) {
      // Treat any error (including network errors) as "no record found"
      setNoRecordFound(true);
    } finally {
      setLoading(false); // Ensure loading is set to false after the fetch
      setHasSearched(true); // Set hasSearched to true after the fetch
    }
  };

  const handleChange = (e) => {
    setStid(e.target.value.trim());
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setSearchTerm(stid);
  };

  // Filter the data based on the search term
  const filteredData = hemisData.filter((val) => {
    return val.STUDENTID.trim().toUpperCase() === searchTerm.toUpperCase();
  });


  return (
    <div className='bg-[#F2F5F8] flex flex-col xl:pt-[95px] md:pt-[95px] sm:pt-[95px] xs:pt-[75px]'>
      <div className='flex flex-col sm:flex-col xs:flex-col items-center'>
        {/* Search Form */}
        <div className='bg-gray-100 md:w-[25%] xl:w-[25%] max:w-[450px] px-10 flex flex-col items-start space-y-5 py-10'>
          <p className='text-base text-[#0863AA]'>
            Find your HEMIS Number by entering your Student-ID
          </p>
          <form className='w-full flex justify-center sm:flex-col xs:flex-col space-x-3 items-center' onSubmit={handleSubmit}>
            <div className='w-full'>
              <input
                className='uppercase border-2 border-gray-300 py-3 px-5 text-center text-white xs:w-full focus:outline-none rounded-full bg-[#0863AA] md:w-[300px] placeholder:text-[#b3b3b1]'
                id="stid"
                name="stid"
                value={stid}
                onChange={handleChange}
                placeholder='Enter student - ID'
              />
            </div>
            <button type="submit" className='bg-[#0863AA] text-white my-5 px-5 py-2 rounded-full'>
              Search
            </button>
          </form>
        </div>

        {/* Results Section */}
        <div className='md:w-[75%] xl:w-[75%] text-[#0863AA] text-[20px] tracking-wide mb-10'>
          {loading ? (
            <p>Loading...</p>
          ) : hasSearched && (noRecordFound || filteredData.length === 0) ? (
            <div className="w-full justify-center items-center">
              <div className="h-[1px] bg-slate-500 w-full"></div>
              <p>No Record Found!</p>
              <p>Please contact the registration department before <strong>21<sup>st</sup> of March 2025</strong></p>
              <p>Failure to do so may result in challenges, so hurry up!</p>
            </div>
          ) : (
            filteredData.map((val, i) => (
              <div key={i} className='flex flex-col mt-5 px-5 overflow-hidden'>
                <div className="h-[1px] bg-slate-500 w-full"></div>
                <div className="flex justify-center items-center w-full my-5 ">
                  <div className='flex flex-col items-start px-3 space-y-4 sm:text-base xs:text-sm'>
                    <div className="flex space-x-4">
                      <p>Full Name : </p>
                      <p className='font-bold'>{val.NAME}</p>
                    </div>
                    <div className="flex space-x-4">
                      <p>Degree : </p>
                      <p className='font-bold'>{val.PROGRAM}</p>
                    </div>
                    <div className="flex space-x-4">
                      <p>HEMIS Number : </p>
                      <p className='font-bold'>{val.HEMISNUMBER}</p>
                    </div>
                    <div className="flex space-x-4">
                      <p>Student Status : </p>
                      <p className='font-bold'>{val.STATUS}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default Hemisverification;