import React from "react";
import {  useParams } from "react-router-dom";
import {programs} from '../../../data/programs'
import parse from 'html-react-parser';




const Departments =() => {
  // const dept = programs[0].lists[0].dep;
  const {slug} = useParams()



  let dept,labs ;
  const msg1 = programs[0].lists.filter((l)=>{
    if(slug === l.slug.toString() ){
      dept = l.dep
      labs = l.labs
      return l.msg
    }
    else{
      return <div> <p>404 page not found!</p> </div>;
    }
  })


  return (
    <div  className="text-black text-justify my-10 flex flex-col justify-center items-center">
      <div className="flex flex-col md:w-[70%] xl:w-[70%]">

      <div className="mx-10">
    <p className="py-5 text-xl ">
    {dept && dept.desc}
    </p>
    {dept &&
        dept.dep.map( (d,i) => (
          <div key={i} className=" mx-10">
            {parse(d)}
          </div>
        ))
    }
    {dept &&
      dept.desc2 && (<p className="py-5 text-xl ">
      {dept.desc2}
      </p>)
    }
     {dept && dept.dep2 && (
       dept.dep2.map( (d,i) => (
         <p  key={i} className="text-base font-semibold mx-10">
           {d}
         </p>
       ))
     )
    }
     {dept &&
      dept.desc3 && (<p className="py-5 text-xl ">
      {dept.desc3}
      </p>)
    }
     {dept && dept.dep3 && (
       dept.dep3.map( (d,i) => (
         <p  key={i} className="text-base font-semibold mx-10">
           {d}
         </p>
       ))
     )
    }
    {
      labs && (
       <div >
         <p className='text-3xl  font-semibold mt-5 my-5'>
          {labs.title}
        </p>
        <p className='text-base'>
          {labs.desc}
        </p>
      {  labs.lst.map( (d,i) => (
         <p  key={i} className="text-base my-2 mx-10">
           {d}
         </p>
       ))}
       </div> 
      )
    }
 
</div>
      </div>
</div>

  );
};

export default Departments;