import React from "react";

const Staff = () => {

  return (
         <div className="text-black text-justify w-full  mt-10  flex  justify-center items-center  " >
          <div className="flex flex-col md:w-[70%] xl:w-[70%] w-[90%]">

           <p>this page is under construction</p>

         
            </div>
      </div>

  );
};

export default Staff;