import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import parse from "html-react-parser";
import { IoTime, IoLocationSharp } from "react-icons/io5";
import { events } from "../../../data/events";
import _ from "lodash";
import { Link } from "react-router-dom";

function Events() {
  const today = new Date();
  const SortedEvents = _.orderBy(events, ["id"], ["desc"]);
  const activeEvents = _.filter(
    SortedEvents,
    (e) => new Date(e.EventDate) > today
  );

  return (
    <div className="">
      <div className="relative w-full h-[100px] mx-h-[100px]"></div>

      {/* menu of this area */}
      <div className="bg-[#EDF4F4] px-4 md:px-10 py-10 ">
        <div className="col-span-10 w-full">
          <input
            type="text"
            placeholder="Search Here"
            className="w-full py-3 px-3 outline-none rounded-md"
          />
        </div>
      </div>

      {/* no name */}
      <div className="grid md:grid-cols-3 xl:grid-cols-4 sm:grid-cols-2 mx-4 md:mx-10 xl:mx-10 my-10 gap-4">
        {SortedEvents.map((s) => (
          <div
            key={s.id}
            className="bg-white rounded-lg shadow-lg hover:border-2 hover:border-[#000] cursor-pointer"
          >
            <Link to={`${s.slug}`}>
              <img
                src={s.img}
                className="rounded-t-lg object-cover md:w-[500px] md:h-[300px] xl:w-[500px] xl:h-[300px]"
                alt="not found"
              />
              <div className="px-6 mt-4 mb-6">
                <p className="font-bold text-[16px] md:text-[1.5rem] line-clamp-1">
                  {s.title}
                </p>
                <p className="text-star  font-normal tracking-wider line-clamp-2 whitespace-pre-line">
                  {parse(s.desc)}
                </p>
                <p>
                  {new Date(s.EventDate).toLocaleDateString("en-US", {
                    weekday: "long",
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })}
                </p>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Events;
