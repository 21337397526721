import React from "react";
import structure from '../../../assets/images/faculties/fy/foundation-structure.png'

const Committee = () => {

  return (
         <div className="text-black text-justify w-full  mt-10  flex  justify-center items-center  " >
          <div className="flex flex-col md:w-[70%] xl:w-[70%] w-[90%] my-5">
            <img src={structure} alt="structure" />
            <div className="my-10">
              <p className="text-2xl">Academic Council Meeting</p>
              <p className="text-lg my-3">The faculty academic Council meet Sunday every week at 14:00.
              </p>
            </div>
            </div>
         
      </div>

  );
};

export default Committee;