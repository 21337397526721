import React from 'react'
import parse from 'html-react-parser';
import { events } from "../../../data/events";
import { useParams } from 'react-router-dom'
import _ from "lodash";



const backet = 'https://bu-page-assets.s3.ap-northeast-1.amazonaws.com/static-assets';
const cover =  backet + '/events/event.jpg'

const EventDetails = (props) => {

    const {slug} = useParams()
    const currentEvent = _.find(events, ['slug', slug]);
   
  return (

    // panel
    <div className=''>
        <div className='relative w-full  xl:pt-[95px] md:pt-[95px] sm:pt-[95px] xs:pt-[75px] group'>
          <img src={currentEvent.img_header} className='w-full h-[200px]   bg-cover bg-center duration-500' alt="cover"/>
          
        </div>
      
        <div className='relative flex justify-center items-center h-auto w-full my-5 sm:mx-2 xs:mx-3'>
            <div className='md:w-[60%] xl:w-[60%] w-full sm:mx-3 xs:mx-3 text-xl space-y-2 py-8'>
                <p>Event : {currentEvent.eventType}</p>
                <p>Venue : {currentEvent.venue}</p>
                <p>Date : {parse(currentEvent.detailDay)}</p>
                <p>Time : {currentEvent.Time}</p>
                <p className='font-bold'>Title : {currentEvent.title}</p>
                <hr />
                {/* <p className='my-2 whitespace-pre-line'>{currentEvent.desc}</p> */}
                <div className='my-2 whitespace-pre-line'>{parse(currentEvent.desc)}</div>
                
                <hr />
               
                
                
                
            </div>
        </div>
      
          
            
        
    </div>
  )
}

export default EventDetails;