import React, { useState } from 'react'
import { BsArrowLeftCircleFill } from 'react-icons/bs';

// import jsonData from '../../../data/admissionResult.json'
import jsonData from '../../../data/admissionResult2024.json'
// import jsonData from '../../../data/admissionResultPart2.json'
import { useNavigate } from 'react-router-dom';
import _ from "lodash"
import ResultTAble from "./resultTable"


const logo = 'https://bu-page-assets.s3.ap-northeast-1.amazonaws.com/static-assets/others/icon.png';

const panner = "https://bu-page-assets.s3.ap-northeast-1.amazonaws.com/static-assets/others/panner.PNG";

const backet = 'https://bu-page-assets.s3.ap-northeast-1.amazonaws.com/static-assets';

const cover =  backet + '/others/cover.jpeg'

const AdmissionTest = () => {
//   const [searchTerm,setSearchTerm] = useState();

  const [stid,setStid] = useState('');
  const [student,setStudent] = useState(null);
  const navigate = useNavigate();
  const JSONDATA = jsonData.Students
 

  function handleChange(e) {
    setStid(e.target.value);
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    const currentStudent = _.find(JSONDATA, function(s) { return s.RollNumber === stid.toUpperCase() });
    if(currentStudent){
        setStudent( student => ({...student, ...currentStudent}))
    }
    else {
        setStudent(currentStudent)
    }
  };

  const Admission = () => {
    if(student === null){
        return (<div>
        </div>)
    }
    if(student === undefined){
        return (<div className='flex flex-col items-center px-3 space-y-4 sm:text-base xs:text-base '>
            <p className='text-3xl sm:text-2xl xs:text-xl font-bold'>Oops!</p>
    <p className='md:w-[50%] xl:w-[50%] '>Ooops, We could not find any results.</p>
    <p className='md:w-[50%] xl:w-[50%] '>Please enter a valid roll number.</p></div>)
    }
   if(student && student.Exam === "Pass"){
        return  (<div className='flex flex-col items-center px-3 space-y-4 sm:text-base xs:text-base '>
            <p className='text-3xl sm:text-2xl xs:text-xl font-bold my-3'>Congratulations!</p>
        <p className='md:w-[50%] xl:w-[50%]'>
        Congratulations on passing your admission exam!</p>
        <p className='md:w-[50%] xl:w-[50%]'>We are happy to welcome you to the Benadir University family.
        To begin your educational journey toward success.
        We are ready for you! Let us begin partnering our way through new discoveries in education together. 
        Let’s enjoy the ride!
        </p>
        <ResultTAble student={student} />
    </div>)
    }
    if(student && student.Exam === "Fail"){
        return  (<div className='flex flex-col items-center px-3 space-y-4 sm:text-base xs:text-base '>
            <p className='text-3xl sm:text-2xl xs:text-xl font-bold'>Oops!</p>
        <p className='md:w-[50%] xl:w-[50%]'> We regret to inform you that you have not been selected for admission to Benadir University for the upcoming academic year.
            We understand that this may be disappointing news and we want to assure you that your exam was carefully reviewed but you did not get enough marks to pass. </p>
            <ResultTAble student={student} /></div>)
    }

  }

  return (
      <div className='bg-[#F2F5F8] flex flex-col  '>
        <div className='flex flex-row sm:flex-col xs:flex-col'> 
        <div className='bg-gray-100 shadow-xl md:w-[25%] xl:w-[25%] max:w-[450px]  px-10  flex flex-col items-start space-y-5 md:py-6 xl:py-6'>

            <h1 className='capitalize mt-5 text-[#0863AA] md:text-[20px] xl:text-[20px] sm:text-base xs:text-base font-bold '>Enter Roll Number </h1>
            <div className='text-[13px] xs:text-[14px]'>
            <p className=' text-[#0863AA] '>
            Please enter the roll number written on ID card.
            </p>
            <p className='text-[#0863AA] '>
            Fadlan geli roll numberka ku qoran ID kaardka.
            </p>
            </div>
        
            <form className='w-full flex justify-center flex-col items-center' >
              <div className='w-full '>
              <input className='border-2 border-gray-300 py-3 px-5 text-center text-white w-full focus:outline-none rounded-full bg-[#0863AA]'  
                id="stid"
                name="stid"
                value={stid}
                onChange={handleChange}
                placeholder='Enter roll number'/>
              </div>
              <button className='bg-[#0863AA] text-white my-5 px-3 py-2' onClick={handleSubmit}>View</button>
            </form>
   
        </div>

        <div>
        
        </div>
        
        <div className='md:w-[75%] xl:w-[75%] text-black text-[18px] tracking-wide mt-10'> 
        <div className='flex flex-row w-full justify-center text-[#0863AA] items-center my-5 md:text-2xl xl:text-2xl font-semibold sm:text-xl xs:text-xl'>
        <p className='text-center'>Admission Examination Results 2024 - 2025</p>
        </div>
        <Admission />
      </div>
      </div>
      </div>
   
  )
}

export default AdmissionTest