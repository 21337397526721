import React from 'react'
import {  AiFillFileAdd} from 'react-icons/ai';
import { MdPeopleAlt } from 'react-icons/md';
import { FaBook } from 'react-icons/fa';
import { FaUserGraduate } from 'react-icons/fa';
import { HiLibrary } from 'react-icons/hi';
import { MdLocalLibrary } from 'react-icons/md';
import CountUp from 'react-countup';

const SliderNews = () => {
    const smallMenues = [
        {icon: MdLocalLibrary, count: 9021, caption: "Students"},
        {icon: FaUserGraduate, count: 13802, caption: "Alumni"},
        {icon: MdPeopleAlt, count: 379, caption: "Staff"},
        {icon: AiFillFileAdd, count: 78, caption: "Active programs"},
        {icon: FaBook, count: 125324, caption: "Library books"},
        {icon: HiLibrary, count: 4, caption: "Campus"}
      ];
 

  return (
    <div className=' w-full mt-5 mx-auto md:px-4 xl:px-4 xl:py-3  md:flex xl:flex   md:flex-wrap xl:flex-wrap grid sm:grid-cols-3 xs:grid-cols-2  justify-center md:space-x-10 xl:space-x-24 items-center  mb-3 bg-[#0863AA]'>


       { smallMenues.map( (s,i) => (

           <div key={i}   className='text-white font-semibold  flex flex-col py-4 md:px-3 xl:px-3 justify-center space-y-2 items-center cursor-default'>
            <s.icon className=' text-8xl'/>
            <div className='flex flex-row text-2xl font-bold'>
            <CountUp 
              start={0}
              end={s.count}
              duration={8}/>
              <span className='mx-1'>+</span>
            </div>
            <p className='text-2xl text-center'>
                {s.caption}
            </p>
           
        </div>
            ))}
    </div>
  )
}

export default SliderNews