import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { IoTime, IoLocationSharp } from "react-icons/io5";
import { events } from "../../../data/events";
import _ from "lodash";
import { Link } from "react-router-dom";

function EventSlide() {
  const today = new Date();
  const SortedEvents = _.orderBy(events, ["id"], ["asc"]);
  const activeEvents = _.filter(
    SortedEvents,
    (e) => new Date(e.EventDate) > today
  );

  return (
    <div className="w-full my-20">
      <div className=" flex flex-col my-2 mt-10  mx-4 md:mx-10 xl:mx-10 mb-10">
        <div className="flex flex-row">
          <p className="text-[30px] font-semibold font-sans sm:text-[40px] ">
            Events
          </p>
        </div>
        <div className="bg-black h-[2px] md:w-[200px] xl:w-[300px] sm:w-full xs:w-full"></div>

        <hr />
        <Carousel
          className="w-full "
          additionalTransfrom={0}
          arrows
          autoPlay
          autoPlaySpeed={4000}
          centerMode={false}
          containerClass=""
          dotListClass=""
          draggable
          focusOnSelect={false}
          infinite={false}
          itemClass=""
          keyBoardControl
          minimumTouchDrag={80}
          pauseOnHover
          partialVisbile={false}
          renderArrowsWhenDisabled={true}
          renderButtonGroupOutside={false}
          renderDotsOutside={false}
          responsive={{
            desktop: {
              breakpoint: {
                max: 3000,
                min: 768,
              },
              items: 4,
              partialVisibilityGutter: 40,
            },
            mobile: {
              breakpoint: {
                max: 767,
                min: 0,
              },
              items: 1,
              partialVisibilityGutter: 30,
            },
            tablet: {
              breakpoint: {
                max: 767,
                min: 640,
              },
              items: 2,
              partialVisibilityGutter: 30,
            },
          }}
          rewind
          rewindWithAnimation={true}
          rtl={false}
          shouldResetAutoplay
          showDots={false}
          sliderClass=""
          slidesToSlide={1}
          swipeable
        >
          {activeEvents.map((e, i) => (
            <div
              key={i}
              className="flex flex-col  justify-between my-3 mx-3 hover:border-2 hover:border-solid"
            >
              <Link to={`events\/${e.slug}`}>
                <p className="text-[#0863AA] font-bold text-2xl xs:text-xl">
                  {e.day}
                </p>
                <p className="bg-[#0863AA] text-white font-bold text-2xl xs:text-xl w-fit px-3 ">
                  {e.month}
                </p>
                <p>
                  <img src={e.img} alt="current month" className="w-full  " />
                </p>
                <p className="text-[#0863AA] font-bold text-2xl xs:text-xl">
                  {e.eventType}
                </p>
                <p className="line-clamp-1">{e.title}</p>
                <div className="flex flex-row justify-between md:mt-8 xl:mt-8 sm:mt-6 xs:mt-2 text-[#0863AA] font-bold text-xl xs:text-base">
                  <div className="flex flow-row space-x-1">
                    <IoLocationSharp />
                    <p className=" ">{e.venue}</p>
                  </div>
                  <div className="flex flow-row space-x-1">
                    <IoTime />
                    <p>{e.Time}</p>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
}

export default EventSlide;
