import React from "react";
import {   Link } from "react-router-dom";

const Schools = ({list}) => {

    // list.push({id: 1});

  return (
         <div className="grid  md:grid-cols-3 xl:grid-cols-4 sm:grid-cols-2 gap-24 justify-around items-center place-items-center w-fit my-10  py-5 px-3" >
        {
          list.map( m => (
            <div key={m.id} className="flex flex-col justify-center items-center  w-[250px] rounded-t-xl border-2 border-solid border-gray-300" >
              <img src={m.img} className="h-[152px]  w-full rounded-t-xl" alt="faculty"/>
              <p className="my-2 py-3 text-[#0863AA] text-xl text-center font-bold h-[100px]">{m.program}</p>
            <Link key={m.id} to={`${m.tag}`} className=" font-serif w-fit font-semibold text-base  py-2 px-8 bg-[#0863AA] text-white mb-5 hover:bg-white hover:text-[#0863AA] hover:border-2 hover:solid hover:border-[#0863AA]">
              Details
            </Link>
            <p></p>
        </div>
          ))
        }
      </div>

  );
};

export default Schools;