import React from 'react'
import Map from 'react-map-gl';

const logo = 'https://bu-page-assets.s3.ap-northeast-1.amazonaws.com/static-assets/others/icon.png';

const panner = "https://bu-page-assets.s3.ap-northeast-1.amazonaws.com/static-assets/others/panner.PNG";
const backet = 'https://bu-page-assets.s3.ap-northeast-1.amazonaws.com/static-assets';


const cover =  backet + '/others/cover.jpeg'

const Bucontacts = () => {
 
  return (
      <div className='bg-[#F2F5F8] flex flex-col mb-10 '>
         <div className='relative w-full h-[400px] mx-h-[350px]'>
          <img src={cover} className='w-full object-cover h-[400px] mx-h-[350px]' alt="cover"/>
          <div className='absolute top-[70%] w-full sm:h-[15%] xs:h-[15%] h-[20%] bg-white opacity-50 flex justify-center items-center'> </div>
            <p className='  xs:text-4xl sm:text-5xl xl:text-7xl md:text-6xl  font-bold text-[#0843aafe] text-center uppercase absolute w-full sm:top-[73%] xs:top-[73%] md:top-[73%] xl:top-[70%]'>
                Contacts
              </p>
        </div>
        <div className=' grid md:grid-cols-3 xl:grid-cols-3 sm:grid-cols-1 xs:grid-cols-1 w-full  py-5 gap-5 justify-center items-center bg-[#ffffff]'> 
        <div className='md:col-span-1 xl:col-span-1 sm:col-span-1 xs:col-span-1 md:mx-10 xl:mx-10'>
            <p className='my-10 font-semibold text-xl'>Contact Information</p>
            <div className='space-y-3 text-left text-sm'>
                <p>Benadir University</p>
                <p>Wadada Jaamacadda Benadir</p>
                <p>Behind Seybiyano, Hodan</p>
                <p>Mogadishu - Somalia</p>
                <p>Help line: 2018</p>
                <p>Email: info@bu.edu.so</p>
            </div>
            <p className='my-3 font-semibold text-xl mt-10'>Social media</p>
            <div className='flex flex-col space-y-3 text-left text-sm'>
              <p>
             Facebook :  <a href="https://www.facebook.com/BenadirUniv?mibextid=ZbWKwL" className='mx-2 text-blue-900' target="_blank">Benadir University Facebook</a>
              </p>
              <p>Instagram :  
              <a href="https://www.instagram.com/benadiruniv" className='mx-2 text-blue-900' target="_blank">Benadir University Instagram</a>
              </p>
              <p>Twitter or X : 
              <a href="https://twitter.com/benadiruniversi" className='mx-2 text-blue-900' target="_blank">Benadir University X, Twitter</a>
              </p>
              <p>LinkedIn : 
              <a href="" className='mx-2 text-blue-900' target="_blank">Benadir University LinkedIn</a>
              </p>
              <p>Youtube : 
              <a href="https://www.youtube.com/@benadiruniv" className='mx-2 text-blue-900' target="_blank">Benadir University youtube</a>
              </p>
              <p>Tiktok : 
              <a href="https://www.tiktok.com/@benadiruniver" className='mx-2 text-blue-900' target="_blank">Benadir University Tiktok</a>
              </p>
            </div>

        </div>
        <div className='md:col-span-2 xl:col-span-2 sm:col-span-1 xs:col-span-1'>
          <div>
            <p>Dr.Adow Campus location </p>
            <div>
            <Map mapLib={import('mapbox-gl')}
                 initialViewState={{
                    longitude:45.299918925155104,
                    latitude: 2.0410916772219605,
                    zoom: 16
                  }}
                  mapboxAccessToken='pk.eyJ1IjoiYnUtbWFwMSIsImEiOiJjbHZwYWg3ZzgwMDI5MmtwcHN6eDcwa3M5In0.5ZV_ARcySxWcU3iv0l7lLw'
                  style={{width: 600, height: 400}}
                  mapStyle="mapbox://styles/mapbox/streets-v9"
                />;
            </div>
          </div>
          {/* <div>
            <p>Dr.Shahid campus location </p>
          </div>
          <div>
            <p>Postgraduate & Innovation Campus</p>
          </div>
          <div>
            <p>Afgoi ( field Training) Campus </p>
          </div> */}

        </div>


        
      

       
      
      </div>
      </div>
   
  )
}

export default Bucontacts