import React from "react";
import {rules} from "../../../data/adminsionrules"

const Documents = () => {

  return (
         <div  className="md:mx-20 md:px-20 xl:mx-20 xl:px-20 flex flex-col justify-evenly text-[#0863AA] mx-3 my-20">
          {
            rules.map( r => (
              <div key={r.id} className="leading-loose">
              <p className="text-2xl my-5">{r.title}</p>
              <p className="text-black md:text-[18px] xl:text-[18px]  text-[15px]  leading-loose">{r.desc}</p>
              {r.parts.length > 0 && 
              r.parts.map( p => (
                <div key={p.id} className="text-black">
                  <div className="mx-8"> 
                  <p className="font-semibold my-2 text-xl">{p.title}</p>
                  <p className="mx-5 my-2">{p.desc1}</p>
                  <ol className="list-disc ml-16">{p.list1.map(
                   (l,i) => (<li key={i}>{l}</li>)
                  )}</ol>
                  </div>
                  <div className="mx-8"> 
                    {p.desc2 && 
                    <p className="mx-5 my-2">{p.desc2}</p>
                    }
                  {p.list2 && 
                  <ol className="list-disc ml-16">{p.list2.map(
                    (l,i) => (<li key={i}>{l}</li>)
                  )}</ol>
                  }
                  </div>
                  
                </div>
              ))}
  
            </div>
            ))
          }
         
        
      </div>

  );
};

export default Documents;