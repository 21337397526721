import React from 'react'
import ProgramList from "./programList"
import _ from "lodash"
import { useState } from 'react'



function SelectInput({schools}) {
    // const schools = postgradute
    const [selectedProgram,setSelectedProgram] = useState()

    const school = _.map(schools, "school")
    const allSchools = _.uniq(school);
    let program;
    if(selectedProgram === undefined || selectedProgram === "ALL"){
         program =  schools
    }
    else{
         program =  _.filter(schools, ['school', (selectedProgram)])
    }
 
    const handleChange = (e) => {
        setSelectedProgram(e.currentTarget.value);
        
    }   



  return (
    <>
    
    <div className='flex flex-row justify-center items-center py-5'>
        <label htmlFor="Filter" className='text-[15px] font-base bg-slate-300 py-2 px-3 rounded-lg rounded-r-none'>Filter</label> 
    <select name="filter" id="filter" value={selectedProgram} onChange={(e) => handleChange(e)} className="py-2 px-4 pe-16  md:w-[50%] xl:w-[40%]  rounded-lg rounded-l-none text-sm xs:text-[12px] focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600" defaultValue="ALL">
    <option  value={"ALL"} >ALL</option>
   {allSchools.map( p =>(
           <option key={p} value={p} >{p}</option>
   )
   )
   }
  </select>
  </div>
   <ProgramList list={program} />

  
  </>
  )
}

export default SelectInput