import React,{useState} from "react";
import { AiOutlineRead } from "react-icons/ai";
import { GrClose } from "react-icons/gr";
import { MdDownload } from "react-icons/md";
import newscoverFeb2025 from "../../../assets/images/newsletter/coverpageFEB2025.jpg"
import newsletterFeb2025 from "./bunnewsfeb2025.pdf"
import PdfViewer from './PDFViewer';


  const NewsLetters = props => {
  
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [pdfUrl, setPdfUrl] = useState("");

  const openModal = (url) => {
    setPdfUrl(url);
    setIsModalOpen(true);
  }
 
  const newsletter = [
    {
        id: 1, 
        img: newscoverFeb2025, 
        fileUrl: newsletterFeb2025
    },
  ]


  return (
    <>
        <div className="xl:pt-[95px] md:pt-[95px] sm:pt-[95px] xs:pt-[75px]">
          <div className="px-5">
            <div className="pt-10 md:mx-6 xl:mx-10">
             <p className="text-xl xs:text-base">Stay updated with the latest news, events, and announcements from Benadir University through our official newsletter.</p>
            </div>
          <div className="grid  xl:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 xs:grid-cols-1 gap-4 place-items-center justify-center w-full my-2 py-3">
         
          {newsletter.map((e, i) => (
            <div 
              key={i} 
              className='bg-white flex flex-col w-fit mx-3 p-5 my-5 justify-center rounded-lg space-y-4 border shadow-lg relative group'
            >
              <div className="relative">
                <img 
                  src={e.img} 
                  alt="avatar" 
                  className='xl:h-[600px] md:h-[300px] sm:h-[300px] xs:h-[200px] rounded-md' 
                />
                <div className="absolute bottom-0 w-full bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity duration-300 space-x-5 flex  items-start justify-center text-white py-8 rounded-t-md">
                <button type="button"  className="cursor-pointer text-3xl " onClick={() => openModal(e.fileUrl)}>
                <AiOutlineRead />
                </button>
                  <a href={e.fileUrl} target="_blank" className="cursor-pointer text-3xl"
                rel="noopener noreferrer"> <MdDownload /></a>
                </div>
              </div>
            </div>
          ))}

          </div>
          </div>
           <div className={`fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm flex justify-end items-start z-30 ease-in duration-300 ${isModalOpen ? "" : "hidden"}`} >
            <div className="flex flex-col w-full">
            <div className="flex w-full justify-end py-2 px-5"><GrClose className="text-3xl text-white bg-white" onClick={() => setIsModalOpen(!isModalOpen)}/></div>
           <PdfViewer fileUrl={pdfUrl} />
          </div>
          </div>
          </div>

          </>

  );
};

export default NewsLetters;