import React from 'react'

import MenuOptions from "../../sections/menuOptions"
import FacultyList from './facultyLIst'
import Schools from '../admission/schools'
import NewsLinks from './newsLinks'
import { Link } from 'react-router-dom'
import {programs} from '../../../data/programs'



const backet = 'https://bu-page-assets.s3.ap-northeast-1.amazonaws.com/static-assets';


const cover =  backet + '/others/cover.jpeg'

const Undergrad = (props) => {
   

  return (

    // panel
    <div className=''>

        <div className='relative w-full xl:h-[558px] md:h-[558px]'>
          <img src={cover} className='w-full object-cover xl:h-[558px] md:h-[558px]' alt="cover"/>
          <div className='absolute top-[65%] md:top-[80%] xl:top-[80%] sm:top-[80%] xs:top-[80%]  w-full h-[15%] bg-white opacity-60 flex justify-center items-center'></div>
          <p className=' md:text-6xl xl:text-5xl xs:text-xl sm:text-3xl font-bold text-[#0843aafe] text-center  md:top-[83%] xl:top-[84%] sm:top-[82%] xs:top-[83%]  absolute w-full uppercase'> undergraduate PROGRAMS</p>
        </div>

      


      <div className=" flex flex-row justify-center items-center my-10 mb-16 ">
        
      
      <div className='flex flex-col'>

       
         
      

      {programs.map( (p)=>
                 (
            <div key={p.id} className=' h-full   w-full px-2 font-sans text-black text-[18px] '>
               <h1 className='text-[24px] font-bold my-4'>
                  {p.title}
               </h1>
               <p className='  pt-2 whitespace-pre-line'>
                  {p.description}
               </p>
               <Schools list={p.lists}/>

      </div>
            ))}
      
      
    { props.id === "2"  && <p>
     For more information, visit our 
     <Link to="" className='px-1 text-blue-600 '>
      Postgraduate page.
     </Link>
     </p>}
     </div>
    </div>
    </div>
  )
}

export default Undergrad;