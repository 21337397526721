import React from 'react'
import {acc} from '../../data/ourpartiners'
import {op} from '../../data/ourpartiners'
import {accre} from '../../data/accredits'


function Accreditations() {
  return (
    <div className="">
    <div className="flex flex-col items-center  justify-center  pt-32">
      <p className="uppercase text-3xl xs:text-lg font-inriaserif font-semibold xs:w-full xs:text-center ">Under maintainance</p>
      {/* <div className="flex flex-col justify-center my-2  place-items-center">
      <div className="bg-slate-200 h-[2px] md:w-[400px] xl:w-[400px] sm:w-full xs:w-full"></div>
      <div className="bg-black h-[2px] md:w-[100px] xl:w-[100px] sm:w-full xs:w-full"></div>
      </div>


      <div className="  flex justify-center items-center my-10 ">
        <p className='text-xl xs:text-base xs:mx-2 xl:w-[60%] md:w-[60%] whitespace-pre-line'>
        We are glad to announce that Benadir University  is fully accredited by locally Minsitry of Education in Somalia, United States Higher Education Accreditation Commission (USHEAC) and all our Programs are recognized by (USHEAC).<br/><br/>
        It serves as a cohesive entity that brings together higher education accreditation agencies, universities, and colleges striving for recognition from both the U.S. Department of Education and the Council for Higher Education Accreditation (CHEA). Benadir University is locally and globally accredited by the Ministry of Education in Somalia,  United States Higher Education Accreditation Commission, USHEAC) as well as California University Foreign Credential Evaluation (CU) a leading global certifying body that grants credential awards to individuals besides accrediting educational institutions and organizations. The USHEAC accredits courses and study programs delivered by universities, colleges, schools, professional institutions and training companies. <br/><br/>
        USHEAC & HEAC has accredited Benadir University of Science and Literature after carefully reviewing, validating and authenticating its study programs. The California department of Education and USHEAC examined the following parameters: course content and duration, teacher qualifications and expertise, financial strength and integrity of course providers, available facilities and resources, delivery and assessment method and most importantly the titles relevance to professional and career development, in line with Benadir University’s mission. The USHEAC & HEAC Board has recognized that Benadir University’s programs of study are up to global educational standards and conferred accreditation.
        </p>

      </div>
        
        <div className='flex flex-row my-24 xs:flex-col xs:space-y-11 '>
        <div className='flex flex-col space-y-5 items-center xl:w-[50%] md:w-[50%]'>
        <p className="uppercase text-2xl xs:text-lg font-inriaserif font-semibold">Accreditations & Memberships</p>
        <div className="bg-slate-200 h-[2px] md:w-[400px] xl:w-[400px] sm:w-full xs:w-full"></div>
       
        <div  className="grid grid-cols-5 md:grid-cols-3 sm:grid-cols-2 xs:grid-cols-2 gap-x-2 gap-y-8 place-items-center">
        {acc.map( (o) => (
          <div key={o.id} className="m-3 p-3">
            <img src={o.logo} alt="Logo" className="w-[150px] h-[150px] xs:w-[100px] xs:h-[100px] m-2"/>
          </div>
        ))}
      </div>
      <div className='xl:px-5 md:px-5 px-2'>
        {
          accre.map( a => (
            <div key={a.id} className='space-y-2 my-3'>
              <p className='text-base xs:text-sm font-bold'>{a.name}</p>
              <p className='xs:text-sm sm:text-sm'>{a.desc}</p>
            </div>  
          ))
        }
      </div>
      </div>
      <div className='flex flex-col space-y-5 justify-center items-center xl:border-l-2 md:border-l-2 xl:w-[50%] md:w-[50%]'>
        <p className="uppercase text-2xl xs:text-lg font-inriaserif font-semibold">Partnerships </p>
        <div className="bg-slate-200 h-[2px] md:w-[400px] xl:w-[400px] sm:w-full xs:w-full"></div>
       
        <div  className="grid grid-cols-5 md:grid-cols-3 sm:grid-cols-2 xs:grid-cols-2 gap-x-2 gap-y-8 place-items-center">
        {op.map( (o) => (
          <div key={o.id} className="m-3 p-3">
            <img src={o.logo} alt="Logo" className="w-[150px] h-[150px] xs:w-[100px] xs:h-[100px] m-2"/>
          </div>
        ))}
      </div>
      </div>
      
      
        </div>
    */}


    </div>
  </div>
  )
}

export default Accreditations