import React,{useState} from "react";
import { AiOutlineRead } from "react-icons/ai";
import { GrClose } from "react-icons/gr";
import { MdDownload } from "react-icons/md";
import {journal} from "../../../data/journals"
import PdfViewer from './PDFViewer';

const Journals = props => {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");

  const openModal = (url) => {
    setPdfUrl(url);
    setIsModalOpen(true);
  }
 


  return (
        <>
          <div className="px-5">
            <div className="bg-[#EDF4F4] ">
              <div className="col-span-10 w-full">
                <input
                  type="text"
                  placeholder="Search Here"
                  className="w-full py-3 px-3 outline-none rounded-md"
                />
              </div>
            </div>
          <div className="grid  xl:grid-cols-6 md:grid-cols-5 sm:grid-cols-2 xs:grid-cols-1 gap-4 place-items-center w-full my-2 py-3">
          {
            journal.map( j => (
            <div key={j.id} className="border-2 border-cyan-500 w-[250px] h-[300px] flex flex-col justify-center bg-gray-200">
              <div className="relative flex flex-col justify-center items-center h-[80%] px-5 space-y-3 w-full">
                <p className="text-xl font-semibold">{j.title}</p>
                <p className="text-base">{j.subtitile}</p>
              </div>
              <div className="w-full text-3xl flex justify-center h-[20%] space-x-2 px-3 py-5">
                <button type="button"  className="cursor-pointer" onClick={() => openModal(j.fileUrl)}>
                <AiOutlineRead />
                </button>
                  <a href={j.doi} target="_blank"
                rel="noopener noreferrer"> <MdDownload /></a>
              </div>
            </div>
            ))
          }
          </div>
          </div>
           <div className={`fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm flex justify-end items-start z-30 ease-in duration-300 ${isModalOpen ? "" : "hidden"}`} >
            <div className="flex flex-col w-full">
            <div className="flex w-full justify-end py-2 px-5"><GrClose className="text-3xl text-white bg-white" onClick={() => setIsModalOpen(!isModalOpen)}/></div>
           <PdfViewer fileUrl={pdfUrl} />
          </div>
          </div>

          </>

  );
};

export default Journals;