import React, { useEffect, useState} from "react";
import ReactPaginate from 'react-paginate';
import {FaAngleDoubleLeft, FaAngleDoubleRight} from 'react-icons/fa'
import Items from './items'

function PaginatedItems({ list,itemsPerPage }) {

  const [itemOffset, setItemOffset] = useState(0);

  let currentItems;
  let pageCount;

  const endOffset = itemOffset + itemsPerPage;

  if(list){
     currentItems = list.slice(itemOffset, endOffset);
     pageCount = Math.ceil(list.length / itemsPerPage);
  }
  // console.log(currentItems)

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % list.length;
    setItemOffset(newOffset);
  };

  return (
    <div className="relative w-full text-black my-10  flex flex-col justify-center items-center">
      <Items currentItems={currentItems} />
      <div className="flex justify-end w-[70%] ">
      <ReactPaginate
        className="flex flex-row justify-end border-2 px-3 items-center space-x-5"
        nextLabel={<FaAngleDoubleRight />}
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        marginPagesDisplayed={2}
        pageCount={pageCount}
        previousLabel={<FaAngleDoubleLeft />}
        pageClassName="px-2 py-1"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link"
        containerClassName="bg-slate-200"
        activeClassName="bg-[#0863AA] text-white px-3 py-1"
        renderOnZeroPageCount={null}
      />
      </div>
    </div>
  );
}

export default PaginatedItems

