import React, { useState, useEffect } from "react";
import { IoMdClose } from "react-icons/io";
import * as yup from "yup";
import { Formik, Field } from "formik";
import { Link, useLocation, useNavigate } from "react-router-dom";
import PageTitle from "../../general/pageTitle";
import api from '../../../config.json'
import { TagsInput } from "react-tag-input-component";
import { RenderUploadButton } from "../../general/renderSingleFile";
import { updateStaff } from "../../../services/staffServices";

const FILE_SIZE = 1024;
const MAX_FILE_SIZE = 1024 * 1024 * 3;

function EditStaff(props) {
  const [reserachInterest, setReserachInterest] = useState([]);
  const [avatar, setAvatar] = useState();

  
  const {state } = useLocation()
  const s = state.staff

  

  const navigate = useNavigate()

  const validFileExtensions = {
    image: ["jpg", "png", "jpeg"],
  };

  function isValidFileType(fileName, fileType) {
    return (
      fileName &&
      validFileExtensions[fileType].indexOf(fileName.split(".").pop()) > -1
    );
  }

  const validationSchema = yup.object().shape({
    title: yup.string("Select staff title").required("Select staff title"),
    where: yup.string("Select the dept.").required("Select the dept."),
    bio: yup.string("Enter staff Bio"),
    email: yup
      .string("Enter your email")
      .email("Enter a valid email")
      .required("Email is required"),
    fullName: yup
      .string("Enter Staff Full Name")
      .required("full Name is required"),
    staffId: yup.string("Enter staff ID").required("Staff ID is required"),
    mobileNumber: yup
      .string("Enter mobile number")
      .required("phone number is required"),
    staffAvatar: yup
      .mixed()
      .required("upload staff avatar.")
      .test("is-valid-type", "Not a valid image type", (value) =>
        isValidFileType(value && value.name, "image")
      )
      .test(
        "FILE_SIZE",
        "Max allowed size is 3MB",
        (value) => !value || (value && value.size <= MAX_FILE_SIZE)
      ),
  });


  const url = api.url;

  function GetFilename(url) {
    if (url) return url.substring(url.lastIndexOf("\\") + 1);
    return "";
  }

  useEffect(() => {
    async function createFile(url, path) {
      if (path !== null || path !== undefined) {
        const fileName = GetFilename(path);
        const path1 = path.replace(/\\/g, "/");
        const response = await fetch(url + path1);
        const data = await response.blob();
        let metadata = {
          type: data.type || "image/jpeg",
        };
        let file = new File([data], fileName, metadata);
        setAvatar(file);
      }
    }
    createFile(url, s.avatar);

    setReserachInterest(s.reserachInterest)
  }, []);



  return (
    <>
      <PageTitle title="Update Staff" subTitle="Staff" />

      <div className=" w-full overflow-auto  ">
        <div className="flex flex-col justify-center p-4 ">
          <Formik enableReinitialize={true}
            initialValues={{
              title: s.title,
              where: s.where,
              fullName: s.fullName,
              mobileNumber: s.mobileNumber,
              email: s.email,
              staffId: s.staffId,
              bio: s.bio,
              staffAvatar: avatar,
            }}
            validationSchema={validationSchema}
            onSubmit={async (values, actions) => {
              if (values.title === "--select title--") {
                actions.setSubmitting(false);
                actions.setErrors({ title: `please select correct title` });
                return;
              }
              if (values.where === "--select department--") {
                actions.setSubmitting(false);
                actions.setErrors({
                  where: `please select correct department`,
                });
                return;
              }
              if (values.occupations === "--select occupation--") {
                actions.setSubmitting(false);
                actions.setErrors({
                  occupations: `please select correct occupation`,
                });
                return;
              }
              const resp = await updateStaff({ ...values, reserachInterest });
              navigate("/staff-list")

            }}
          >
            {({
              values,
              setFieldValue,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => (
              <form
                onSubmit={handleSubmit}
                className="flex flex-col bg-white border shadow-sm rounded-xl"
              >
                <div className="flex justify-between items-center py-2.5 px-4 border-b">
                  <h3 className="font-bold text-gray-800">
                    Register new staff
                  </h3>
                  <Link
                    to="/staff-list"
                    className="inline-flex flex-shrink-0 justify-center items-center h-8 w-8 rounded-md text-gray-500 hover:text-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 focus:ring-offset-white transition-all text-sm"
                  >
                    <IoMdClose />
                  </Link>
                </div>
                <div className="p-4 overflow-y-auto">
                  <div className="grid lg:grid-cols-2 gap-6">
                  <div>
                      <label
                        htmlFor="validationDefault01"
                        className="text-gray-800 text-sm font-medium inline-block mb-2"
                      >
                        staff ID
                      </label>
                      <input readOnly
                        className="form-input"
                        name="staffId"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.staffId}
                      />
                      {errors.staffId && touched.staffId && errors.staffId}
                    </div>
                    <div>
                      <label
                        htmlFor="example-select"
                        className="text-gray-800 text-sm font-medium inline-block mb-2"
                      >
                        Title{" "}
                      </label>
                      <select
                        name="title"
                        value={values.title}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="form-select"
                      >
                        <option value="--select title--">
                          --select title--
                        </option>
                        <option value="Mr">Mr</option>
                        <option value="Dr">Dr</option>
                        <option value="Prof">Prof</option>
                        <option value="Eng">Eng</option>
                        <option value="Ms">Ms</option>
                      </select>
                      {errors.title && touched.title && errors.title}
                    </div>
                    
                    <div>
                      <label
                        htmlFor="example-select"
                        className="text-gray-800 text-sm font-medium inline-block mb-2"
                      >
                        Where{" "}
                      </label>
                      <select
                        name="where"
                        value={values.where}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="form-select"
                      >
                        <option value="--select department--">
                          --select department--
                        </option>
                        <option value="Academic">Academic</option>
                        <option value="Office">Office</option>
                        <option value="Both">Both</option>
                      </select>
                      {errors.where && touched.where && errors.where}
                    </div>

                    <div>
                      <label
                        htmlFor="validationDefault01"
                        className="text-gray-800 text-sm font-medium inline-block mb-2"
                      >
                        Full name
                      </label>
                      <input
                        className="form-input"
                        name="fullName"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.fullName}
                      />
                      {errors.fullName && touched.fullName && errors.fullName}
                    </div>
                   
                    <div className="mb-3">
                      <label className="text-gray-800 text-sm font-medium inline-block mb-2">
                        Mobile No.
                      </label>
                      <input
                        className="form-input"
                        name="mobileNumber"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.mobileNumber}
                        placeholder="(xxx) x-xxxxxx"
                      />
                      {errors.mobileNumber &&
                        touched.mobileNumber &&
                        errors.mobileNumber}
                    </div>
                    <div>
                      <label
                        htmlFor="validationDefaultUsername"
                        className="text-gray-800 text-sm font-medium inline-block mb-2"
                      >
                        Email
                      </label>
                      <div className="flex items-center">
                        <span className="py-2 px-3 bg-light rounded-l">@</span>
                        <input
                          type="email"
                          className="form-input rounded-l-none "
                          name="email"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email}
                        />
                      </div>
                      {errors.email && touched.email && errors.email}
                    </div>
                    <div className="md:col-span-3 xl:col-span-3">
                      <label
                        htmlFor="validationDefault03"
                        className="text-gray-800 text-sm font-medium inline-block mb-2"
                      >
                        Research Area (
                        <em>press enter or comma to add new tag</em>)
                      </label>
                      {/* <ResearchAreaSeclector className="w-full" /> */}
                      <div className="w-full">
                        <TagsInput
                          classNames="border-none"
                          value={reserachInterest}
                          onChange={setReserachInterest}
                          onBlur={handleBlur}
                          name="selected"
                        />
                      </div>
                    </div>
                    <div className="md:col-span-3 xl:col-span-3">
                      <label
                        htmlFor="validationDefault03"
                        className="text-gray-800 text-sm font-medium inline-block mb-2"
                      >
                        Biography
                      </label>
                      <div className="flex">
                        <textarea
                          rows="4"
                          name="bio"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.bio}
                          className="form-textarea ltr:rounded-l-none rtl:rounded-r-none"
                        ></textarea>
                      </div>
                      {errors.bio && touched.bio && errors.bio}
                    </div>
                    <div className="my-10 border-2 shadow-lg w-full p-3  md:col-span-3 xl:col-span-3">
                      {RenderUploadButton(
                        setFieldValue,
                        values,
                        errors,
                        "staffAvatar",
                        "image"
                      )}
                      {errors.staffAvatar &&
                        touched.staffAvatar &&
                        errors.staffAvatar}
                    </div>
                  </div>
                  <div className="flex justify-end items-center gap-x-2 py-2.5 px-4 border-t my-4">
                    {/* <button
                  type="submit"
                  disabled={isSubmitting}
                  className="btn bg-secondary text-white"
                >
                  Cancel
                </button> */}
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="btn bg-primary text-white"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}

export default EditStaff;
