import { http } from "./httpServices";
import api from "../config.json";

// const url = api.local + "/staff";
const url = api.mssqlglobal + "/portal";

// const url = apiUrl + "/Staff";

function portalUrl(id) {
  return `${url}/${id}`;
}

export function getAllUsers() {
  return http.get(url);
}

export function deleteUser(studentId) {
  return http.delete(portalUrl(studentId));
}

export async function getUser(studentId) {
  return http.get(portalUrl(studentId));
}

export function saveUser(student) {
  return http.post(url, student);
}

export function updateUser(student) {
    return http.put(portalUrl(student.studentID), {
      studentName: student.studentName,
      studentID: student.studentID,
      Password: student.Password,
    });

}
