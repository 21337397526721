const backet =
  "https://bu-page-assets.s3.ap-northeast-1.amazonaws.com/static-assets";


const icon2 = backet + "/careers/professional.PNG";

export const careers = [
  {
    id: 13,
    icon: icon2,
    title: "TEACHING OPPORTUNITY",
    careerid: "Lecturer-marine-science-3",
    location: "University Campus, Mogadishu- Somalia",
    jobType: "Part time",
    role: "Senior Lecturer in marine science",
    title1: {
      title: "JOB PURPOSE",
      desc: `We are seeking an experienced and dynamic Lecturers to join our faculty. The successful candidates will be responsible for teaching the following courses:
      <strong>Fish production dynamics</strong>, <strong>Anatomy & physiology of fish</strong>, <strong>Maritime transport of dangerours goods</strong>, <strong>Maritime terminology</strong> and <strong>Refrigeration system & storage</strong>.
      This role involves delivering high-quality instruction, developing curriculum, and fostering a positive learning environment that promotes academic excellence and student engagement.`,
    },
    title2: {
      title: "JOB DESCRIPTION: KEY RESPONSIBILITY",
      desc: "Following are the key responsibilities;",
      lst: [  
      "To be able to teach the courses mentioned above. Deliver engaging lectures and practical sessions on all courses. Develop and update course materials, including syllabi, lesson plans, and assessments.",
      "Provide students with a comprehensive understanding of the principles, regulations, and best practices in these fields.",
      "Use a variety of instructional methods to facilitate learning and accommodate different learning styles. Design and implement course curricula that reflect current trends and practices in the maritime industry. Collaborate with department colleagues to ensure alignment of course objectives with program goals.",
      "Provide academic advising and mentorship to students. Assess student performance through assignments, exams, and practical assessments, offering constructive feedback. Foster a supportive classroom environment that encourages active participation and critical thinking.",
      "Stay updated on industry developments, regulations, and emerging trends in shipping, port management, and the transportation of dangerous goods. Establish and maintain relationships with industry professionals to enhance the learning experience for students. Organize guest lectures, field trips, and other activities that connect students with industry practitioners.",
      ]
    },

    title3: {
      title: "KEY QUALIFICATIONS AND SKILLS",
      desc: "Minimum Requirements",
      lst: [
        "Master degree in marine science or equivalent degree.",
        "A minimum of two years of experience.",
        "Excellent organization, communication and interpersonal skills."
      ],
    },
   
    Howtoapply: [
      `Interested applicants, please send your CV and cover letter to <strong><a href="mailto:hrm@bu.edu.so" target="_blank">hrm@bu.edu.so<a/></strong>, until 25<sup>Th</sup> January 2025`,
      "A cover latter in English, A detailed CV including a track record of previous experience and professional capacity, All Academic documents and other supporting documents including bachelor and master transcripts.",
      "Interviews for qualified candidates will be conducted in person.",
    ],
    isActive: true,
    expiredDate: "2025, 01, 25",
  },
  {
    id: 13,
    icon: icon2,
    title: "TEACHING OPPORTUNITY",
    careerid: "Lecturer-marine-science-2",
    location: "University Campus, Mogadishu- Somalia",
    jobType: "Part time",
    role: "Lecturer in Shipping and Port Management & Maritime Transport of Dangerous Goods",
    title1: {
      title: "JOB PURPOSE",
      desc: `We are seeking an experienced and dynamic Lecturer to join our faculty. The successful candidate will be responsible for teaching courses in Shipping and Port Management as well as Maritime Transport of Dangerous Goods. This role involves delivering high-quality instruction, developing curriculum, and fostering a positive learning environment that promotes academic excellence and student engagement.`,
    },
    title2: {
      title: "JOB DESCRIPTION: KEY RESPONSIBILITY",
      desc: "Following are the key responsibilities;",
      lst: [  
      "To be able to teach the following courses: 1) Shipping and Port management 2)Maritime Transport of Dangerous Goods. Deliver engaging lectures and practical sessions on Shipping and Port Management and Maritime Transport of Dangerous Goods. Develop and update course materials, including syllabi, lesson plans, and assessments.",
      "Provide students with a comprehensive understanding of the principles, regulations, and best practices in these fields.",
      "Use a variety of instructional methods to facilitate learning and accommodate different learning styles. Design and implement course curricula that reflect current trends and practices in the maritime industry. Collaborate with department colleagues to ensure alignment of course objectives with program goals.",
      "Provide academic advising and mentorship to students. Assess student performance through assignments, exams, and practical assessments, offering constructive feedback. Foster a supportive classroom environment that encourages active participation and critical thinking.",
      "Stay updated on industry developments, regulations, and emerging trends in shipping, port management, and the transportation of dangerous goods. Establish and maintain relationships with industry professionals to enhance the learning experience for students. Organize guest lectures, field trips, and other activities that connect students with industry practitioners.",
      "Engage in scholarly activities and research within the fields of shipping and port management, and maritime transport of dangerous goods. Participate in professional development opportunities to remain current in the field.Contribute to the academic community through publications, conferences, and workshops.",
      ]
    },

    title3: {
      title: "KEY QUALIFICATIONS AND SKILLS",
      desc: "Minimum Requirements",
      lst: [
        "Master degree in marine science or equivalent degree.",
        "A minimum of two years of experience.",
        "Excellent organization, communication and interpersonal skills."
      ],
    },
   
    Howtoapply: [
      "Interested applicants, please send your CV and cover letter to hrm@bu.edu.so, until 14Th September 2024",
      "A cover latter in English, A detailed CV including a track record of previous experience and professional capacity, All Academic documents and other supporting documents including bachelor and master transcripts.",
      "Interviews for qualified candidates will be conducted in person.",
    ],
    isActive: false,
    expiredDate: "2024, 09, 14",
  },
  {
    id: 12,
    icon: icon2,
    title: "Midwife at Benadir University Teaching Hospital",
    careerid: "Midwife",
    location: "Benadir University Teaching Hospital, Mogadishu - Somalia",
    title1: {
      title: "Overview",
      desc: `<span className="font-bold text-base">Reporting To:</span> Hospital Administrative Director
      <span className="font-bold text-base">Duration:</span> 1 year, renewbale based on performance
      <span className="font-bold text-base">Purpose of the Position</span>
      The Midwife will provide comprehensive midwifery care to patients in the Obstetrics and Gynecology department, including antenatal, intrapartum, and postnatal care. This role also includes patient education, collaboration with medical staff, and contribution to teaching and training activities within the department.

      <span className="font-bold text-xl">Key Responsibilities:</span>

      <span className="font-semibold text-base mx-2 my-3">a) Clinical Duties:</span>
      <span className="mx-8">Provide antenatal, intrapartum, and postnatal care.</span>
      <span className="mx-8">Assist in labor and delivery, including handling normal and high-risk pregnancies.</span>
      <span className="mx-8">Conduct gynecological examinations and screenings.</span>
      <span className="mx-8">Manage obstetric and gynecological emergencies under the supervision of the Obstetrics and Gynecology doctor.</span>
      <span className="mx-8">Maintain detailed and accurate medical records for all patients.</span>

      <span className="font-semibold text-base mx-2 my-3">b) Patient Education:</span>
      <span className="mx-8">Educate patients and families on prenatal care, childbirth, postpartum care, and newborn care.</span>
      <span className="mx-8">Conduct community outreach programs to promote maternal and reproductive health.</span>

      <span className="font-semibold text-base mx-2 my-3">c) Collaboration:</span>
      <span className="mx-8">Work closely with doctors, nurses, and other healthcare professionals to provide comprehensive care.</span>
      <span className="mx-8">Participate in departmental meetings and contribute to the development of policies and protocols.</span>

      <span className="font-semibold text-base mx-2 my-3">d) Teaching and Training:</span>
      <span className="mx-8">Assist in training and supervising medical students, interns, and junior midwives.</span>
      <span className="mx-8">Participate in continuous education programs and workshops.</span>

      <span className="font-semibold text-base mx-2 my-3">e) Research and Development:</span>
      <span className="mx-8">Engage in clinical research projects and contribute to scientific publications.</span>
      <span className="mx-8">Stay updated with the latest advancements in midwifery and obstetric care.</span>
      <span className="mx-8">Promote and implement evidence-based practices within the department.</span>

      <span className="font-bold text-xl">Qualifications:</span>
      <span className="mx-8">Diploma or degree in Midwifery from a recognized institution.</span>
      <span className="mx-8">Minimum of 2 years of experience in midwifery, preferably in a hospital setting.</span>
      <span className="mx-8">Strong clinical skills in antenatal, intrapartum, and postnatal care.</span>
      <span className="mx-8">Excellent communication and interpersonal skills.</span>
      <span className="mx-8">Ability to work in a multicultural and resource-limited setting.</span>
      <span className="mx-8">Proficiency in English and Somali.</span>

      <span className="font-bold text-xl">Key Competencies:</span>
      <span className="mx-8">Clinical expertise in midwifery.</span>
      <span className="mx-8">Compassionate and patient-centered care.</span>
      <span className="mx-8">Strong organizational and time-management skills.</span>
      <span className="mx-8">Ability to work collaboratively with healthcare teams.</span>
      <span className="mx-8">Adaptability and resilience in challenging environments.</span>

      <span className="font-bold text-xl">Remuneration</span>
      <span className="mx-8">Competitive salary of University Scale.</span> `
    },   
 Howtoapply: [
      "Interested candidates should apply not later than by <strong>26/07/2024</strong>",
      "Please note that only shortlisted candidate will be communicated for a competence-based interview.",
    ],
    isActive: false,
    expiredDate: "2024, 07, 26",
  },
  {
    id: 11,
    icon: icon2,
    title: "Obstetrics and Gynecology Doctor at Benadir University Teaching Hospital",
    careerid: "Obstetrics-and-Gynecology-Doctor",
    location: "Benadir University Teaching Hospital, Mogadishu - Somalia",
    title1: {
      title: "Overview",
      desc: `<span className="font-bold text-base">Reporting To:</span> Hospital Administrative Director
      <span className="font-bold text-base">Duration:</span> 1 year, renewbale based on performance
      <span className="font-bold text-base">Purpose of the Position</span>
      Benadir University Teaching and Community Hospital, opened in 2014, is a community and teaching hospital affiliated with Benadir University. This position will be responsible for the Department of Obstetrics and Gynecology and will serve as a consultant doctor for the department, encompassing both clinical and teaching aspects.

      <span className="font-bold text-xl">Key Responsibilities:</span>

      <span className="font-semibold text-base mx-2 my-3">a) Clinical Duties:</span>
      <span className="mx-8">Diagnose and treat patients in the obstetrics and gynecology department.</span>
      <span className="mx-8">Conduct routine and emergency obstetric and gynecological surgeries.</span>
      <span className="mx-8">Provide antenatal, perinatal, and postnatal care.</span>
      <span className="mx-8">Manage labor and delivery, including high-risk pregnancies.</span>
      <span className="mx-8">Perform gynecological examinations, screenings, and diagnostics.</span>
      <span className="mx-8">Handle gynecological emergencies and provide appropriate treatment.</span>
      <span className="mx-8">Maintain detailed and accurate medical records for all patients.</span>

      <span className="font-semibold text-base mx-2 my-3">b) Teaching and Training:</span>
      <span className="mx-8">Supervise and train medical students, interns, and junior doctors.</span>
      <span className="mx-8">Conduct lectures, seminars, and practical training sessions for the interns.</span>
      <span className="mx-8">Participate in continuous medical education programs.</span>
      <span className="mx-8">Liaise with the Faculty of Medicine and the Faculty of Health Sciences for practical pediatric sessions at the university each semester.</span>


      <span className="font-semibold text-base mx-2 my-3">c) Research and Development:</span>
      <span className="mx-8">Engage in clinical research projects and contribute to scientific publications</span>
      <span className="mx-8">Stay updated with the latest advancements in obstetrics and gynecology.</span>
      <span className="mx-8">Promote and implement evidence-based practices within the department.</span>
     
      <span className="font-semibold text-base mx-2 my-3">d) Administrative Duties:</span>
      <span className="mx-8">Participate in departmental meetings and hospital committees.</span>
      <span className="mx-8">Assist in the development and implementation of departmental policies and protocols.</span>
      <span className="mx-8">Contribute to quality improvement initiatives and ensure compliance with medical standards and regulations.</span> 

      <span className="font-semibold text-base mx-2 my-3">e) Community Engagement:</span>
      <span className="mx-8">Conduct community outreach programs to educate the public on maternal and reproductive health</span>
      <span className="mx-8">Collaborate with local and international health organizations to improve healthcare services.</span>

      <span className="font-bold text-xl">Qualifications:</span>
      <span className="mx-8">Medical degree with specialization in Obstetrics and Gynecology. from recognized university.</span>
      <span className="mx-8">Minimum of 5 years of experience in obstetrics and gynecology, preferably in the teaching sector at a university hospital.</span>
      <span className="mx-8">Proven experience in teaching and supervising medical students.</span>
      <span className="mx-8">Strong clinical and surgical skills.</span>
      <span className="mx-8">Excellent communication and interpersonal skills.</span>
      <span className="mx-8">Ability to work in a multicultural and resource-limited setting.</span>
      <span className="mx-8">Proficiency in English and Somali.</span>

      <span className="font-bold text-xl">Key Competencies:</span>
      <span className="mx-8">Clinical expertise in obstetrics and gynecology.</span>
      <span className="mx-8">Leadership and mentoring abilities.</span>
      <span className="mx-8">Strong organizational and time-management skills.</span>
      <span className="mx-8">Commitment to patient-centered care and medical ethics.</span>
      <span className="mx-8">Ability to work collaboratively with healthcare teams.</span>
      <span className="mx-8">Adaptability and resilience in challenging environments.</span>

      <span className="font-bold text-xl">Remuneration</span>
      <span className="mx-8">Competitive salary of University Scale.</span> `
    },   
 Howtoapply: [
      "Interested candidates should apply not later than by <strong>26/07/2024</strong>",
      "Please note that only shortlisted candidate will be communicated for a competence-based interview.",
    ],
    isActive: false,
    expiredDate: "2024, 07, 26",
  },
  {
    id: 10,
    icon: icon2,
    title: "Laboratory Technician at Benadir University Teaching Hospital",
    careerid: "Laboratory-Technician",
    location: "Benadir University Teaching Hospital, Mogadishu - Somalia",
    title1: {
      title: "Overview",
      desc: `<span className="font-bold text-base">Reporting To:</span> Hospital Administrative Director
      <span className="font-bold text-base">Duration:</span> 1 year, renewbale based on performance
      <span className="font-bold text-base">Purpose of the Position</span>
      The Laboratory Technician will be responsible for performing laboratory tests and procedures to support the diagnosis and treatment of patients. This role includes maintaining laboratory equipment, ensuring quality control, and collaborating with medical staff to provide accurate and timely test results.

      <span className="font-bold text-xl">Key Responsibilities:</span>

      <span className="font-semibold text-base mx-2 my-3">a) Clinical Duties:</span>
      <span className="mx-8">Perform routine and specialized laboratory tests in areas such as hematology, microbiology, biochemistry, and immunology.</span>
      <span className="mx-8">Collect, process, and analyze biological samples.</span>
      <span className="mx-8">Ensure the accurate and timely reporting of test results to medical staff.</span>
      <span className="mx-8">Maintain detailed and accurate laboratory records.</span>

      <span className="font-semibold text-base mx-2 my-3">b) Equipment Maintenance:</span>
      <span className="mx-8">Operate and maintain laboratory equipment and instruments.</span>
      <span className="mx-8">Perform regular calibration and quality control checks.</span>
      <span className="mx-8">Ensure the cleanliness and safety of the laboratory environment.</span>

      <span className="font-semibold text-base mx-2 my-3">c) Quality Control and Assurance:</span>
      <span className="mx-8">Implement and monitor quality control procedures.</span>
      <span className="mx-8">Participate in proficiency testing and external quality assessment programs.</span>
      <span className="mx-8">Ensure compliance with laboratory safety standards and regulations.</span>

      <span className="font-semibold text-base mx-2 my-3">d) Collaboration:</span>
      <span className="mx-8">Work closely with doctors, nurses, and other healthcare professionals to support patient care.</span>
      <span className="mx-8">Participate in departmental meetings and contribute to the development of policies and protocols.</span>

      <span className="font-semibold text-base mx-2 my-3">e) Teaching and Training:</span>
      <span className="mx-8">Assist in training and supervising laboratory students and junior technicians.</span>
      <span className="mx-8">Participate in continuous education programs and workshops.</span>

      <span className="font-semibold text-base mx-2 my-3">f) Research and Development:</span>
      <span className="mx-8">Engage in clinical research projects and contribute to scientific publications.</span>
      <span className="mx-8">Stay updated with the latest advancements in laboratory technology and practices.</span>
      <span className="mx-8">Promote and implement evidence-based practices within the laboratory</span>


      <span className="font-bold text-xl">Qualifications:</span>
      <span className="mx-8">Bachelor's degree in Medical Laboratory Technology from a recognized institution.</span>
      <span className="mx-8">Minimum of 2 years of experience as a laboratory technician, preferably in a hospital setting.</span>
      <span className="mx-8">Strong technical skills in laboratory testing and analysis.</span>
      <span className="mx-8">Excellent communication and interpersonal skills.</span>
      <span className="mx-8">Ability to work in a multicultural and resource-limited setting.</span>
      <span className="mx-8">Proficiency in English and Somali.</span>

      <span className="font-bold text-xl">Key Competencies:</span>
      <span className="mx-8">Technical expertise in laboratory procedures and equipment.</span>
      <span className="mx-8">Attention to detail and accuracy in test results.</span>
      <span className="mx-8">Strong organizational and time-management skills.</span>
      <span className="mx-8">Ability to work collaboratively with healthcare teams.</span>
      <span className="mx-8">Adaptability and resilience in challenging environments</span>

      <span className="font-bold text-xl">Remuneration</span>
      <span className="mx-8">Competitive salary of University Scale.</span> `
    },   
 Howtoapply: [
      "Interested candidates should apply not later than by <strong>26/07/2024</strong>",
      "Please note that only shortlisted candidate will be communicated for a competence-based interview.",
    ],
    isActive: false,
    expiredDate: "2024, 07, 26",
  },
  {
    id: 9,
    icon: icon2,
    title: "Pediatric Doctor at Benadir University Teaching Hospital",
    careerid: "Pediatric-Doctor",
    location: "Benadir University Teaching Hospital, Mogadishu - Somalia",
    title1: {
      title: "Overview",
      desc: `<span className="font-bold text-base">Reporting To:</span> Hospital Manager
      <span className="font-bold text-base">Duration:</span> 1 year, renewbale based on performance
      <span className="font-bold text-base">Purpose of the Position</span>
      Benadir University Teaching and Community Hospital, opened in 2014, is a community and teaching hospital affiliated with Benadir University. This position will be responsible for the Department of Pediatrics and will serve as a consultant doctor for the department, encompassing both clinical and teaching aspects.

      <span className="font-bold text-xl">Key Responsibilities:</span>

      <span className="font-semibold text-base mx-2 my-3">a) Clinical Duties:</span>
      <span className="mx-8">Diagnose and treat patients in the pediatrics department.</span>
      <span className="mx-8">Conduct routine and emergency pediatric surgeries.</span>
      <span className="mx-8">Provide neonatal, infant, and child healthcare.</span>
      <span className="mx-8">Manage pediatric emergencies and provide appropriate treatment.</span>
      <span className="mx-8">Perform pediatric examinations, screenings, and diagnostics.</span>
      <span className="mx-8">Maintain detailed and accurate medical records for all patients.</span>


      <span className="font-semibold text-base mx-2 my-3">b) Teaching and Training:</span>
      <span className="mx-8">Supervise and train medical students, interns, and junior doctors.</span>
      <span className="mx-8">Conduct lectures, seminars, and practical training sessions for the interns.</span>
      <span className="mx-8">Participate in continuous medical education programs.</span>
      <span className="mx-8">Liaise with the Faculty of Medicine and the Faculty of Health Sciences for practical pediatric sessions at the university each semester.</span>


      <span className="font-semibold text-base mx-2 my-3">c) Research and Development:</span>
      <span className="mx-8">Engage in clinical research projects and contribute to scientific publications.</span>
      <span className="mx-8">Stay updated with the latest advancements in pediatrics.</span>
      <span className="mx-8">Promote and implement evidence-based practices within the department.</span>


      <span className="font-semibold text-base mx-2 my-3">d) Administrative Duties:</span>
      <span className="mx-8">Participate in departmental meetings and hospital committees.</span>
      <span className="mx-8">Assist in the development and implementation of departmental policies and protocols.</span>
      <span className="mx-8">Contribute to quality improvement initiatives and ensure compliance with medical standards and regulations.</span>



      <span className="font-semibold text-base mx-2 my-3">e) Community Engagement:</span>
      <span className="mx-8">Conduct community outreach programs to educate the public on child health and nutrition.</span>
      <span className="mx-8">Collaborate with local and international health organizations to improve healthcare services.</span>

      <span className="font-bold text-xl">Qualifications:</span>
      <span className="mx-8">Medical degree with specialization in Pediatrics from a recognized university.</span>
      <span className="mx-8">Minimum of 5 years of experience in pediatrics, preferably in the teaching sector at a university hospital.</span>
      <span className="mx-8">Proven experience in teaching and supervising medical students.</span>
      <span className="mx-8">Strong clinical and surgical skills.</span>
      <span className="mx-8">Excellent communication and interpersonal skills.</span>
      <span className="mx-8">Ability to work in a multicultural and resource-limited setting.</span>
      <span className="mx-8">Proficiency in English and Somali.</span>

      <span className="font-bold text-xl">Key Competencies:</span>
      <span className="mx-8">Clinical expertise in pediatrics</span>
      <span className="mx-8">Leadership and mentoring abilities.</span>
      <span className="mx-8">Strong organizational and time-management skills.</span>
      <span className="mx-8">Commitment to patient-centered care and medical ethics.</span>
      <span className="mx-8">Ability to work collaboratively with healthcare teams.</span>
      <span className="mx-8">Adaptability and resilience in challenging environments.</span>


      <span className="font-bold text-xl">Remuneration</span>
      <span className="mx-8">Competitive salary of University Scale.</span> `
    },   
 Howtoapply: [
      "Interested candidates should apply not later than by <strong>26/07/2024</strong>",
      "Please note that only shortlisted candidate will be communicated for a competence-based interview.",
    ],
    isActive: false,
    expiredDate: "2024, 07, 26",
  },
  {
    id: 8,
    icon: icon2,
    title: "Pharmacist at Benadir University Teaching Hospital",
    careerid: "Pharmacist",
    location: "Benadir University Teaching Hospital, Mogadishu - Somalia",
    title1: {
      title: "Overview",
      desc: `<span className="font-bold text-base">Reporting To:</span> Hospital Administrative Director
      <span className="font-bold text-base">Duration:</span> 1 year, renewbale based on performance
      <span className="font-bold text-base">Purpose of the Position</span>
      The Pharmacist will be responsible for the preparation, dispensing, and management of medications prescribed to patients. This role includes ensuring the safe and effective use of pharmaceuticals, providing drug   information to healthcare professionals and patients, and contributing to the education and training of pharmacy students and staff.

      <span className="font-bold text-xl">Key Responsibilities:</span>

      <span className="font-semibold text-base mx-2 my-3">a) Clinical Duties:</span>
      <span className="mx-8">Prepare and dispense medications as prescribed by doctors.</span>
      <span className="mx-8">Provide accurate and comprehensive drug information to healthcare professionals and patients.</span>
      <span className="mx-8">Monitor patient medication therapies for drug interactions and contraindications.</span>
      <span className="mx-8">Conduct medication reconciliation and review patient medication histories.</span>
      <span className="mx-8">Maintain detailed and accurate records of all medications dispensed.</span>

      <span className="font-semibold text-base mx-2 my-3">b) Inventory Management:</span>
      <span className="mx-8">Manage the procurement, storage, and distribution of pharmaceuticals.</span>
      <span className="mx-8">Ensure proper storage conditions for medications to maintain their efficacy.</span>
      <span className="mx-8">Conduct regular inventory checks and manage stock levels to prevent shortages.</span>

      <span className="font-semibold text-base mx-2 my-3">c) Quality Control and Assurance:</span>
      <span className="mx-8">Implement and monitor quality control procedures for medication dispensing.</span>
      <span className="mx-8">Participate in internal and external audits of the pharmacy department.</span>
      <span className="mx-8">Ensure compliance with pharmacy laws, regulations, and hospital policies.</span>

      <span className="font-semibold text-base mx-2 my-3">d) Collaboration:</span>
      <span className="mx-8">Work closely with doctors, nurses, and other healthcare professionals to optimize patient care.</span>
      <span className="mx-8"> Participate in departmental meetings and contribute to the development of policies and protocols.</span>

      <span className="font-semibold text-base mx-2 my-3">e) Teaching and Training:</span>
      <span className="mx-8">Assist in training and supervising pharmacy students and junior pharmacists.</span>
      <span className="mx-8">Conduct educational sessions and workshops on safe medication practices.</span>
      <span className="mx-8">Participate in continuous education programs.</span>

      <span className="font-semibold text-base mx-2 my-3">f) Research and Development:</span>
      <span className="mx-8">Engage in clinical research projects and contribute to scientific publications.</span>
      <span className="mx-8">Stay updated with the latest advancements in pharmacology and pharmaceutical sciences</span>
      <span className="mx-8">Promote and implement evidence-based practices within the pharmacy department.</span>

      <span className="font-bold text-xl">Qualifications:</span>
      <span className="mx-8">Bachelor's degree in Pharmacy from a recognized institution.</span>
      <span className="mx-8">Minimum of 2 years of experience as a pharmacist, preferably in a hospital setting.</span>
      <span className="mx-8">Strong knowledge of pharmacology and pharmaceutical sciences.</span>
      <span className="mx-8">Excellent communication and interpersonal skills</span>
      <span className="mx-8">Ability to work in a multicultural and resource-limited setting.</span>
      <span className="mx-8">Proficiency in English and Somali.</span>

      <span className="font-bold text-xl">Key Competencies:</span>
      <span className="mx-8">Clinical expertise in pharmacology and medication management.</span>
      <span className="mx-8">Attention to detail and accuracy in medication dispensing.</span>
      <span className="mx-8">Strong organizational and time-management skills.</span>
      <span className="mx-8">Ability to work collaboratively with healthcare teams.</span>
      <span className="mx-8">Adaptability and resilience in challenging environments.</span>

      <span className="font-bold text-xl">Remuneration</span>
      <span className="mx-8">Competitive salary of University Scale.</span> `
    },   
 Howtoapply: [
      "Interested candidates should apply not later than by <strong>26/07/2024</strong>",
      "Please note that only shortlisted candidate will be communicated for a competence-based interview.",
    ],
    isActive: false,
    expiredDate: "2024, 07, 26",
  },
  {
    id: 7,
    icon: icon2,
    title: "Pediatric Nurse at Benadir University Teaching Hospital",
    careerid: "Pediatric-Nurse",
    location: "Benadir University Teaching Hospital, Mogadishu - Somalia",
    title1: {
      title: "Overview",
      desc: `<span className="font-bold text-base">Reporting To:</span> Pediatric Doctor
      <span className="font-bold text-base">Duration:</span> 1 year, renewbale based on performance
      <span className="font-bold text-base">Purpose of the Position</span>
      The Pediatric Nurse will provide comprehensive nursing care to neonates, infants, and children in the Pediatric Department. This role involves direct patient care, collaboration with the healthcare team, and participation in educational and community outreach activities.

      <span className="font-bold text-xl">Key Responsibilities:</span>

      
      <span className="font-semibold text-base mx-2 my-3">a) Clinical Duties:</span>
      <span className="mx-8">Provide direct nursing care to neonates, infants, and children.</span>
      <span className="mx-8">Assist in routine and emergency pediatric procedures and surgeries.</span>
      <span className="mx-8">Administer medications and treatments as prescribed by the Pediatric Doctor.</span>
      <span className="mx-8">Monitor patients’ conditions and report any significant changes to the Pediatric Doctor.</span>
      <span className="mx-8">Perform pediatric assessments, screenings, and diagnostics.</span>
      <span className="mx-8">Maintain detailed and accurate medical records for all patients</span>

      <span className="font-semibold text-base mx-2 my-3">b) Patient Education:</span>
      <span className="mx-8">Educate patients and families on child health, nutrition, and disease prevention.</span>
      <span className="mx-8">Provide guidance on newborn care and breastfeeding.</span>

      <span className="font-semibold text-base mx-2 my-3">c) Collaboration:</span>
      <span className="mx-8">Work closely with doctors, nurses, and other healthcare professionals to provide comprehensive care.</span>
      <span className="mx-8">Participate in departmental meetings and contribute to the development of policies and protocols.</span>

      <span className="font-semibold text-base mx-2 my-3">d) Teaching and Training:</span>
      <span className="mx-8">Assist in training and supervising nursing students and junior nurses.</span>
      <span className="mx-8">Participate in continuous education programs and workshops.</span>

      <span className="font-semibold text-base mx-2 my-3">e) Community Engagement:</span>
      <span className="mx-8">Conduct community outreach programs to promote child health and nutrition.</span>
      <span className="mx-8">Collaborate with local and international health organizations to improve pediatric healthcare services.</span>

      <span className="font-bold text-xl">Qualifications:</span>
      <span className="mx-8">Diploma or degree in Nursing with a specialization in Pediatrics from a recognized institution.</span>
      <span className="mx-8">Minimum of 2 years of experience in pediatric nursing, preferably in a hospital setting.</span>
      <span className="mx-8">Strong clinical skills in pediatric care.</span>
      <span className="mx-8">Excellent communication and interpersonal skills.</span>
      <span className="mx-8">Ability to work in a multicultural and resource-limited setting.</span>
      <span className="mx-8">Proficiency in English and Somali.</span>

      <span className="font-bold text-xl">Key Competencies:</span>
      <span className="mx-8">Clinical expertise in pediatric nursing.</span>
      <span className="mx-8">Compassionate and patient-centered care.</span>
      <span className="mx-8">Strong organizational and time-management skills.</span>
      <span className="mx-8">Ability to work collaboratively with healthcare teams.</span>
      <span className="mx-8">Adaptability and resilience in challenging environments.</span>

      <span className="font-bold text-xl">Remuneration</span>
      <span className="mx-8">Competitive salary of University Scale.</span> `
    },   
 Howtoapply: [
      "Interested candidates should apply not later than by <strong>26/07/2024</strong>",
      "Please note that only shortlisted candidate will be communicated for a competence-based interview.",
    ],
    isActive: false,
    expiredDate: "2024, 07, 26",
  },
  {
    id: 6,
    icon: icon2,
    title: "Admin and IT Technician",
    careerid: "Admin-and-IT-Technician",
    location: "University Campus in Town, Mogadishu- Somalia",
    jobType: "Full time",
    role: "IT Technician",
    title1: {
      title: "Overview",
      desc: "The Center for Languages at Benadir University is seeking a skilled and motivated Admin and IT Technician to join our team. The successful candidate will be responsible for performing a variety of administrative duties including student registration, document writing, and filing, as well as managing the bookstore. Additionally, the role includes IT responsibilities such as assisting students, teachers, and management with the setup of login details for our digital learning platform and troubleshooting any IT-related issues.",
    },
    title2: {
      title: "Key Responsibilities:",
      desc: "Duties:",
      lst: [
        "Assist with student registration and maintain student records.",
        "Perform documentation and filing tasks and help with scheduling.",
        "Support students, the management and teachers in administrative tasks",
        "Manage the CFL bookstore, including inventory control, book sales, and maintaining accurate records.",
        "Assist students, teachers, and management with setting up login details for the digital learning platform.",
        "Troubleshoot and resolve IT-related issues for the CFL community.",
        "Support digital literacy across the center.",
        "Maintain up-to-date knowledge of software and hardware relevant to the center's operations.",
        "manage center’s social media platforms for posting updates of the center."
      ],
    },
    title3: {
      title: "KEY QUALIFICATIONS AND SKILLS",
      desc: "Minimum Requirements",
      lst: [
        "University degree in IT, Computer Science, Software Engineering, or a related field in management.",
        "Strong background in working with numbers and experience in an administrative role.",
        "Proficiency in digital literacy.",
        "Bilingual fluency in English and Somali.",
        "Excellent interpersonal skills and a passion for helping others.",
        "Demonstrated leadership qualities and a team-oriented approach."
      ],
    },

    Howtoapply: [
      "Interested applicants, please send CV, academic documents and cover letter to cfl.coordinator@bu.edu.so    and cc to this hrm@bu.edu.so",
      "Please note that only shortlisted candidate will be communicated for a competence-based interview.",
    ],
    isActive: false,
    expiredDate: "2024, 03, 15",
  },
  {
    id: 5,
    icon: icon2,
    title: "TEACHING OPPORTUNITY",
    careerid: "Lecturer-marine-science-1",
    location: "University Campus, Mogadishu- Somalia",
    jobType: "Part time",
    role: "Lecturer",
    title1: {
      title: "JOB PURPOSE",
      desc: `The purpose is to teach marine science courses at undergraduate level. This involves designing course materials, delivering lectures, leading discussions, and conducting practical sessions.`,
    },
    title2: {
      title: "JOB DESCRIPTION: KEY RESPONSIBILITY",
      desc: "Following are the key responsibilities;",
      lst: [  
      "To be able to teach the following courses: - Introduction to Oceanography and - Practical skills of boat & skipper Safety.",
      "To provide opportunities for students to gain practical experience and apply theoretical knowledge through laboratory exercises, field trips, and hands-on projects. This may involve organizing field trips to coastal areas or collaborating with research institutions for access to marine research facilities.",
      "To provide students with a comprehensive understanding of the fundamental principles and concepts of oceanography. This includes teaching topics such as ocean circulation, marine ecosystems, coastal processes, ocean chemistry, and geological aspects of the ocean.",
      "Offering academic support and guidance to students through office hours, individual consultations, and feedback on assignments and assessments. Providing mentorship and advising to students interested in pursuing further studies or careers in oceanography.",
      "to provide practical instruction on boat handling and skipper safety techniques to ensure the safe operation of vessels in marine environments. This involves teaching students how to navigate, maneuver, and operate boats safely, as well as how to respond effectively to emergency situations such as capsizing, grounding, or medical emergencies.",
      "To educate students about relevant laws, regulations, and best practices governing boating safety, including local navigation rules, international maritime conventions, and industry standards. Ensuring that students understand their legal responsibilities as boat operators and are equipped to comply with applicable regulations to prevent accidents and avoid fines or penalties.",
      "To provide guidance and support to students interested in pursuing careers in the maritime industry, including opportunities for further training, certification, and licensure as boat operators, captains, or maritime safety professionals. Mentoring students in developing the skills, qualifications, and experience necessary to succeed in their chosen field and contribute to the safety and sustainability of maritime operations"
      ]
    },

    title3: {
      title: "KEY QUALIFICATIONS AND SKILLS",
      desc: "Minimum Requirements",
      lst: [
        "Master degree in marine science or equivalent degree.",
        "A minimum of two years of experience.",
        "Excellent organization, communication and interpersonal skills."
      ],
    },
   
    Howtoapply: [
      "Interested applicants, please send your CV and cover letter to hrm@bu.edu.so, until 17Th Febaury 2024",
      "A cover latter in English, A detailed CV including a track record of previous experience and professional capacity, All Academic documents and other supporting documents including bachelor and master transcripts.",
      "Interviews for qualified candidates will be conducted in person.",
    ],
    isActive: false,
    expiredDate: "2024, 02, 17",
  },
  {
    id: 4,
    icon: icon2,
    title: "TEACHING OPPORTUNITY",
    careerid: "TEACHING-OPPORTUNITY",
    location: "University Campus, Mogadishu- Somalia",
    jobType: "Full time",
    role: "English Language Instructor",
    title1: {
      title: "JOB PURPOSE",
      desc: `Center for Languages at BU is seeking dedicated instructors to lead our English language program, developed and supported by the international educational consultancy Ednastics.
      Join us at Benadir University and make a meaningful impact on the education and future of our students.`,
    },

    title2: {
      title: "KEY QUALIFICATIONS AND SKILLS",
      desc: "Minimum Requirements",
      lst: [
        "Bachelor's Degree in English, TESOL, or a related field.",
        "Advanced Certification or Graduate Diploma in TESOL (CELTA/DELTA/Master’s preferred)",
        "Prior teaching experience, with a strong grasp of technology.",
        "Exceptional interpersonal skills, a positive attitude, empathy, and a sense of humor.",
        "Cultural and religious sensitivity, ideally with experience in a similar environment or a good",
        "understanding of the cultural norms relevant to the job environment.",
      ],
    },
    title3: {
      title: "Language Proficiency Requirement & Working Conditions",
      desc: "",
      lst: [
        "Non-native speakers who have not studied in English and not lived in an English-speaking environment must provide a valid demonstration of language proficiency.",
        "High-level fluency in English, as evidenced by official English proficiency tests such as Cambridge Linguaskill (minimum 186), Duolingo 130 or IELTS (minimum 7.0).",
        "Full-time position: 40 hours per week (24 instructional hours and 16 office hours for meetings, professional development, student support, grading, and preparation).",
      ],
    },

    Howtoapply: [
      "Interested applicants, please send your CV and cover letter to:",
      "hrm@bu.edu.so and CC the Coordinator of the Center at cfl.coordinator@bu.edu.so, until 28th November 2023",
      "Interviews for qualified candidates will be conducted in person or via video conferencing on Zoom.",
    ],
    isActive: false,
    expiredDate: "2023, 11, 28",
  },
  {
    id: 3,
    icon: icon2,
    title: "Faculty of Medicine Coordinator",
    careerid: "faculty-of-medicine-coordinator",
    location: "University Campus in Town, Mogadishu- Somalia",
    jobType: "Full time",
    role: "Adminstration level",
    title1: {
      title: "JOB PURPOSE",
      desc: "The Main purpose of the coordinator is to coordinate, and direct responsibility for, specific set of tasks related to a function or program or department, Organizes and implements administrative systems and procedures performing necessary support duties",
    },
    title2: {
      title: "JOB DESCRIPTION: KEY RESPONSIBILITY",
      desc: "Following  are the  key responsibility;",
      lst: [
        "Works closely with the Dean of Faculty of Medicine and Surgery",
        "Assists the Dean of Faculty with scheduling, planning, advertising, and implementing faculty strategies in line with the University.",
        "Assists in the planning, organization and execution of faculty appointments and formal reviews.",
        "Maintains faculty files and other records (e.g., spreadsheets, databases) in support of the faculty dean.",
        "Reviews central database to ensure that all records pertaining to faculty activities are up-to-date, such as exams, re-exams of the students and reports directly to the dean of the faculty",
        "Maintains records on faculty participation in faculty development and assists in evaluation of faculty development programs.",
        "Oversees closely with hospitals to assure that all internship students are performing well.",
      ],
    },
    title3: {
      title: "KEY QUALIFICATIONS AND SKILLS",
      desc: "Minimum Requirements",
      lst: [
        "Master’s degree in public administration",
        "A minimum of 3 years’ experience in administrative with the relevant position.",
        "Computer literacy, particularly word, excel and power point; knowledge other data management will be added advantage.",
        "Excellent planning, and report skills, with the ability to organize a substantial workload comprised of complex diverse tasks and responsibility.",
        "Excellent organization, communication and interpersonal skills.",
        "Commitment and time management.",
        "Age minimum 30 years.",
        "Language English and Arabic ",
      ],
    },

    Howtoapply: [
      "Interested Applicants who met above criteria must send following document to; hrm@bu.edu.so , until 27th October 2023 indicating “BU, faculty of Medicine Coordinator” followed by your name in the subject line.",
      "A cover latter in English, A detailed CV including a track record of previous experience and professional capacity, All Academic documents and other supporting documents including batcher and transcript, master and transcript.",
      "The evaluation of the applicants will be based on the above criteria indicated in section 4 (Key Qualifications and skills).",
      "Please note that only shortlisted candidate will be communicated for a competence-based interview.",
    ],
    isActive: false,
    expiredDate: "2023, 10, 27",
  },
  {
    id: 2,
    icon: icon2,
    title: "Postgraduate Programs Coordinator",
    careerid: "Postgraduate-Programs-Coordinator",
    location: "University Campus in Town, Mogadishu- Somalia",
    jobType: "Full time",
    role: "Adminstration level",
    title1: {
      title: "JOB PURPOSE",
      desc: "The Main purpose of the Postgraduate program coordinator at the school postgraduate programs is to provide leadership and overall coordination of postgraduate studies at Benadir University. This includes the development and implementation of postgraduate programs, the recruitment and admission of postgraduate students, and the provision of academic and administrative support to postgraduate students and staff. The coordinator is expected to close work with head of department at postgraduate and deans at undergraduate in the promotion of academic performance and introduction of new courses.",
    },
    title2: {
      title: "JOB DESCRIPTION: KEY RESPONSIBILITY",
      desc: "Following  are the  key responsibility;",
      lst: [
        "To Develop and implement postgraduate programs in accordance with the university's strategic plan.",
        "To Recruit , admit postgraduate students and come up strategies to attract more students.",
        "To Provide academic and administrative support to postgraduate students and staff.",
        "To Monitor and evaluate the performance of postgraduate programs.",
        "To Represent the university at conferences and other events related to postgraduate studies.",
        "To Maintain and update the university's postgraduate database.",
        "To Collaborate with the Provost and the other academic deans in strategic planning for the Academic Affairs division in support of the university’s mission. ",
        "To Set  strategic planning priorities for the Graduate School in concordance with Academic Affairs’ planning and the university’s mission in close collaboration with dean of the school and academic department.",
        "To revise and update the course curriculum of P.G. programmes  and ensure they are in line with international standards.",
        "To monitor post graduate teaching and research and to enforce students’ discipline at post graduate level.",
        "To Assist departments/ school programs to develop proposals for new graduate programs for consideration by appropriate Graduate school  committees and other university administrations.",
        "To develop memorandum of understanding with different institutes within Somalia  and abroad for collaboration in post-graduate research and education.",
        "To conduct the activities such as P.G students’ admission, registration in courses and other activities, approval of programme of work and plan of research for thesis, appointment of examiners, evaluation of thesis, preparation of report card, providing degrees, selections for gold medals etc.,  and maintenance of records of PG student",
        "To work harness institutional potential and seize the opportunity to grow the size and the number of existing graduates’ programs.",
        "To Ensure faculty, department/program, and student compliance with all academic policies set by the university.",
        "To Allocate funding for graduate student support, including Teaching and Graduate Assistant funding, to the Colleges/Schools (academic deans) as appropriate and Reviews annual productivity reports (credit-hour production, degrees awarded etc.).",
        "To prepare  annual Graduate School performance on institutional surveys and assessments.",
        "To Provide leadership, supervision, and direction for the Graduate School, including staff: Hires and trains employees; plans, assigns, and reviews work; establishes goals and assesses performance through completion of written evaluations; acts on disciplinary issues, up to and including dismissal; addresses grievances and develops a plan for corrective action. Actively promotes staff development.",
        "To Evaluate the effectiveness of existing and proposed systems and processes in support of graduate programs (enrollment, applications, curricular development) at university.",
        "Identify institutional partnership opportunities at the national local level (from bilateral, multilateral, international organizations, similar academic institutions  and embassies and foundations).",
        "Mobilize resources both locally and internationally and ensure the efficient use of the resources.",
        "Prepare a calendar of events for the school  and ensures they occur as per the plan.",
        "Any other duties assigned by the immediate supervisor. ",
      ],
    },
    title3: {
      title: "KEY QUALIFICATIONS AND SKILLS",
      desc: "Minimum Requirements",
      lst: [
        "Master Degree or (PHD) in a relevant field with three (3) to five(5)years experience.",
        "Demonstrated successful experience in academic leadership at department, the college and/or university level; evidence of successful teaching in higher education; evidence of scholarly activity and professional achievement in an academic discipline commensurate.",
        "Knowledge Of Budgeting; strategic planning; academic programs; personal computer applications.",
        "Knowledge of  University structure and academic programs  and  Enrollment management best practices .",
        "Have skills written, verbal and interpersonal communication with the ability to communicate effectively with all components of the university and external constituents.English language, Arabic Language and Turkish Language are compulsory to know.",
        "Have a Supervision skills to provide support and guidance for staff and able to Implement projects and assessment of program initiatives.",
        "Ability to  Provide leadership and to direct others  and  outlines clear goals and conceptual framework for programs.",
        "Ability to  Promote a collegial and constructive environment based on open communication, positive team building, and effective management of student relationships.",
        "Have Problem-solving , Leadership , Time management, Decision-making  and Prioritization and delegation skills.",
        "A strategic thinker, who values consultation and collegiality as well  as honest and open individual with a high level of personal integrity.",
        "Strong skills in Academic writing  and Grant writing /Proposal for funding",
      ],
    },

    Howtoapply: [
      "Interested Applicants who met above criteria must  send following document to; hrm@bu.edu.so, until 27th Sep 2023 indicating “postgraduate Programs Coordinator” followed by your name in the subject line.",
      "A cover latter in English, A detailed CV including  a track record of previous experience and professional capacity, All Academic documents and other supporting documents.",
      "The evaluation of the applicants will be based on the above criteria indicated in section 3 (Key Qualifications and skills).",
      "Please note that only shortlisted candidate will be communicated for a competence-based interview.",
    ],
    isActive: false,
    expiredDate: "2023, 09, 27",
  },

  {
    id: 1,
    icon: icon2,
    title: "Dean of the Faculty of Veterinary Science",
    careerid: "Dean-of-the-Faculty-of-Veterinary-Science",
    location: "University Campus in Town, Mogadishu- Somalia",
    jobType: "Full time",
    role: "Adminstration level",
    title1: {
      title: "Job description",
      desc: "Benadir university is looking for a qualified dean of the faculty of veterinary science",
    },
    title2: {
      title: "KEY QUALIFICATIONS AND SKILLS",
      desc: "Minimum Requirements",
      lst: [
        "Academic and teaching experience at university level at least 3 - 5 years.",
        "Active research program with peer-reviewed publications.",
        "Ability to work independently and as a part of a team.",
        "Strong organizational, time management, and communication skills.",
        "Experience in curriculum developement and assessment.",
        "Ability to secure funding for research and programs.",
        "Commitment to diverse and inclusion.",
      ],
    },

    Howtoapply: [
      "To apply, please submit your resume, a cover letter, and coppy of your certificate to hrm@bu.edu.so.",
      "The deadline for applications is September 20,2023",
    ],
    isActive: false,
    expiredDate: "2023, 09, 20",
  },
];
