import React from "react";

const pro1 =
  "https://bu-page-assets.s3.ap-northeast-1.amazonaws.com/static-assets/staff/cs/bue0049.jpg";
const pro2 =
  "https://bu-page-assets.s3.ap-northeast-1.amazonaws.com/static-assets/others/Assad Abdi Olad.jpeg";
const pro3 =
  "https://bu-page-assets.s3.ap-northeast-1.amazonaws.com/static-assets/others/Feysal Ahmed Abdi.jpeg";
const pro4 =
  "https://bu-page-assets.s3.ap-northeast-1.amazonaws.com/static-assets/others/Naima Mohamed Mohamud.jpg";

const AdmissionContacts = () => {
  const contacts = {
    title: "Benadir University",
    address: "Wadada Jaamacadda Benadir,",
    street: "Behind saybiayno Hodan",
    city: "Mogadishu - Somalia",
    tell: "Admision Help Line: +2018",
    email: "Email: admission@bu.edu.so",
  };

  const contactPerson = [
    {
      id: 1,
      profile: pro1,
      name: "Eng. Abdulkadir Hassan Ahmed",
      title: "General Registrar",
      phone: "+252 615524055 (whatsapp)",
      email: "registrar@bu.edu.so",
    },
    {
      id: 2,
      profile: pro2,
      name: "Eng. Assad Abdi Olad",
      title: "Admission Officer",
      phone: "+252 61 5560709 (whatsapp)",
      email: "admission@bu.edu.so",
    },
    {
      id: 3,
      profile: pro3,
      name: "Mr. Feysal Ahmed Abdi",
      title: "Admission Secretary",
      phone: "+252 61 5328225 (whatsapp)",
      email: "admission@bu.edu.so",
    },
    {
      id: 4,
      profile: pro4,
      name: "Mss. Naima Mohamed Mohamud",
      title: "Admission Secretary",
      phone: "+252 61 ------- ",
      email: "admission@bu.edu.so",
    },
  ];

  return (
    <div className="text-[#0863AA] mb-20">
      <div className="my-10 flex flex-col justify-center items-center ">
        <p className="text-3xl xs:text-xl  py-3 text-[#0863AA] font-semibold">
          Contact to admission office
        </p>
        <div className="text-[#0863AA] border-2 solid border-[#0863AA] rounded-xl py-10 px-10 flex flex-col justify-center items-center space-y-2 mt-5">
          <p className="py-2 text-3xl xs:text-xl">{contacts.title}</p>
          <p className="py-2 text-2xl xs:text-xl">{contacts.address}</p>
          <p className="py-2 text-2xl xs:text-xl">{contacts.street}</p>
          <p className="py-2 text-2xl xs:text-xl">{contacts.city}</p>
          <p className="py-2 text-2xl xs:text-xl">{contacts.tell}</p>
          <p className="py-2 text-2xl xs:text-xl ">{contacts.email}</p>
        </div>
      </div>
      {/* card */}
      <div className=" grid xl:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 xs:grid-cols-1 gap-20 justify-center place-items-center  items-center font-semibold">
        {contactPerson.map((c) => (
          <div
            key={c.id}
            className="w-[350px] xs:w-[250px] sm:w-[300px] flex flex-col justify-center items-center text-xl space-y-2 sm:text-base xs:text-sm"
          >
            <img
              src={c.profile}
              alt="avatar"
              className="h-[300px] xs:h-[200px]"
            />
            <p className="font-semibold">{c.name}</p>
            <p className="font-normal">{c.title}</p>
            <p className="font-normal">{c.email}</p>
            <p className="font-normal">{c.phone}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AdmissionContacts;
