import React from "react";
import { MdArrowForwardIos } from "react-icons/md";
import _ from "lodash";

const backet =
  "https://bu-page-assets.s3.ap-northeast-1.amazonaws.com/static-assets";

const file2023 = backet + "/convacation-files/Graduation-Book-2022-2023.pdf";
const file2022 = backet + "/convacation-files/Graduation-Book-2021-2022.pdf";
const file2021 = backet + "/convacation-files/Graduation-Book-2020-2021.pdf";

const cover = backet + "/others/cover.jpeg";

const Convacation = (props) => {
  const lists = [
    { id: 1, conv: "Class of 2021", file: file2021 },
    { id: 2, conv: "Class of 2022", file: file2022 },
    { id: 3, conv: "Class of 2023", file: file2023 },
  ];

  const sortedLists = _.orderBy(lists, ["id"], ["desc"]);

  return (
    // panel
    <div className="">
      <div className="relative w-full xl:h-[558px] md:h-[558px]  group">
        <img
          src={cover}
          className="w-full object-cover xl:h-[558px] md:h-[558px]"
          alt="cover"
        />
        <div className="absolute top-[65%] md:top-[80%] xl:top-[80%] sm:top-[80%] xs:top-[80%]  w-full h-[15%] bg-white opacity-70 flex justify-center items-center"></div>
        <p className=" md:text-6xl xl:text-7xl xs:text-3xl sm:text-5xl font-bold text-[#0843aafe] text-center  md:top-[83%] xl:top-[82%] sm:top-[82%] xs:top-[83%]  absolute w-full uppercase">
          {" "}
          Convocation
        </p>
      </div>

      <div className=" flex flex-col justify-center items-center my-10  mb-16 ">
        {sortedLists.map((l) => (
          <div className="flex flex-row py-2 space-x-2">
            <MdArrowForwardIos />
            <a
              href={l.file}
              download="Certificate-Request-Form"
              className=" text-gray-800 font-bold inline-flex items-center"
            >
              <span>{l.conv}</span>
              <svg
                className="fill-current w-4 h-4 ml-2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
              </svg>
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Convacation;
