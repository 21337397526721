import React from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";
import { fees } from "../../../data/fees";
import { programs } from "../../../data/programs";

const Fees = () => {
  // const contacts = programs[0].lists[0].contacts;
  const { slug } = useParams();
 

  const program = _.find(programs[0].lists, ['slug', slug])


  const myfees = _.find(fees, function (p) {
    return p.id.toString() == program.id;
  });

  return (
    <div className="flex justify-center items-center md:mx-[300px] xl:mx-[300px] mx-3">
      <table className="table-auto border-collapse border border-[#e8d5d6] my-10 text-base xs:text-sm">
        <thead className="bg-[#0863aa] uppercase text-white">
          <tr className="py-3">
            <th className="border border-[#e8d5d6] w-[500px] sm:w-[300px] xs:w-[300px] py-4 uppercase">
              Payments
            </th>
            <th className="border border-[#e8d5d6] w-[500px] sm:w-[300px] xs:w-[300px] py-4 uppercase">
              Charges
            </th>
          </tr>
        </thead>
        <tbody>
          {myfees.fees.map((f) => (
            <tr key={f.id} className="text-black">
              <td className="border border-[#e8d5d6] w-[500px] sm:w-[300px] xs:w-[300px] py-2 text-center">
                {f.title}
              </td>
              <td className="border border-[#e8d5d6] w-[500px] sm:w-[300px] xs:w-[300px] py-2  text-center">
                {f.desc}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Fees;
