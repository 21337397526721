import React from "react";
import Schools from "./schools"
import AdmissionMenu from "./admissionMenu"
import {programs} from '../../../data/programs'

const sample = "https://bu-page-assets.s3.ap-northeast-1.amazonaws.com/static-assets/admission/sawir.PNG";


const AdmissionNotice = props => {
  
    const notice = {
        title: "Admisson notice", img: sample, caption: "Admisson Test Date"
    }
  return (
        <div  className="flex flex-col">
           
            <div className="flex flex-row justify-center" >
                <AdmissionMenu />
            </div>
            <div className="flex flex-row justify-center">
            <Schools list={programs[0].lists}/>
            </div>
        </div>

  );
};

export default AdmissionNotice;