const backet =
  "https://bu-page-assets.s3.ap-northeast-1.amazonaws.com/static-assets/staff/";
// const pro = backet + "/profesional.jpeg";

export const mdStaff = [
  {
    id: "BUE0023",
    fullName: "Dr. Tahliil Abdi Afrah",
    phone: "+2526xxxxxxxx",
    email: "work-email@bu.edu.so",
    title: "Dean, Faculty of Medicine & Surgery",
    desc: [`Biography will goes here`],
    aoi: [],
    pub: [],
    avatar: backet + "md/tahlil.jpg",
  },
];

export const csStaff = [
  {
    id: "BUE0012",
    fullName: "Prof. Khaliif Muse Warsame",
    phone: "+252615991200",
    email: "khalif.warsame@bu.edu.so",
    title: "Dean, Faculty of Computer Science and IT",
    desc: [
      `Profesor Khalif Muse, recieved his BSc of Computer science from Benadir University, His MSc of Computer science form ankara university, turkey. He has been teaching computer science courses over the past 15 years. Curently, he is a dean of the faculty and also provide consultants to the researchers and academisions in the feild.`,
    ],
    aoi: [
      { id: 1, field: "Web technology" },
      { id: 2, field: "Design patterns" },
      { id: 3, field: "Software engineering" },
    ],
    pub: [
     
    ],
    avatar: backet + "cs/khalif.jpg",
  },
  {
    id: "BUE0049",
    fullName: "Dr. Abdulkadir Hassan",
    phone: "+252615524055",
    email: "abdulkadir@bu.edu.so",
    title: "Associate Professor",
    desc: [
      `Dr. Abdulkadir Hassan Ahmed is an Associate Professor of Computer Science at Benadir University, Somalia. He received his Ph.D. in computer engineering from Karadeniz Technical University in Turkey. He has published several papers in top academic journals and conferences.
      Dr. Abdulkadir Hassan is passionate about using his research to solve real-world problems. He is currently working on projects to develop new algorithms for big data processing and machine learning. He is also interested in using artificial intelligence to improve education and healthcare in Somalia.
      In addition to his research, Dr. Abdulkadir is also an active professor and mentor. He enjoys helping students learn and grow, and he is committed to making computer science accessible to everyone. He is a strong advocate for diversity and inclusion in STEM fields, and he is committed to creating a more equitable future for all.`,
    ],
    aoi: [
      { id: 1, field: "Big data" },
      { id: 2, field: "High performance computing" },
      { id: 3, field: "Bioinformatics" },
      { id: 4, field: "Artificial intelligence" },
    ],
    pub: [
      {
        id: 1,
        title:
          "Parallel Delaunay Triangulation for Large Data in Geographic Information System",
        link: "https://www.shin-norinco.com/article/parallel-delaunay-triangulation-for-large-data-in-geographic-information-system",
      },
      {
        id: 2,
        title:
          "High-Performance Computing on Big data in Geographic Information Systems",
        link: "https://www.researchgate.net/publication/344371074_High-Performance_Computing_on_Big_data_in_Geographic_Information_Systems?_tp=eyJjb250ZXh0Ijp7ImZpcnN0UGFnZSI6Il9kaXJlY3QiLCJwYWdlIjoicHJvZmlsZSIsInByZXZpb3VzUGFnZSI6ImhvbWUifX0",
      },
    ],
    avatar: backet + "cs/bue0049.jpg",
  },
  {
    id: "BUE0440",
    fullName: "Eng. Abdulrazak A. Dirie",
    phone: "+252615991378",
    email: "abdulrazak.dirie@bu.edu.so",
    title: "Senior Lecturer",
    desc: [
      `Mr. Abdulrazak A. Dirie, recieved his BSc in computer science from Benadir university, his BSc honours degree and MSc of computer science from University of The witwatersran. He is a pasionate computer science lecturer and a well known developer. He is memeber of somali tech community and founder of Africa Tech community. He teaches core computer science courses from web technology to mobile technology. He currently work as tech consultant at Horn Tech.`,
    ],
    aoi: [
      { id: 1, field: "Big data" },
      { id: 2, field: "High performance computing" },
      { id: 3, field: "Machine learning" },
      { id: 4, field: "Artificial intelligence" },
      { id: 5, field: "blockchain technology and web3.0" },
    ],
    pub: [
      {
        id: 1,
        title: "Vehicle Detection And Tracking in Real Time",
        link: "https://www.researchgate.net/publication/374925657_Vehicle_Detection_And_Tracking_in_Real_Time",
      },
      {
        id: 2,
        title:
          "FCM based Scalable Clustering Algorithm for Handling Big Data using Apache Spark",
        link: "https://www.researchgate.net/publication/374925590_Research_Proposal_FCM_based_Scalable_Clustering_Algorithm_for_Handling_Big_Data_using_Apache_Spark?channel=doi&linkId=653794c05d51a8012b6c4628&showFulltext=true",
      },
    ],
    avatar: backet + "cs/bue0440.jpg",
  },
  {
    id: "BUE0442",
    fullName: "Eng. Hassan Abdi Arale",
    phone: "+252615678538",
    email: "hassan.arale@bu.edu.so",
    title: "Senior Lecturer",
    desc: [
      `Mr. Hassan recieved his BSc in computer science from Benadir university, his MSc of computer science from Asian University. He is a pasionate computer science lecturer. He teaches core computer science courses from web technology to desktop and gaming technology. He curently work as assistant director at the faculty of computer science.`,
    ],
    aoi: [
      { id: 1, field: "ICT" },
      { id: 2, field: "Game developement with C#" },
      { id: 3, field: "Web technology using .Net technology" },
    ],
    pub: [],
    avatar: backet + "cs/profesional.jpeg",
  },
];

export const edStaff = [];
export const agStaff = [];
export const vetStaff = [];
export const ssStaff = [];
export const emStaff = [
  {
    id: "1",
    fullName: "Dr. Ibrahim Omar Ahmed Bashir",
    phone: "+252618438870",
    email: "ibrahimomar@bu.edu.so ",
    title: "Dean, Faculty of Economics and Management",
    desc: [
      `Dr. Ibrahim is currently the faculty dean and associate professor of Economics and Management at Benadir University in Somalia. He was born in Wanlawayne-Lower Shabelle in 1982 and obtained his Ph.D. in Business Administration, specializing in Human Resource Management, in 2015 from the International University of Africa (IUA) in Khartoum, Sudan. He also holds a Master's degree in Business Administration with a focus on Islamic Banking and Finance (2009) and a Bachelor's degree in Economics, in (2007) both from the IUA. Since 2011, Dr. Ibrahim has been lecturing at various universities in Mogadishu and has gained extensive experience working with local NGOs. In addition to his academic career, he has established institutions and served in positions such as General Director and Admin and Finance Officer. Since 2020, he has been fulfilling the role of the dean of the faculty of Economics and Management.`,
    ],
    aoi: [
      { id: 1, field: "Economics and Islamic Economics" },
      { id: 2, field: "Islamic Banking and Finance" },
      { id: 3, field: "leadership and Human Resource Management" },
    ],
    pub: [],
    avatar: backet + "em/Ibrahim.jpg",
  },
  {
    id: "2",
    fullName: "BARASA BERNARD MACHO",
    phone: "+256775367651",
    email: "machobernard@bu.edu.so",
    title: "Senior Lecturer",
    desc: [
      `Barasa Bernard Macho is Ugandan Economist and Applied Statistics lecturer born on the 27th of October 1985 from Busia district in Uganda. Mr. Macho holds A Master of Art in Financial services and a Bsc. Quantitative Economics from Makerere University. He is currently working with the faculty of Economics and Management of Benadir University in Somalia since August 2023. Macho has a passion in Social-Economic Research; attained regular working experience in Strong Quantitative Analysis as a work order consultant with United Nations Children Fund (UNICEF) in data management and analysis. He has worked with private and national Organizations on project completion reports, designing survey implementation plans, conducting trainings for field surveys, Preparing budgets, designing data monitoring, collection and capture instruments, report writing and dissemination of the findings to the stakeholders. Mr. Macho As a coordinator at the Uganda Statistical Association participated in organizing professional trainings for Statisticians across the country, liaised and maintained good relations with the representative offices of major donors, stakeholders and members of the Association, gave oversight and direction on resource mobilization, and successfully organized a pre-conference International training for Young Statisticians during the 5th International Conference on Agricultural Statistics in 2010 He previously served as the managing director of Guild Frank Foreign Exchange Bureau and participated in documenting various operational policies for the institution as well as a lecturer at Team University in Uganda.`,
    ],
    aoi: [
      { id: 1, field: "Research Methodology" },
      { id: 2, field: "Quantitative Methods" },
      { id: 3, field: "Demography" },
      { id: 4, field: "International Economics and Theory of Statistics" },
    ],
    pub: [],
    avatar: backet + "em/profesional.jpeg",
  },
  {
    id: "3",
    fullName: "MAYIGA DRAKE",
    phone: "+256 705638412",
    email: "mayigad@bu.edu.so",
    title: "Senior Lecturer",
    desc: [
      `Mayiga Drake is a Ugandan Accounting and Finance lecturer born on 15th, August 1988 from Masaka district in Uganda. He holds A Masters of Business Administration and Bsc. in Accounting and Finance both from Kyambogo University, and is currently working as a lecturer at Benadir University – Somalia under Faculty of Economics and Management since August 2023. Mr. Mayiga is so passionate about Financial Analysis, practical Accounting and Finance concepts in modern business world. He has gained perpetual working experience as an Accountant ,Financial Analyst, and Financial literacy trainer in both private and national organizations by over seeing, managing financial plans and budget preparation, Identifying and managing financial risks, making investment decisions, communicating the company's financial performance and reporting to the stakeholders. He previously served as project accountant with the East African Youth Inclusion Program –(EAYIP) under Heifer International, Finance Officer at Centmax Limited - Uganda as well as a Lecturer at Kyambogo University in Uganda, and Kyadondo Business and Technical Institute -(KBTI) Uganda with 7 years of lecturing experience. I teach and support students to learn, grow and believes at bringing the best from the learners and committed to helping them succeed in their career and ensuring that accounting is applied in the real world to enhance their critical thinking and creativity.`,
    ],
    aoi: [
      { id: 1, field: "Cost Accounting" },
      { id: 2, field: "Financial Management" },
      { id: 3, field: "Financial Statement Analysis" },
      { id: 4, field: "Procurement Ethics" },
    ],
    pub: [],
    avatar: backet + "em/profesional.jpeg",
  },

  {
    id: "4",
    fullName: "MOHAMUD JAMA HASHI",
    phone: "+252615367208",
    email: "mjaamach@bu.edu.so",
    title: "Senior Lecturer",
    desc: [
      `MOHAMUD JAMA HASHI is a researcher and part time lecturer at Benadir University in Mogadishu Somalia. He has a masters degree of public administration and bachelor degree of public administration from Islamic university in Uganda and Mogadishu University respectively.`,
    ],
    aoi: [
      { id: 1, field: "community participation" },
      { id: 2, field: "health service delivery" },
      { id: 3, field: "decentralization" },
      { id: 4, field: "social accountability" },
      {
        id: 5,
        field:
          "environmental resources management and sustainable development in Somalia",
      },
    ],
    pub: [],
    avatar: backet + "em/profesional.jpeg",
  },
  {
    id: "5",
    fullName: "MANGENI DAVID",
    phone: "+256772867646",
    email: "mangenidavid51@bu.edu.so ",
    title: "Senior Lecturer",
    desc: [
      `My Names: MANGENI DAVID Date of Birth: 17th-Oct-1971 Place of Birth: BUSIA DISTRICT, DABANI SUB-COUNTY, BUYENGO PARISH, MALOMBA VILLAGE: UGANDA I have over 25 years of working experience with the private sector in Uganda. I have held several positions in different organizations both at middle and top -level management positions. The following is a brief description of my working history. A) WORKING EXPERIENCE: 1. BANKING OFFICER: This was with the Cooperative Bank. This was between 1998-1999' 2. ASSISTANT ACCOUNTANT: Kampala Pharmaceuticals Industries between 1999-2004. 3. FINANCE AND ADMINISTRATION MANAGER: Busia Millers Ltd between 2005-2008. 4. MANAGING DIRECTOR: Busia Millers Ltd between 2008-2015 5. CONSULTANT: Writing Business plans for entrepreneurs, Tax administration training and human resource training programs for institutions 6. LECTURER: Team University Kampala between 2021-2023: Faculty of Business and Management sciences: subjects: Business Law, Principles of Management, HRM and Research Methodology. 7. LECTURER: Benadir University: subjects: HRM, Research Methodology and Strategic Management. B) EDUCATION BACKGROUND: 1.Uganda Management Institute: between 2012-2014: Master's in business administration. 2.Makerere University Kampala: between 1993-1996: Bachelor's of Business Administration 3. Caltec Academy Makerere : between 1991-1993: Uganda Advanced Certificate of Education 4. Bukedi College Kachonga: between 1987-1990: Uganda Certificate of Education. 5. Buyengo Primary School between 1978-1986: Primary Leaving Certificate. C) PROFESSIONAL QUALIFICATION: Institute of Chartered Public Accountants of Uganda: CPA.`,
    ],
    aoi: [
      { id: 1, field: "Business Law" },
      { id: 2, field: "Principles of Management" },
      { id: 3, field: "HRM" },
      { id: 4, field: "Strategic management" },
    ],
    pub: [],
    avatar: backet + "em/profesional.jpeg",
  },
];
export const msStaff = [];
export const slStaff = [];
export const geoStaff = [
  {
    id: "BUE0200",
    fullName: "Eng. Mohamed Abukar Haji Goobdoon",
    phone: "+252615991200",
    email: "work-email@bu.edu.so",
    title: "Dean, Faculty of Geoscience & Envoirement",
    desc: [`Biography will goes here`],
    aoi: [],
    pub: [],
    avatar: backet + "gs/gobdoon.jpg",
  },
];
export const hsStaff = [];
export const engStaff = [];
export const denStaff = [];
