export  const menus = [
{id: 9, text: "About us", link: "about/welcome"},
{id: 1, text: "Admission", link: "admision"},
{id: 2, text: "Students", link: "/students.bu.edu.so"},
{id: 3, text: "Academics", link: "academics"},
{id: 4, text: "Research", link: "research/publications"},
{id: 5, text: "Innovation", link: "/ihub.bu.edu.so"},
{id: 6, text: "Accreditations and partnerships", link: "accreditations"},
{id: 7, text: "Advisors", link: "advisors"},
{id: 8, text: "Staff", link: "staff"},
{id: 10, text: "Erasmus+", link: "erasmus"},
]