import React, { useState } from 'react'
import Sidebar from './sidebar/Side';
import Header from "./header/Header";
import Layout from './Layout/Layout';
import Main from './main/Main';
import News from './main/news/News';
import { Route, Routes } from 'react-router-dom';

const Dashborad = ({ children }) => {
  return (
    <div>
        <Header/>
        <div className='flex flex-row '>
          <div className='sm:hidden xs:hidden'>
            <Sidebar />
          </div>
        <Layout>
          {children}
        </Layout>
        </div>
    </div>
  )
}

export default Dashborad