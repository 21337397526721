import React, { useState } from 'react';
import Pagination from './Pagination';
import Items from "./items"
import _ from "lodash"

const Paginate = ({items, slug}) => {

  const itemsPerPage = 6;

  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(items.length / itemsPerPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const currentItems = items.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );
  

  return (
    <div className="xl:container mx-auto p-4 ">
     
      <Items currentActivity={currentItems} />
      <Pagination 
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
    </div>
  );
};

export default Paginate;


