import React from "react";
import parse from 'html-react-parser';
import {  useParams } from "react-router-dom";
import visionImg from "../../../assets/images/vision.png"
import misionImg from "../../../assets/images/mision.png"
import valueImg from "../../../assets/images/value.png"
import {foundation} from '../../../data/programs'



const backet = 'https://bu-page-assets.s3.ap-northeast-1.amazonaws.com/static-assets';

const undergrad = backet + '/undergrad/undergrad.jpg'
const visionIcon = backet + '/about/vission.jpg'
const misionIcon = backet + '/about/Mission.jpg'
const valuesIcon = backet + '/about/badge.png'


const Background = props => {

  const {slug} = useParams()



  // const bg = programs[0].lists[0].bg;
   
  let bg,contacts, p ;
      bg = foundation.bg
      contacts = foundation.contacts
      p = foundation.program

  return (
         <div  className="text-black text-justify mt-10 xl:mx-[300px] md:mx-[100px] ">
            <p className='text-3xl py-5 font-semibold uppercase '>About</p>
          <div className="mx-10">
            {bg && 
              <p  className="py-3 text-xl whitespace-pre-line">
                {parse(bg.bg)}
              </p>}
            </div>

            <div className="flex flex-col justify-center items-start  my-10 xs:space-y-3 sm:space-y-3 ">
                
               
                <div className="flex flex-row sm:flex-col xs:flex-col justify-center items-center space-x-5 bg-[#EFEDED] w-full">
                  <img src={visionImg} alt="not found" className="md:w-[350px] md:h-[300px] xl:w-[350px] xl:h-[300px] object-cover" />
                  <div className="py-3 px-5">
                  <p className="py-3 text-xl font-semibold ">OUR VISION</p>
                  {bg && (
                    <p   className=" text-xl  whitespace-pre-line">
                      {parse(bg.vision)}
                    </p>
                  )}
                  </div>

                </div>
                <div className="flex flex-row sm:flex-col xs:flex-col  justify-center items-center space-x-5 bg-[#FAFAFA] w-full">
                  <div className="py-3 px-5">
                  <p className="py-3 text-xl font-semibold ">OUR MISSION</p>
                  {bg && (
                    <p   className="text-xl whitespace-pre-line">
                      {parse(bg.mission)}
                    </p>
                  )}
                  </div>
                  <img src={misionImg} alt="not found" className=" md:w-[350px] md:h-[300px] xl:w-[350px] xl:h-[300px]" />
                </div>

                <div className="flex flex-row sm:flex-col xs:flex-col justify-start items-center  space-x-5 bg-[#D5D2D2] w-full">
                  <img src={valueImg} alt="not found"  className="md:w-[350px] md:h-[300px] xl:w-[350px] xl:h-[300px]  sm:w-full"/>
                  <div className="py-3 px-5">
                  <p className="py-3 text-2xl font-semibold ">CORE VALUES</p>

                  {bg &&  (
                    <p   className="  text-xl whitespace-pre-line">
                      {parse(bg.values)}
                    </p>
                  )}
                  </div>
                </div>
            </div>

            <p className='text-3xl xs:text-xl sm:text-xl py-5 font-semibold uppercase '>Objectives</p>
            <div className="flex flex-row space-x-10  text-xl mx-10 ">
              {bg && 
              <p className="whitespace-pre-line text-justify my-3">{bg.Objectives.desc}</p>}
              <img src={bg.Objectives.img} alt="" className="h-[300px] sm:hidden xs:hidden rounded-lg" />
            </div>
            
            <div className="">
            <p className='text-3xl xs:text-xl sm:text-xl py-5 font-semibold uppercase '>Faculties</p>
            <div className="flex flex-col mb-10 mx-10 text-xl">
            
               {bg && (
                    <p   className="whitespace-pre-line  ">
                       {parse(bg.Faculties)}
                    </p>)}
            </div>
            </div>
            <div className="">
            <p className='text-3xl xs:text-xl sm:text-xl py-5 font-semibold uppercase '>Contact</p>
            <div className="flex flex-col mb-10 text-xl mx-10">
              <p>{p}</p>
              <p>{contacts.location}</p>
              <p>Office: {contacts.Office}</p>
              <p>Email: {contacts.email}</p>
              <p>Phone: {contacts.phone1}</p>
              {contacts.phone2 &&
              <p>Alt. Phone: {contacts.phone2}</p>
              }
            </div>
            </div>

          
   
      </div>

  );
};

export default Background;