import React from "react";
import { useParams } from "react-router-dom";
import { postgradute } from "../../../data/programs";
import { PostgraduateDeploma } from "../../../data/programs";
import { phd } from "../../../data/programs";
import parse from "html-react-parser"
import _ from "lodash";
import SectionCollapse from "./sections";

const backet =
  "https://bu-page-assets.s3.ap-northeast-1.amazonaws.com/static-assets";

const cover =  backet + '/others/cover.jpeg'

const PostgradDetail = ({ children }) => {
  let program;
  const { tag } = useParams();
  program = _.find(postgradute, ["tag", tag]);
  if(!program){
    program = _.find(PostgraduateDeploma, ["tag", tag]);
  }
  if(!program){
    program = _.find(phd, ["tag", tag]);
  }
  

  return (
    <div className="">
      <div className="relative w-full h-[558px] mx-h-[558px]">
        <img
          src={cover}
          className="w-full object-cover h-[558px] mx-h-[558px]"
          alt="cover"
        />
        <div className="absolute  top-[80%]  w-full h-[15%] bg-white opacity-50 flex justify-center items-center"></div>
        <p className="uppercase   md:text-4xl xl:text-5xl xs:text-3xl sm:text-3xl font-bold text-[#0843aafe] text-center absolute top-[83%] xl:top-[83%] w-full">
          {program && program.program}
        </p>
      </div>

      <div className="flex flex-col justify-center items-center w-full bg-[#d9d9de] text-black xs:px-1">
        <div className="h-full flex flex-col overflow-hidden md:ml-16 xl:ml-16  md:space-x-2 xl:my-3 mb-5 bg-[#d9d9de] md:w-[70%] xl:w-[70%] w-full mx-5">
          {program && program.detail.map((d) => (
            <SectionCollapse key={d.id} title={d.title} icon={d.icon}>
              {parse(d.desc)}
            </SectionCollapse>
          ))}
        </div>
        <div className="flex w-full  md:w-[70%] xl:w-[70%] mx-5 md:ml-16 xl:ml-16  justify-start pb-3 px-3 ">
          <a href="https://shaag.odoo.com/web-registration"
          className="px-5 py-3 bg-green-500 shadow-xl rounded-xl text-2xl xs:text-xl uppercase text-white hover:bg-blue-500" target="_blank">
            apply now
          </a>
        </div>
      </div>
    </div>
  );
};

export default PostgradDetail;
