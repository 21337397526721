// src/PdfViewer.js
import React from 'react';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

const PdfViewer = ({ fileUrl }) => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    renderToolbar: (Toolbar) => (
      <Toolbar>
        {(slots) => {
          const { Download, Print, Save, ...otherSlots } = slots;
          return (
            <div>
              {otherSlots.Open}
              {otherSlots.SwitchTheme}
              {otherSlots.ToggleSidebar}
              {otherSlots.SearchPopover}
              {otherSlots.ZoomPopover}
              {otherSlots.ZoomOut}
              {otherSlots.ZoomIn}
              {otherSlots.RotateClockwise}
              {otherSlots.RotateCounterclockwise}
              {otherSlots.GoToFirstPage}
              {otherSlots.GoToPreviousPage}
              {otherSlots.GoToNextPage}
              {otherSlots.GoToLastPage}
              {otherSlots.CurrentPageInput}
              {otherSlots.PageNumber}
              {otherSlots.PageLayer}
            </div>
          );
        }}
      </Toolbar>
    ),
  });
  
  return (
    <div className="h-screen w-full bg-gray-100 flex justify-center items-center">
      <div className="w-full h-full p-4 bg-white shadow-lg rounded-lg">
        <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}>
          {fileUrl ? (
            <Viewer
              fileUrl={fileUrl}
              plugins={[defaultLayoutPluginInstance]}
            />
          ) : (
            <div className="text-center text-gray-500">No PDF file specified</div>
          )}
        </Worker>
      </div>
    </div>
  );
};

export default PdfViewer;
