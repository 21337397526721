import React,{useState, useEffect} from 'react'
import _ from "lodash"
import PaginatedItems from "./PaginatedItems";
import { getAllStaffs } from "../../../services/employStaff";

function SelectInput(props) {
    const [selectedDepts,setSelectedDepts] = useState("ALL")
    const [staff,setStaff] = useState()

    let filteredStaff;
    if(selectedDepts === undefined || selectedDepts === "ALL"){
      filteredStaff =  staff
    }
    else{
      filteredStaff =  _.filter(staff, ['department', (selectedDepts)])
    }
 
    const handleChange = (e) => {
      setSelectedDepts(e.currentTarget.value)
    }   

    useEffect(() => {
      async function fetchData() {
        const response = await getAllStaffs();
        setStaff(response.data);
      }
      fetchData();
    }, [staff]);


  return (
    <>
    
    <div className='flex flex-row justify-center items-center py-5'>
        <label htmlFor="Filter" className='text-[15px] font-base bg-slate-300 py-2 px-3 rounded-lg rounded-r-none'>Filter</label> 
    <select name="filter" id="filter" value={selectedDepts} onChange={(e) => handleChange(e)} className="py-2 px-4 pe-16  md:w-[50%] xl:w-[40%]  rounded-lg rounded-l-none text-sm xs:text-[12px] focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600" defaultValue="ALL">
    <option  value={"ALL"} >ALL</option>
   {props.depts && props.depts.map( p =>(
           <option key={p.code} value={p.department} >{p.department}</option>
   )
   )
   }
  </select>
  </div>
  <PaginatedItems list={filteredStaff} itemsPerPage="8" />
  
  </>
  )
}

export default SelectInput