
const backet = 'https://bu-page-assets.s3.ap-northeast-1.amazonaws.com/static-assets';


const agri = backet + '/others/agri.jpg'
const future = backet + '/others/future.jpg'
const ir = backet + '/others/ir.jpeg'
const cool = backet + '/others/cool.jpg'


export const posts=[
    {
        id:1,
        title:"Support Karolinska Institutet's research",
        image: {agri},
        text:"The admission notice for the spring courses has now been sent out. To all of you who have been accepted to Karolinska Institutet - big congratulations! In the spring, the occupational therapist program, the physiotherapist program, the medical program and the nursing program, as well as independent courses, will start. Prepare for the start of the semester already now on our welcome pages."
    },
    {
        id:2,
        title:"Support Karolinska Institutet's research",
        image: {ir},
        text:"The admission notice for the spring courses has now been sent out. To all of you who have been accepted to Karolinska Institutet - big congratulations! In the spring, the occupational therapist program, the physiotherapist program, the medical program and the nursing program, as well as independent courses, will start. Prepare for the start of the semester already now on our welcome pages."
    },
    {
        id:3,
        title:"Support Karolinska Institutet's research",
        image: {cool},
        text:"The admission notice for the spring courses has now been sent out. To all of you who have been accepted to Karolinska Institutet - big congratulations! In the spring, the occupational therapist program, the physiotherapist program, the medical program and the nursing program, as well as independent courses, will start. Prepare for the start of the semester already now on our welcome pages."
    },
    {
        id:4,
        title:"Support Karolinska Institutet's research",
        image: {future},
        text:"The admission notice for the spring courses has now been sent out. To all of you who have been accepted to Karolinska Institutet - big congratulations! In the spring, the occupational therapist program, the physiotherapist program, the medical program and the nursing program, as well as independent courses, will start. Prepare for the start of the semester already now on our welcome pages."
    },
]