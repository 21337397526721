import React from 'react'
import Footer from './erusmas_footer'
import Header from './erusmas_header'

const Layout = ({children}) => {
  return (
    <>
        <Header/>
        <div>
            {children}
        </div>
        <Footer/>
    </>
  )
}

export default Layout