import { http } from "./httpServices";
import api from "../config.json";

// const url = api.local + "/depts";
const url = api.global + "/depts";

function deptsUrl(code) {
  return `${url}/${code}`;
}

export function getAllDepts() {
  return http.get(url);
}

export function deleteDepts(code) {
  return http.delete(deptsUrl(code));
}

export async function getDepts(code) {
  return http.get(deptsUrl(code));
}

export async function saveDepts(depts) {
  return http.post(url, depts);
}

export function updateDepts(depts) {
    return http.put(deptsUrl(depts.code), depts);
}
