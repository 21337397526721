import React from "react";
import { Link, useParams } from "react-router-dom";
import api from "../../../config.json";
const url = api.url;

function Items({ currentActivity }) {
  return (

          <div className="grid md:grid-cols-3 xl:grid-cols-3 sm:grid-cols-2 mx-4 md:mx-10 xl:mx-10 my-10 gap-4 lg:gap-y-10 ">
          {currentActivity && currentActivity.map((n) => (
            <div
              key={n._id}
              className="bg-gray-200 rounded-lg shadow-lg hover:border-2 hover:border-[#000] cursor-pointer place-content-end"
            >
              <Link to={`${n._id}`} state={{ activity: n }}>
              <div className="px-6 mt-8 mb-3">
                  <p className="font-bold text-[16px] md:text-[1.5rem] ">
                    {n.title}
                  </p>
                </div>
                <img
                  src={url + n.titleImageUrl}
                  className="rounded-b-lg object-cover md:w-[500px] md:h-[300px] xl:w-[500px] xl:h-[300px]"
                  alt="not found"
                />
                
              </Link>
            </div>
          ))}
        </div>
  );
}

export default Items;
