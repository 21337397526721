import React from 'react'

const backet = 'https://bu-page-assets.s3.ap-northeast-1.amazonaws.com/static-assets';
const cover =  backet + '/postgrad/froms.jpg'


const ResultTable = ({student}) => {
  return (

    // panel
    <div className=''>

        <div className='flex justify-center items-start '>
        <table className="table-auto border-collapse border border-[#e8d5d6] my-10 md:text-base xl:text-base sm:text-base xs:text-sm">
            <thead className='bg-[#0863aa] uppercase text-white'>
              <tr className='py-3'>
                <th className='border border-[#e8d5d6] w-[300px] py-5'>FULL NAME</th>
                {
                  student.Department &&
                  <th className='border border-[#e8d5d6] w-[300px] py-5'>DEPARTMENT</th>
                }
                <th className='border border-[#e8d5d6] w-[300px] py-5'>RESULT</th>
              </tr>
            </thead>
            <tbody >
              <tr className=''>
                <td className='border border-[#e8d5d6] w-[300px] py-2 text-center'>{student.Name}</td>
                {
                  student.Department && 
                  <td className='border border-[#e8d5d6] w-[300px] py-2  text-center'>{student.Department}</td>
                }

                <td className='border border-[#e8d5d6] w-[300px] py-2  text-center'>{student.Exam}</td>
              </tr>
            </tbody>
          </table>
        </div>
      
          
            
        
    </div>
  )
}

export default ResultTable;