import bidey from "../assets/images/profle/leadership/1.jpg"
import abdirahman from "../assets/images/profle/leadership/2.jpg"
import khalif from "../assets/images/profle/leadership/3.jpg"
import jeylani from "../assets/images/profle/leadership/4.jpg"
import nur from "../assets/images/profle/leadership/5.jpg"
import avatar from "../assets/images/profle/leadership/avatar.jpeg"


export const Rectorate =  [
            {id: 1, name: "Prof. Dr. Mohamed Mohamud Hassan Bidey", tiltle: "Rector", photo: bidey, bio: 'biography', desc:``},
            {id: 2, name: "Prof. Dr. Abdurahman Hassan Omar", tiltle: "Deputy Rector, Admin and Finance", photo: abdirahman, bio: 'dbiography',titleDesc: `
                <strong>Deputy Rector of Admin & Finance.</strong>
                <strong>Dean of the Faculty of Sharia & Law</strong>
                <strong> Adviser on Legal Affairs of the Somali Universities Union (Association of 
Somali universities)</strong>
                `, desc:`
                Prof. Dr. Abdurahman Hassan Omar is a renowned scholar and academic leader, currently serving as the Deputy Rector of Benadir University since October 2014. He brings a wealth of knowledge and expertise to his roles, having earned a PhD in International Humanitarian Law from Al-Zaim Al-Azhari University in Khartoum, Sudan, in April 2017. Dr. Omar also holds a Master's degree in International Law from the University of Juba (2005) and a Bachelor's degree in Law from the International University of Africa (2001).

In addition to his role as Deputy Rector, Dr. Omar has been the Dean of the Faculty of Sharia & Law at Benadir University since 2012. His dedication to the legal profession is further evidenced by his registration with the Somali Bar Association (No. 055) in February 2018 and his tenure as Head of the Legal Office of the Benadir Region Administration from 2014 to 2017. He has also served as an Adviser on Legal Affairs to the Somali Universities Union since 2022.

Dr. Omar is deeply committed to education and has been a Senior Lecturer in the Higher Studies Program at both the International University of Africa and Benadir University since 2017. His teaching portfolio also includes being a Professor in Benadir University’s Postgraduate Program since 2021.

With a keen interest in cross-cultural engagement and humanity, Dr. Omar is known for his ability to make sound, practical, and timely judgments. His highly developed intellectual, analytical, and decision-making skills, coupled with superior active listening and interpersonal abilities, make him a respected figure in academia and beyond. Dr. Omar's capacity to work efficiently under pressure, his patience, and his continuous willingness to learn highlight his exceptional dedication to his profession and students.
`},
            {id: 3, name: "Assoc. Prof. Khalif Muse Warsame", tiltle: "Deputy Rector, Academic affairs", photo: khalif, bio: 'dbiography',titleDesc: `<strong>Deputy Rector of Academic Affairs.</strong>
<strong>Dean of Faculty of Computer Science and IT.</strong>
<strong>Advisor of Information and Communication Technology.</strong>
`, desc:`Ass Prof. Khalif Muse Warsame is the Deputy Rector of Academic Affairs at Benadir University. He excels in teaching, research, administration, and leadership, and is known for his outstanding personality. With 18 years of experience at Benadir University, he has gained extensive expertise in his field.

As the Deputy Rector of Academic Affairs, he oversees all learning and teaching-related activities at the university. He is responsible for all aspects of the student experience and reports directly to the Rector. In this role, he plays a decisive and forward-looking part in defining the vision and strategy for the university’s learning and teaching activities. He is also pivotal in introducing new student-centered teaching methods. His key responsibilities include evaluating and improving the quality of learning and teaching.

The academic affairs department, under his leadership, is primarily responsible for planning, implementing, and coordinating the educational programs of the university. 

Additionally, Prof. Khalif Muse Warsame serves as the Dean of the Faculty of Computer Science and Information Technology. Under his administration, the faculty and academic staff have developed a comprehensive plan to reform and advance the faculty, ensuring that it meets its objectives and goals. 

Through the hard work of the faculty's academic staff, the Faculty of Computer Science and Information Technology has become a center of excellence in learning and teaching. Our mission is to develop students with strong technical, problem-solving, and leadership skills`},
            {
                id: 4, 
                name: "Prof. Jeilani Kassim Said", 
                tiltle: "Deputy Rector, Planning and development", 
                photo: jeylani, 
                bio: 'dbiography',
                titleDesc: `<strong>Deputy Rector Planning & Development</strong> at Benadir University, 
                One of the founders of Benadir University			
                <strong>Dean Faculty of Education</strong> at Benadir University
                Former Benadir University Registrar, 
                Has 40+ years of experience in Education Field 
                `,
                desc:`Mr. Jeilani has served in the field of education for a long time and has extensive experience in managing educational systems. He has taught Arabic Language and Geography at both secondary school and university levels. Additionally, he was the head of the examination board at the Imam Al-Shafi’i Foundation for 10 years and served as the principal of a high school. 

                Mr. Jeilani has played a significant role in developing curricula for high schools and universities, and he was the first designer of academic documents at Benadir University. He is also one of the founders of the Abubakar As-Sadiq Islamic Institute in Minneapolis, USA. Furthermore, he supervised the archives and student transfers at the Banadir Region Educational Office.
                
                Mr. Jeilani has over 40 years of experience in the field of education. Throughout his distinguished career, he has made significant contributions to educational institutions and systems, showcasing his dedication to academic excellence and innovation. His extensive experience encompasses various roles, from teaching and administration to curriculum development and institutional leadership. 

Beyond his professional achievements, Mr. Jeilani is deeply committed to fostering a supportive and enriching environment for students. He believes in the transformative power of education and is dedicated to guiding and advising students to help them reach their full potential. His passion for education is evident in his continuous efforts to enhance learning experiences and outcomes for students at all levels.`},
        ]
    

export const admin =  [
            {id: 1, name: "Mr. Nor Abdulle Afrah", tiltle: "Director of HRM", photo: nur, bio: ''},
            {id: 2, name: "", tiltle: "Director of XXX", photo: avatar, bio: '', desc:`his bio is gonna publish soon`},
            {id: 3, name: "", tiltle: "Director of XXX", photo: avatar, bio: '', desc:`his bio is gonna publish soon`},
            {id: 4, name: "", tiltle: "Director of XXX", photo: avatar, bio: '', desc:`his bio is gonna publish soon`},
            {id: 5, name: "", tiltle: "Director of XXX", photo: avatar, bio: '', desc:`his bio is gonna publish soon`},
            {id: 6, name: "", tiltle: "Director of XXX", photo: avatar, bio: '', desc:`his bio is gonna publish soon`},
            {id: 7, name: "", tiltle: "Director of XXX", photo: avatar, bio: '', desc:`his bio is gonna publish soon`},
            {id: 8, name: "", tiltle: "Director of XXX", photo: avatar, bio: '', desc:`his bio is gonna publish soon`},
        ]


export const deans =  [
            {id: 1, name: "", tiltle: "Dean of faculty", photo: avatar, bio: '', desc:`his bio is gonna publish soon`},
            {id: 2, name: "", tiltle: "Dean of faculty", photo: avatar, bio: '', desc:`his bio is gonna publish soon`},
            {id: 3, name: "", tiltle: "Dean of faculty", photo: avatar, bio: '', desc:`his bio is gonna publish soon`},
            {id: 4, name: "", tiltle: "Dean of faculty", photo: avatar, bio: '', desc:`his bio is gonna publish soon`},
        ]