export const med = 
{id: 1, 
 Sem1: [
        {id:1, title: "English-I", LH: 90, PH: 30, CH:120, CU:8},
        {id:2, title: "Arabic-I", LH: 30, PH: "", CH:30, CU:2},
        {id:3, title: "Somali Study-I", LH: 30, PH: 30, CH:30, CU:2},
        {id:4, title: "Academic writing & critical Thinking - I", LH: 30, PH: "", CH:30, CU:2},
        {id:5, title: "Computer Skills & Data Processing", LH: 30, PH: 30, CH:60, CU:4},
        {id:6, title: "Islamic Study", LH: 30, PH: "", CH:30, CU:2},
        {id:7, title: "Mathematics-I", LH: 60, PH: "", CH:60, CU:4},
        {id:8, title: "Cell-Biology-I", LH: 60, PH: "", CH:60, CU:4},
        {id:9, title: "Chemistry-I", LH: 60, PH: "", CH:60, CU:4},
        {id:10, title: "Medical Physics-I", LH: 60, PH: "", CH:60, CU:4},
        {id:11, title: "    ", LH: 480, PH: 60, CH:540, CU:36},
], 
Sem2: [
    {id:1, title: "English-I", LH: 90, PH: 30, CH:120, CU:8},
    {id:2, title: "Arabic-II", LH: 30, PH: "", CH:30, CU:2},
    {id:3, title: "Somali Study-II", LH: 30, PH: "", CH:30, CU:2},
    {id:4, title: "Academic writing & critical Thinking - II", LH: 30, PH: "", CH:30, CU:2},
    {id:5, title: "Conflict Resolution", LH: 30, PH: "", CH:30, CU:2},
    {id:6, title: "Islamic Study", LH: 30, PH: "", CH:30, CU:2},
    {id:7, title: "Health Informatics", LH: 45, PH: "", CH:45, CU:3},
    {id:8, title: "Applied Medical Mathematics", LH: 45, PH: "", CH:45, CU:3},
    {id:9, title: "Chemistry II", LH: 60, PH: "", CH:60, CU:4},
    {id:10, title: "Medical Physics", LH: 60, PH: "", CH:60, CU:4},
    {id:11, title: "Cell Biology II", LH: 60, PH: "", CH:60, CU:4},
    {id:12, title: "     ", LH: 510, PH: 30, CH:540, CU:36},
]};
export const cs = 
    {id: 1, 
     Sem1: [
            {id:1, title: "Islamic Ethics", LH: 90, PH: 30, CH:120, CU:8},
            {id:2, title: "Arabic-I", LH: 30, PH: "", CH:30, CU:2},
            {id:3, title: "Somali Study-I", LH: 30, PH: 30, CH:30, CU:2},
            {id:4, title: "Academic writing & critical Thinking - I", LH: 30, PH: "", CH:30, CU:2},
            {id:5, title: "Computer Skills & Data Processing", LH: 30, PH: 30, CH:60, CU:4},
            {id:6, title: "Islamic Study", LH: 30, PH: "", CH:30, CU:2},
            {id:7, title: "Mathematics-I", LH: 60, PH: "", CH:60, CU:4},
            {id:8, title: "Cell-Biology-I", LH: 60, PH: "", CH:60, CU:4},
            {id:9, title: "Chemistry-I", LH: 60, PH: "", CH:60, CU:4},
            {id:10, title: "Medical Physics-I", LH: 60, PH: "", CH:60, CU:4},
            {id:11, title: "    ", LH: 480, PH: 60, CH:540, CU:36},]
    , Sem2: [
        {id:1, title: "English-I", LH: 90, PH: 30, CH:120, CU:8},
        {id:2, title: "Arabic-II", LH: 30, PH: "", CH:30, CU:2},
        {id:3, title: "Somali Study-II", LH: 30, PH: "", CH:30, CU:2},
        {id:4, title: "Academic writing & critical Thinking - II", LH: 30, PH: "", CH:30, CU:2},
        {id:5, title: "Conflict Resolution", LH: 30, PH: "", CH:30, CU:2},
        {id:6, title: "Islamic Study", LH: 30, PH: "", CH:30, CU:2},
        {id:7, title: "Health Informatics", LH: 45, PH: "", CH:45, CU:3},
        {id:8, title: "Applied Medical Mathematics", LH: 45, PH: "", CH:45, CU:3},
        {id:9, title: "Chemistry II", LH: 60, PH: "", CH:60, CU:4},
        {id:10, title: "Medical Physics", LH: 60, PH: "", CH:60, CU:4},
        {id:11, title: "Cell Biology II", LH: 60, PH: "", CH:60, CU:4},
        {id:12, title: "     ", LH: 510, PH: 30, CH:540, CU:36},
    ]
};
export const  edu =
{id: 1, 
 Sem1: [
        {id:1, title: "Arabic -I", LH: 30, PH: "", CH:30, CU:2},
        {id:2, title: "Chemistry I", LH: 45, PH: "", CH:45, CU:3},
        {id:3, title: "English I", LH: 90, PH: 30, CH:120, CU:8},
        {id:4, title: "Islamic Ethics ", LH: 30, PH: "", CH:30, CU:2},
        {id:5, title: "Computer Skills & Data Processing", LH: 30, PH: 30, CH:60, CU:4},
        {id:6, title: "Pre- Calculus", LH: 45, PH: "", CH:45, CU:3},
        {id:7, title: "Physics I ", LH: 45, PH: "", CH:45, CU:3},
        {id:8, title: "Zoology I", LH: 45, PH: "", CH:45, CU:3},
        {id:9, title: "Somali Studies I", LH: 30, PH: "", CH:30, CU:2},
        {id:10, title: "Academic writing & critical Thinking - I", LH: 30, PH: "", CH:30, CU:2},
        {id:11, title: "    ", LH: 420, PH: 60, CH:480, CU:32},
], Sem2: [
    {id:1, title: "English-II", LH: 90, PH: 30, CH:120, CU:8},
    {id:2, title: "Arabic-II", LH: 30, PH: "", CH:30, CU:2},
    {id:3, title: "Somali Studies II", LH: 30, PH: "", CH:30, CU:2},
    {id:4, title: "Academic writing & critical Thinking", LH: 30, PH: "", CH:30, CU:2},
    {id:5, title: "Conflict Resolution", LH: 30, PH: "", CH:30, CU:2},
    {id:6, title: "Islamic Study", LH: 30, PH: "", CH:30, CU:2},
    {id:7, title: "Physics-II", LH: 45, PH: "", CH:45, CU:3},
    {id:8, title: "Calculus-I", LH: 45, PH: "", CH:45, CU:3},
    {id:9, title: "     ", LH: 330, PH: 30, CH:360, CU:24},
]};

export const Eng =
{id: 1, 
 Sem1: [
        {id:1, title: "Chemistry I", LH: 60, PH: "", CH:60, CU:4},
        {id:2, title: "Islamic Ethics", LH: 30, PH: "", CH:30, CU:2},
        {id:3, title: "Computer Skills & Data Processing", LH:30, PH: 30, CH:60, CU:4},
        {id:4, title: "Physics I", LH: 60, PH:"", CH:60, CU:4},
        {id:5, title: "English-I", LH: 90, PH: 30, CH:120, CU:8},
        {id:6, title: "Introduction to Engineering", LH:60, PH: "", CH:60, CU:4},
        {id:7, title: "Arabic-I ", LH: 30, PH:"", CH:30, CU:2},
        {id:8, title: "Somali Studies", LH: 30, PH:"", CH:30, CU:2},
        {id:9, title: "Academic writing & critical Thinking - I", LH:30, PH:"", CH:30, CU:2},
        {id:10, title: "    ", LH: 420, PH: 60, CH:480, CU:32},
], Sem2: [
    {id:1, title: "Islamic Study", LH:30, PH:"", CH:30, CU:2},
    {id:2, title: "Arabic-II", LH: 30, PH: "", CH:30, CU:2},
    {id:3, title: "Somali Study II", LH: 30, PH: "", CH:30, CU:2},
    {id:4, title: "Academic writing & critical Thinking - II", LH: 30, PH: "", CH:30, CU:2},
    {id:5, title: "Conflict Resolution", LH: 30, PH: "", CH:30, CU:2},
    {id:6, title: "English-II", LH: 90, PH: "30", CH:120, CU:8},
    {id:7, title: "Introduction to Engineering", LH: 60, PH: "", CH:60, CU:4},
    {id:8, title: "Physics I", LH: 60, PH: "", CH:60, CU:4},
    {id:9, title: "Chemistry II", LH: 60, PH:"", CH:60, CU:4},
    {id:10, title: "Calculus-I", LH: 90, PH:"", CH:90, CU:6},
    {id:11, title: "     ", LH: 510, PH:30, CH:540, CU:36},
]};

export const Shl =
{id: 1, 
 Sem1: [
        {id:1, title: "Arabic-I", LH: 120, PH:"", CH:120, CU:8},
        {id:2, title: "English-I", LH: 90, PH:30, CH:60, CU:4},
        {id:3, title: "Introduction to law", LH: 30, PH:"", CH:30, CU:2},
        {id:4, title: "Introduction to Islamic sahri’a",  LH: 30, PH:"", CH:30, CU:2},
        {id:5, title: "Islamic Ethics ", LH: 30, PH:"", CH:30, CU:2},
        {id:6, title: "Academic writing & critical Thinking - I", LH: 30, PH:"", CH:30, CU:2},
        {id:7, title: "Somali Studies ", LH:30, PH:"", CH:30, CU:2},
        {id:8, title: "Computer Skills & Data Processing", LH: 30, PH:30, CH:60, CU:4},
        {id:9, title: "    ", LH: 390, PH:60, CH:390, CU:26},
], 
Sem2: [
    {id:1, title: "English-II", LH:90, PH:30, CH:30, CU:2},
    {id:2, title: "Arabic-II", LH: 120, PH:"", CH:120, CU:8},
    {id:3, title: "Somali Study-II", LH:30, PH:"", CH:30, CU:2},
    {id:4, title: "Academic writing & critical Thinking - II", LH:30, PH:"", CH:30, CU:2},
    {id:5, title: "Conflict Resolution", LH: 30, PH:"", CH:30, CU:2},
    {id:6, title: "Islamic Study", LH:30, PH:"", CH:30, CU:2},
    {id:7, title: "Introduction to management", LH:30, PH: "", CH:30, CU:2},
    {id:8, title: "Science Of Quran", LH:30, PH: "", CH:30, CU:2},
    {id:9, title: "     ", LH: 390, PH:30, CH:330, CU:22},
]};

export const Vet =
{id: 1, 
 Sem1: [
        {id:1, title: "English-I", LH: 90, PH:30 , CH:120, CU:8},
        {id:2, title: "Arabic-I",   LH: 30, PH:"", CH:30, CU:2},
        {id:3, title: "Islamic Ethics", LH: 30, PH:"", CH:30, CU:2},
        {id:4, title: "Academic writing & critical Thinking", LH: 30, PH:"", CH:30, CU:2},
        {id:5, title: "Computer Skills & Data Processing ", LH:30, PH:30 , CH:60, CU:4},
        {id:6, title: "General Chemistry", LH: 60, PH:"", CH:60, CU:4},
        {id:7, title: "Mathmatics ", LH: 60, PH:"", CH:60, CU:4},
        {id:8, title: "Zoology I", LH: 60, PH:"", CH:60, CU:4},
        {id:9, title: "    ", LH: 390, PH:60, CH:450, CU:30},
], Sem2: [
    {id:1, title: "English-II", LH: 90, PH:30, CH:120, CU:8},
    {id:2, title: "Arabic-II", LH: 30, PH:"", CH:30, CU:2},
    {id:3, title: "Somali Study-II", LH: 30, PH: "", CH:30, CU:2},
    {id:4, title: "Academic writing & critical Thinking - II", LH:30, PH: "", CH:30, CU:2},
    {id:5, title: "Conflict Resolution", LH: 30, PH:"", CH:30, CU:2},
    {id:6, title: "Islamic Study", LH:30, PH:"", CH:30, CU:2},
    {id:7, title: "Organic  Chemistry", LH: 60, PH:"", CH:60, CU:4},
    {id:8, title: "Zoology II", LH: 60, PH:"", CH:60, CU:4},
    {id:9, title: "Physics", LH:60, PH:"", CH:60, CU:4},
    {id:10,title: "     ", LH: 420, PH:30, CH:450, CU:30},
]};


export const  Agr =
{id: 1, 
 Sem1: [
        {id:1, title:  "Arabic", LH: 30, PH:"", CH:30, CU:2},
        {id:2, title: "English", LH: 90, PH:30, CH:120, CU:8},
        {id:3, title: "Islamic Ethics", LH:30, PH:"", CH:30, CU:2},
        {id:4, title: "Computer Skills & Data Processing", LH: 30, PH:30, CH:60, CU:4},
        {id:5, title: "Mathematics", LH: 60, PH:"", CH:60, CU:4},
        {id:6, title: "General Chemistry", LH: 60, PH:"", CH:60, CU:4},
        {id:7, title: "Somali Studies", LH:30, PH:"", CH:30, CU:2},
        {id:8, title: "Academic writing & critical Thinking - I", LH:30, PH:"", CH:30, CU:2},
        {id:9, title: "Zoology I", LH: 60, PH:"", CH:60, CU:4},
        {id:10, title: "    ", LH: 420, PH:60, CH:480, CU:32},
], Sem2: [
    {id:1, title: "English-II", LH: 90, PH:30, CH:120, CU:8},
    {id:2, title: "Arabic-II", LH: 30, PH:"", CH:30, CU:2},
    {id:3, title: "Somali Study-II", LH:30, PH:"", CH:30, CU:2},
    {id:4, title: "Academic writing & critical Thinking - II", LH: 30, PH:"", CH:30, CU:2},
    {id:5, title: "Conflict Resolution", LH: 30, PH: "", CH:30, CU:2},
    {id:6, title: "Islamic Study", LH: 30, PH:"", CH:30, CU:2},
    {id:7, title: "Organic  Chemistry", LH: 60, PH:"", CH:60, CU:4},
    {id:8, title: "Genral Biology", LH: 60, PH:"", CH:60, CU:4},
    {id:9, title: "Physics", LH:60, PH:"", CH:60, CU:4},
    {id:10,title: "     ", LH: 420, PH:30, CH:450, CU:30},
]};

export const HS_Nur =
{id: 1, 
 Sem1: [
        {id:1, title: "English-I", LH:90, PH:30, CH:120, CU:8},
        {id:2, title: "Arabic-I", LH:30, PH:"", CH:30, CU:2},
        {id:3, title: "Islamic Ethics ", LH:30, PH:"", CH:30, CU:2},
        {id:4, title: "Academic writing & critical Thinking - I", LH: 30, PH:"", CH:30, CU:2},
        {id:5, title: "Computer Skills & Data Processing ", LH: 30, PH:30, CH:60, CU:4},
        {id:6, title: "Chemistry ", LH:45, PH:"", CH:45, CU:3},
        {id:7, title: "Physics ", LH: 45, PH:"", CH:45, CU:3},
        {id:8, title: "Biology ", LH: 45, PH:"", CH:45, CU:3},
        {id:9, title: "Math", LH: 30, PH:"", CH:30, CU:2},
        {id:10, title: "    ", LH: 375, PH:60, CH:435, CU:29},
], 
 Sem2:[
        {id:1, title: "English-II", LH: 90, PH:30, CH:120, CU:8},
        {id:2, title: "Arabic-II", LH: 30, PH:"", CH:30, CU:2},
        {id:3, title: "Somali Study-II ", LH:30, PH:"", CH:30, CU:2},
        {id:4, title: "Academic writing & critical Thinking - II", LH: 30, PH:"", CH:30, CU:2},
        {id:5, title: "Conflict Resolution", LH:30, PH:"", CH:30, CU:2},
        {id:6, title: "Behavior Science ", LH:30, PH:"", CH:30, CU:2},
        {id:7, title: "Health Ethics", LH:30, PH:"", CH:30, CU:2},
        {id:8, title: "Fundamental of Nursing 1 ", LH: 45, PH:"", CH:45, CU:3},
        {id:9, title: "    ", LH: 315, PH:30, CH:345, CU:23},
   

],
};

export const HS_Mw =
{id: 1, 
 Sem1: [
        {id:1, title: "English-I", LH:90, PH:30, CH:120, CU:8},
        {id:2, title: "Arabic-I", LH:30, PH:"", CH:30, CU:2},
        {id:3, title: "Islamic Ethics ", LH:30, PH:"", CH:30, CU:2},
        {id:4, title: "Academic writing & critical Thinking - I", LH: 30, PH:"", CH:30, CU:2},
        {id:5, title: "Computer Skills & Data Processing ", LH: 30, PH:30, CH:60, CU:4},
        {id:6, title: "Chemistry ", LH:45, PH:"", CH:45, CU:3},
        {id:7, title: "Physics ", LH: 45, PH:"", CH:45, CU:3},
        {id:8, title: "Biology ", LH: 45, PH:"", CH:45, CU:3},
        {id:9, title: "Math", LH: 30, PH:"", CH:30, CU:2},
        {id:10, title: "    ", LH: 375, PH:60, CH:435, CU:29},
], 
 Sem2: [
        {id:1, title: "English-II", LH: 90, PH:30, CH:120, CU:8},
        {id:2, title: "Arabic-II", LH: 30, PH:"", CH:30, CU:2},
        {id:3, title: "Somali Study-II ", LH: 30, PH:"", CH:30, CU:2},
        {id:4, title: "Academic writing & critical Thinking - II", LH: 30, PH:"", CH:30, CU:2},
        {id:5, title: "Conflict Resolution", LH: 30, PH:"", CH:30, CU:2},
        {id:6, title: "Behavior Science ", LH: 30, PH:"", CH:30, CU:2},
        {id:7, title: "Health Ethics", LH:30, PH:"", CH:30, CU:2},
        {id:8, title: "Fundamental of Midwifery ", LH:45, PH:"", CH:45, CU:3},
        {id:9, title: "    ", LH: 315, PH:30, CH:345, CU:23},
   

],
};

export const HS_ML =
{id: 1, 
 Sem1: [
        {id:1, title: "English-I", LH:90, PH:30, CH:120, CU:8},
        {id:2, title: "Arabic-I", LH:30, PH:"", CH:30, CU:2},
        {id:3, title: "Islamic Ethics ", LH:30, PH:"", CH:30, CU:2},
        {id:4, title: "Academic writing & critical Thinking - I", LH: 30, PH:"", CH:30, CU:2},
        {id:5, title: "Computer Skills & Data Processing ", LH: 30, PH:30, CH:60, CU:4},
        {id:6, title: "Chemistry ", LH:45, PH:"", CH:45, CU:3},
        {id:7, title: "Physics ", LH: 45, PH:"", CH:45, CU:3},
        {id:8, title: "Biology ", LH: 45, PH:"", CH:45, CU:3},
        {id:9, title: "Math", LH: 30, PH:"", CH:30, CU:2},
        {id:10, title: "    ", LH: 375, PH:60, CH:435, CU:29},
], 
Sem2: [
        {id:1, title: "English-II", LH: 90, PH:30, CH:120, CU:8},
        {id:2, title: "Arabic-II", LH: 30, PH:"", CH:30, CU:2},
        {id:3, title: "Somali Study-II ", LH: 30, PH:"", CH:30, CU:2},
        {id:4, title: "Academic writing & critical Thinking - II", LH: 30, PH:"", CH:30, CU:2},
        {id:5, title: "Conflict Resolution", LH:30, PH:"", CH:30, CU:2},
        {id:6, title: "Behavior Science ", LH: 30, PH:"", CH:30, CU:2},
        {id:7, title: "Health Ethics", LH:30, PH:"", CH:30, CU:2},
        {id:8, title: "Lab Instrument ", LH:45, PH:30, CH:75, CU:5},
        {id:9, title: "    ", LH: 315, PH:60, CH:375, CU:25},
   

],
};

export const HS_PH =
{id: 1, 
 Sem1: [
        {id:1, title: "English-I", LH:90, PH:30, CH:120, CU:8},
        {id:2, title: "Arabic-I", LH:30, PH:"", CH:30, CU:2},
        {id:3, title: "Islamic Ethics ", LH:30, PH:"", CH:30, CU:2},
        {id:4, title: "Academic writing & critical Thinking - I", LH: 30, PH:"", CH:30, CU:2},
        {id:5, title: "Computer Skills & Data Processing ", LH: 30, PH:30, CH:60, CU:4},
        {id:6, title: "Chemistry ", LH:45, PH:"", CH:45, CU:3},
        {id:7, title: "Physics ", LH: 45, PH:"", CH:45, CU:3},
        {id:8, title: "Biology ", LH: 45, PH:"", CH:45, CU:3},
        {id:9, title: "Math", LH: 30, PH:"", CH:30, CU:2},
        {id:10, title: "    ", LH: 375, PH:60, CH:435, CU:29},
], 
Sem2:[
        {id:1, title: "English-II", LH: 90, PH:30, CH:120, CU:8},
        {id:2, title: "Arabic-II", LH: 30, PH:"", CH:30, CU:2},
        {id:3, title: "Somali Study-II ", LH: 30, PH:"", CH:30, CU:2},
        {id:4, title: "Academic writing & critical Thinking - II", LH: 30, PH:"", CH:30, CU:2},
        {id:5, title: "Conflict Resolution", LH:30, PH:"", CH:30, CU:2},
        {id:6, title: "Behavior Science ", LH: 30, PH:"", CH:30, CU:2},
        {id:7, title: "Health Ethics", LH:30, PH:"", CH:30, CU:2},
        {id:8, title: "Introduction to Public Health ", LH:45, PH:"", CH:45, CU:3},
        {id:9, title: "    ", LH: 315, PH:30, CH:345, CU:23},
],
};

export const HS_PHR =
{id: 1, 
 Sem1: [
        {id:1, title: "English-I", LH:90, PH:30, CH:120, CU:8},
        {id:2, title: "Arabic-I", LH:30, PH:"", CH:30, CU:2},
        {id:3, title: "Islamic Ethics ", LH:30, PH:"", CH:30, CU:2},
        {id:4, title: "Academic writing & critical Thinking - I", LH: 30, PH:"", CH:30, CU:2},
        {id:5, title: "Computer Skills & Data Processing ", LH: 30, PH:30, CH:60, CU:4},
        {id:6, title: "Chemistry ", LH:45, PH:"", CH:45, CU:3},
        {id:7, title: "Physics ", LH: 45, PH:"", CH:45, CU:3},
        {id:8, title: "Biology ", LH: 45, PH:"", CH:45, CU:3},
        {id:9, title: "Math", LH: 30, PH:"", CH:30, CU:2},
        {id:10, title: "    ", LH: 375, PH:60, CH:435, CU:29},
], 
Sem2:[
        {id:1, title: "English-II", LH: 90, PH:30, CH:120, CU:8},
        {id:2, title: "Arabic-II", LH: 30, PH:"", CH:30, CU:2},
        {id:3, title: "Somali Study-II ", LH: 30, PH:"", CH:30, CU:2},
        {id:4, title: "Academic writing & critical Thinking - II", LH: 30, PH:"", CH:30, CU:2},
        {id:5, title: "Conflict Resolution", LH:30, PH:"", CH:30, CU:2},
        {id:6, title: "Behavior Science ", LH: 30, PH:"", CH:30, CU:2},
        {id:7, title: "Health Ethics", LH:30, PH:"", CH:30, CU:2},
        {id:8, title: "Fundamental of Pharmacy ", LH:45, PH:"", CH:45, CU:3},
        {id:9, title: "    ", LH: 315, PH:30, CH:345, CU:23},
],
};

export const HS_Nutr =
{id: 1, 
 Sem1: [
        {id:1, title: "English-I", LH:90, PH:30, CH:120, CU:8},
        {id:2, title: "Arabic-I", LH:30, PH:"", CH:30, CU:2},
        {id:3, title: "Islamic Ethics ", LH:30, PH:"", CH:30, CU:2},
        {id:4, title: "Academic writing & critical Thinking - I", LH: 30, PH:"", CH:30, CU:2},
        {id:5, title: "Computer Skills & Data Processing ", LH: 30, PH:30, CH:60, CU:4},
        {id:6, title: "Chemistry ", LH:45, PH:"", CH:45, CU:3},
        {id:7, title: "Physics ", LH: 45, PH:"", CH:45, CU:3},
        {id:8, title: "Biology ", LH: 45, PH:"", CH:45, CU:3},
        {id:9, title: "Math", LH: 30, PH:"", CH:30, CU:2},
        {id:10, title: "    ", LH: 375, PH:60, CH:435, CU:29},
], 
Sem2: [
        {id:1, title: "English-II", LH: 90, PH:30, CH:120, CU:8},
        {id:2, title: "Arabic-II", LH: 30, PH:"", CH:30, CU:2},
        {id:3, title: "Somali Study-II ", LH: 30, PH:"", CH:30, CU:2},
        {id:4, title: "Academic writing & critical Thinking - II", LH: 30, PH:"", CH:30, CU:2},
        {id:5, title: "Conflict Resolution", LH:30, PH:"", CH:30, CU:2},
        {id:6, title: "Behavior Science ", LH: 30, PH:"", CH:30, CU:2},
        {id:7, title: "Health Ethics", LH:30, PH:"", CH:30, CU:2},
        {id:8, title: "introduction of Nutrition", LH:45, PH:"", CH:45, CU:3},
        {id:9, title: "    ", LH: 315, PH:30, CH:345, CU:23},
],
};

export const  EM =
{id: 1, 
 Sem1: [
        {id:1, title: "English-I", LH: 90, PH:30 , CH:120, CU:8},
        {id:2, title: "Arabic-I", LH: 30, PH:"", CH:30, CU:2},
        {id:3, title: "Academic writing & critical Thinking - I", LH: 30, PH:"", CH:30, CU:2},
        {id:4, title: "Islamic Ethics ", LH: 30, PH:"", CH:30, CU:2},
        {id:5, title: "Somali Studies", LH: 30, PH:"", CH:30, CU:2},
        {id:6, title: "Computer Skills & Data Processing", LH: 30, PH: 30, CH:60, CU:4},
        {id:7, title: "Principles of Economics ", LH: 60, PH:"", CH:60, CU:4},
        {id:8, title: "Accounting I", LH:60, PH:"", CH:60, CU:4},
        {id:9, title: "    ", LH: 360, PH:60, CH:420, CU:28},
], Sem2: [
    {id:1, title: "English-II", LH: 90, PH:30, CH:120, CU:8},
    {id:2, title: "Arabic-II", LH: 30, PH: "", CH:30, CU:2},
    {id:3, title: "Somali Study-II", LH: 30, PH: "", CH:30, CU:2},
    {id:4, title: "Academic writing & critical Thinking - II", LH: 30, PH: "", CH:30, CU:2},
    {id:5, title: "Conflict Resolution", LH: 30, PH: "", CH:30, CU:2},
    {id:6, title: "Islamic Study", LH: 30, PH:"", CH:30, CU:2},
    {id:7, title: "Quantitative Methods ", LH: 60, PH: "", CH:60, CU:4},
    {id:8, title: "Accounting II", LH:60, PH: "", CH:60, CU:4},
    {id:9,title: "     ", LH:360, PH:30, CH:390, CU:26},
]};

export const Den =
{id: 1, 
 Sem1: [
        {id:1, title: "English-I", LH: 90, PH:30 , CH:120, CU:8},
        {id:2, title: "Arabic-I", LH: 30, PH:"", CH:30, CU:2},
        {id:3, title: "Somali Study-I", LH: 30, PH:"", CH:30, CU:2},
        {id:4, title: "Academic writing & critical Thinking -I ",LH: 30, PH:"", CH:30, CU:2},
        {id:5, title: "Computer Skills & Data Processing", LH: 30, PH:30, CH:60, CU:4},
        {id:6, title: "Islamic Study", LH:30, PH:"", CH:30, CU:2},
        {id:7, title: "Mathematics-I ", LH:60, PH:"", CH:60, CU:4},
        {id:8, title: "Cell-Biology-I ", LH: 60, PH:"", CH:60, CU:4},
        {id:9, title: "Chemistry-I", LH: 60, PH:"", CH:60, CU:4},
        {id:10, title:"Medical Physics-I", LH: 60, PH:"", CH:60, CU:4},
        {id:11,title: "    ", LH:480, PH:60, CH:540, CU:36},
        {id:12,title: "    ", LH:870, PH:90, CH:960, CU:64},
], Sem2: [
    {id:1, title: "English-II", LH: 90, PH:30, CH:120, CU:8},
    {id:2, title: "Arabic-II", LH: 30, PH:"", CH:30, CU:2},
    {id:3, title: "Somali Study-II", LH: 30, PH:"", CH:30, CU:2},
    {id:4, title: "Academic writing & critical Thinking - II", LH: 30, PH: "", CH:30, CU:2},
    {id:5, title: "Conflict Resolution", LH: 30, PH:"", CH:30, CU:2},
    {id:6, title: "Islamic Study", LH: 30, PH:"", CH:30, CU:2},
    {id:7, title: "Health Informatics ", LH:45, PH:"", CH:45, CU:3},
    {id:8, title: "Applied Medical Mathematics", LH: 45, PH:"", CH:45, CU:3},
    {id:9, title: "Introduction to Dentistry", LH: 60, PH:"", CH:60, CU:4},
    {id:10, title: "Medical Physics", LH: 60, PH:"", CH:60, CU:4},
    {id:11, title: "Cell Biology II", LH: 60, PH:"", CH:60, CU:4},
    {id:12, title: "Dental Anatomy", LH: 60, PH:"", CH:60, CU:4},
    {id:13,title: "     ", LH: 570, PH: 30, CH:600, CU:40},
   
                           
]};
export const  Geo  =
{id: 1, 
 Sem1: [
        {id:1, title: "Chemistry I", LH:30, PH:"", CH:30, CU:2},
        {id:2, title: "Islamic Ethics", LH: 30, PH:"", CH:30, CU:2},
        {id:3, title: "Internal earth process", LH: 60, PH:"", CH:60, CU:4},
        {id:4, title: "English I", LH: 90, PH:30, CH:120, CU:8},
        {id:5, title: "General Biology", LH:30, PH:"", CH:30, CU:2},
        {id:6, title: "Arabic", LH: 30, PH:"", CH:30, CU:2},
        {id:7, title: "Computer skills & Data Processing", LH: 30, PH:30, CH:60, CU:4},
        {id:8, title: "Academic writing & critical Thinking - I", LH:30, PH:"", CH:30, CU:2},
        {id:9, title: "Algebra and Analytical Geometry", LH:30, PH:"", CH:30, CU:2},
        {id:10, title: "Somali Studies", LH: 30, PH:"", CH:30, CU:2},
        {id:11, title: "Physics", LH:30, PH:"", CH:30, CU:2},
        {id:12, title: "    ", LH: 390, PH:60, CH:450, CU:30},
], Sem2: [
    {id:1, title: "English-II", LH: 90, PH:30, CH:120, CU:8},
    {id:2, title: "Arabic-II", LH: 30, PH: "", CH:30, CU:2},
    {id:3, title: "Somali Study-II", LH: 30, PH: "", CH:30, CU:2},
    {id:4, title: "Academic writing & critical Thinking - II", LH: 30, PH: "", CH:30, CU:2},
    {id:5, title: "Conflict Resolution", LH: 30, PH: "", CH:30, CU:2},
    {id:6, title: "Islamic Study", LH: 30, PH: "", CH:30, CU:2},
    {id:7, title: "Organic Chemistry", LH: 45, PH: "", CH:45, CU:3},
    {id:8, title: "Mathematics II", LH:45, PH: "", CH:45, CU:3},
    {id:9, title: "External earth processes", LH:60, PH:"", CH:60, CU:4},
    {id:10, title: "     ", LH: 390, PH:30, CH:420, CU:28},
]}; 
export const ss_ps =  
{id: 1, 
 Sem1: [
        {id:1, title: "Islamic Ethics", LH:30, PH:"", CH:30, CU:2},
        {id:2, title: "Somali Studies", LH: 30, PH:"", CH:30, CU:2},
        {id:3, title: "Arabic", LH: 30, PH:"", CH:30, CU:2},
        {id:4, title: "English I", LH: 90, PH:30, CH:120, CU:8},
        {id:5, title: "Mathematics", LH: 45, PH:"", CH:45, CU:3},
        {id:6, title: "Computer skills & Data Processing", LH:30, PH:30, CH:60, CU:4},
        {id:7, title: "Introduction to public Administration", LH: 45, PH:"", CH:45, CU:3},
        {id:8, title: "Introduction to Political Science", LH: 45, PH:"", CH:45, CU:3},
        {id:9, title: "Introduction to IR", LH: 45, PH:"", CH:45, CU:3},
        {id:10, title: "Academic writing & critical Thinking - I", LH: 30, PH:"", CH:30, CU:2},
        {id:11, title: "    ", LH: 420, PH:60, CH:480, CU:32},

], Sem2: [
    {id:1, title: "English II", LH: 90, PH: 30, CH:120, CU:8},
    {id:2, title: "Arabic II", LH: 30, PH:"", CH:30, CU:2},
    {id:3, title: "Somali Study-II", LH: 30, PH:"", CH:30, CU:2},
    {id:4, title: "Academic writing & critical Thinking - II", LH: 30, PH: "", CH:30, CU:2},
    {id:5, title: "Conflict Resolution", LH: 30, PH:"", CH:30, CU:2},
    {id:6, title: "Islamic Study", LH:30, PH:"", CH:30, CU:2},
    {id:7, title: "Public Policy Management", LH: 45, PH:30, CH:45, CU:3},
    {id:8, title: "General Math & Statics", LH: 45, PH:30, CH:45, CU:3},
    {id:9, title: "Introduction to Public Administration", LH: 45, PH:"", CH:45, CU:3},
    {id:10, title: "     ", LH: 375, PH:90, CH:405, CU:27},

]
}
export const ss_pa =  
{id: 1, 
 Sem1: [
        {id:1, title: "Islamic Ethics", LH:30, PH:"", CH:30, CU:2},
        {id:2, title: "Somali Studies", LH: 30, PH:"", CH:30, CU:2},
        {id:3, title: "Arabic", LH: 30, PH:"", CH:30, CU:2},
        {id:4, title: "English I", LH: 90, PH:30, CH:120, CU:8},
        {id:5, title: "Mathematics", LH: 45, PH:"", CH:45, CU:3},
        {id:6, title: "Computer skills & Data Processing", LH:30, PH:30, CH:60, CU:4},
        {id:7, title: "Introduction to public Administration", LH: 45, PH:"", CH:45, CU:3},
        {id:8, title: "Introduction to Political Science", LH: 45, PH:"", CH:45, CU:3},
        {id:9, title: "Introduction to IR", LH: 45, PH:"", CH:45, CU:3},
        {id:10, title: "Academic writing & critical Thinking - I", LH: 30, PH:"", CH:30, CU:2},
        {id:11, title: "    ", LH: 420, PH:60, CH:480, CU:32},

],Sem2: [
    {id:1, title: "English II", LH: 90, PH:30, CH:120, CU:8},
    {id:2, title: "Arabic II", LH: 30, PH:"", CH:30, CU:2},
    {id:3, title: "Somali Study-II", LH: 30, PH:"", CH:30, CU:2},
    {id:4, title: "Academic writing & critical Thinking - II", LH: 30, PH: "", CH:30, CU:2},
    {id:5, title: "Conflict Resolution", LH: 30, PH:"", CH:30, CU:2},
    {id:6, title: "Islamic Study", LH: 30, PH: "", CH:30, CU:2},
    {id:7, title: "Quantitative Methods", LH: 45, PH:30, CH:60, CU:4},
    {id:8, title: "Accounting II", LH: 45, PH:30, CH:60, CU:4},
    {id:9, title: "Introduction to Public Administration", LH:45, PH:"", CH:45, CU:3},
    {id:10, title: "     ", LH: 375, PH:90, CH:435, CU:29},
]}; 

export const ms =  
{id: 1, 
 Sem1: [
        {id:1, title: "Algebra and Analytical Geometry", LH: 45, PH:"", CH:45, CU:3},
        {id:2, title: "Physics I", LH: 45, PH: "", CH:45, CU:3},
        {id:3, title: "Chemistry", LH: 45, PH: "", CH:45, CU:3},
        {id:4, title: "Islamic Ethics", LH: 30, PH:"", CH:30, CU:2},
        {id:5, title: "Introduction to Marine-Biology", LH: 60, PH:"", CH:60, CU:4},
        {id:6, title: "English I", LH:90, PH:30, CH:120, CU:8},
        {id:7, title: "Arabic", LH:30, PH:"", CH:30, CU:2},
        {id:8, title: "Computer Skills and Data Processing", LH:30, PH:30, CH:60, CU:4},
        {id:9, title: "Academic writing & critical Thinking - I", LH:30, PH:"", CH:30, CU:2},
        {id:10, title: "Somali Studies", LH:30, PH:"", CH:30, CU:2},
        {id:11, title: "    ", LH: 435, PH: 60, CH:495, CU:33},
], Sem2: [
    {id:1, title: "English-II", LH: 90, PH:30, CH:120, CU:8},
    {id:2, title: "Arabic-II", LH:30, PH: "", CH:30, CU:2},
    {id:3, title: "Somali Study-II", LH: 30, PH: "", CH:30, CU:2},
    {id:4, title: "Academic writing & critical Thinking - II", LH: 30, PH:"", CH:30, CU:2},
    {id:5, title: "Conflict Resolution", LH: 30, PH: "", CH:30, CU:2},
    {id:6, title: "Islamic Study", LH: 30, PH: "", CH:30, CU:2},
    {id:7, title: "organic chemistry", LH: 45, PH: "", CH:45, CU:3},
    {id:8, title: "Physics I", LH:45, PH:"", CH:45, CU:3},
    {id:9, title: "Introduction to oceanography", LH: 60, PH:"", CH:60, CU:4},
    {id:10, title: "     ", LH: 390, PH:30, CH:420, CU:28},
]};














    