import React, { useState } from 'react'
import MenuOptions from "../../sections/menuOptions"
import ResearchNav from './researchNav';
import ResNav from './resNav';







const avatar = 'https://bu-page-assets.s3.ap-northeast-1.amazonaws.com/static-assets/others/khalif.jpg';



const Research = ({children}) => {
 

  const backet = 'https://bu-page-assets.s3.ap-northeast-1.amazonaws.com/static-assets';

  const campus =  backet + '/others/campus.png'
  return (
      <div className='pt-20'>

          <div className="md:h-full xl:h-full flex md:flex-col xl:flex-col flex-col space-y-2  overflow-hidden md:ml-16 xl:ml-16 text-[#0843aafe] md:space-x-2 xl:space-x-2 my-3 mb-16 ">
            <div className='md:h-full  xl:h-full     w-full '>

            {/* <ResearchNav /> */}
            <ResNav />

            </div>
            <div className='h-full px-2 font-sans '>
            
              {children}
            </div>
            </div>
     
   

      </div>
   
  )
}

export default Research