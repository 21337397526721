import React, { useState} from 'react'
import Swipe from "react-easy-swipe";
import { BsChevronCompactLeft, BsChevronCompactRight } from 'react-icons/bs';
import { BiSolidQuoteAltLeft, BiSolidQuoteAltRight } from 'react-icons/bi';


const backet = 'https://bu-page-assets.s3.ap-northeast-1.amazonaws.com/static-assets';
const msg = `It’s a matter of pride and satisfaction to introduce you to Benadir University (BU), a leading private university in Somalia. Our university was founded in 2002 by Somali intellectuals with a mission to revitalize the education system affected by civil war and to provide education to the Somali population. With 22 years of history, we continue to offer quality education through a diverse range of programs at the undergraduate and postgraduate levels, preparing students for success and contributing significantly to various sectors in Somalia. And by doing so, BU has been recognized by the World Health Organization (WHO) as an education provider in Somalia.
Thank you for choosing us for your education!`;
const msg1 = "Over a decade ago, plans for Benadir University was announced and students, locals, and future administrators were all excited. From the admission of its first few students at the Headquarter in early September 2002, the University has embarked on an ambitious journey to become a nationally recognize university known for the contributions it makes to its students.";
const msg2 = "The University launched its ‘Vision, Value, and Voices’ or 3V’s to prospective students and local leaders to discuss the present needs and future ambitions of the university. During the past ten years B.U. has expanded its student body and resources as well as building new campuses at KM 13. The University launched its ‘Vision, Value, and Voices’ or 3V’s to prospective students and local leaders to discuss the present needs and future ambitions of the university. During the past ten years B.U. has expanded its student body and resources as well as building new campuses at KM 13. The University launched its ‘Vision, Value, and Voices’ or 3V’s to prospective students and local leaders to discuss the present needs and future ambitions of the university. During the past ten years B.U. has expanded its student body and resources as well as building new campuses at KM 13.";

function Messages() {
    const slides = 
      {url: backet + '/messages/rector.png', msg: msg, pub: " Prof. Dr. Mohamed Mohamud Hassan (Biday)", title: "Rector"}
      // {url: backet + '/carousels/2.jpg', msg: msg1, pub: "Prof. Dr. ABDULKADIR MOHAMED", title: "BU ICT MANAGER"},
      // {url: backet + '/carousels/3.jpg', msg: msg2, pub: "Prof. Dr. ABDULRAHMAN BIDAY", title: "BU Deputy Ractor"},
    //]

  
    

  return (
    <div className=' h-[500px] w-full   md:my-10 xl:my-16 relative group md:px-7 xl:px-7 flex md:flex-row xl:flex-row justify-center items-center' >
    <img src={slides.url} alt="messages" className='sm:hidden xs:hidden h-[500px] duration-500 '/>
    <div className='md:w-[40%] md:mx-10 xl:w-[40%] xl:mx-10 mx-4  relative inline-block '>
        <BiSolidQuoteAltLeft />
        <p className='text-xl px-5 text-justify leading-relaxed  line-clamp-[10] whitespace-pre-line'>
            {slides.msg}
        </p>
        <BiSolidQuoteAltRight />
        <p className='text-xl px-5 font-bold  mt-4'>
          {slides.pub}
        </p>
        <p className='text-xl px-5 font-bold'>
          {slides.title}
        </p>
    </div>
  </div>
  )
}

export default Messages;