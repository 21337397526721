import React from "react";
import { NavLink, Link } from "react-router-dom";
import bird from "../../../assets/images/research/alliences/bird.jpg"
import digfer from "../../../assets/images/research/alliences/digfer.png"
const sample =
  "https://bu-page-assets.s3.ap-northeast-1.amazonaws.com/static-assets/others/ractor.jpg";

const ResearchAllience = (props) => {
  const alliences = [
    {
      id: 1,
      img: bird,
      name: "Benadir Institute of Research and Development"
    },
    {
      id: 2,
      img: digfer,
      name: "Mogadishu Somali Türkiye Training and Research Hospital"
    }
  ]
  return (
    <div>
      
      <p className="text-xl xs:text-base font-semibold font-montserrat" >Benadir University and Its Partners Unite for Groundbreaking Research Excellence</p>

      <div className="my-5 grid grid-cols-5 xs:grid-cols-1 gap-8">
        {/* card */}
        {
          alliences.map( a => (
          <div key={a.id}>
          <img src={a.img} alt="carddd" className="w-[200px] h-[200px]" />
          <p className="my-2 font-inriaserif ">{a.name}</p>
        </div>
          ))
        }
        

      </div>
    </div>
  );
};

export default ResearchAllience;
