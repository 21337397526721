import React from "react";

import { HiOutlineMicrophone } from "react-icons/hi2";
import { IoIosMenu } from "react-icons/io";
import { BsArrowsFullscreen } from "react-icons/bs";
import { GoSearch } from "react-icons/go";
import { FaAngleDown, FaAngleUp } from "react-icons/fa6";
import Somali from "../../assets/images/flags/somali.jpg";
import US from "../../assets/images/flags/us.jpg";
import avatar from "../../assets/images/users/avatar-6.jpg";

import { getCurrentUser } from "../../services/authServices";
import { useState } from "react";
import { Link } from "react-router-dom";
import { logout } from "../../services/authServices";
import {menus} from "../../data/menus";
import Sidebar from '../sidebar/Side';



const Header = () => {
  const [avatarMenueToglle, setAvatarMenueToglle] = useState(true);
  const [languageToggle, setLanguageToggle] = useState(true);
  const [sidebarToggle, setSidebarToggle] = useState(false);

  const user = getCurrentUser();
  const logo_mobile =
    "https://bu-page-assets.s3.ap-northeast-1.amazonaws.com/static-assets/others/Benadir University Logo.png";

    const activateFullScreen = (e) => {
      e.preventDefault();
      if (!document.fullscreenElement && !document.mozFullScreenElement && !document.webkitFullscreenElement) {
        if (document.documentElement.requestFullscreen) {
            document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
            document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
            document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
        }
    } else {
        if (document.cancelFullScreen) {
            document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
            document.webkitCancelFullScreen();
        }
    }
    }
    const handleLogOut = ()=>{

      logout()
    }
  

    const showToggle = () => {
      setSidebarToggle(!sidebarToggle)
    }
  
  return (
    <>
    <div className="app-header flex items-center justify-between    ">
      <div className="flex flex-row">
         <div className="flex flex-row justify-start relative  px-5 sm:hidden xs:hidden ">
        {/* <!-- Brand Logo --> */}
        <a href="/" className="lg:hidden p-2">
          <img src={logo_mobile} className="h-10" alt="Small logo" />
        </a>
      </div>
      <div className="sm:flex xs:flex flex-row justify-start relative  px-5 sm:visible xs:visible hidden ">
        {/* <!-- Brand Logo --> */}
        <button  className="p-2" onClick={showToggle}>
          <IoIosMenu size={30} />
        </button>
      </div>
      </div>
     

      {/* <!-- Topbar Search --> */}
      <div className="sm:hidden xs:hidden">
      <div className="md:flex  items-center relative  ">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none ">
            <GoSearch />
          </div>
          <input
            type="search"
            className="w-[450px] form-input px-10 rounded-full text-[15px]  bg-gray-500/10 border-transparent focus:border-transparent focus:ring-1"
            placeholder="Search..."
          />
          <button
            type="button"
            className="absolute inset-y-0 right-0 flex items-center pr-3 "
          >
            <HiOutlineMicrophone className="text-base text-blue-900 hover:text-blue" />
          </button>
        </div>
      </div>
      <div className="flex flex-row px-4 space-x-5 ">
        

        {/* <!-- Language Dropdown Button --> */}
        <div className="relative inline-block sm:hidden xs:hidden">
          <button
            type="button"
            className="nav-link p-2 waves-effect fc-dropdown"
            onClick={() => setLanguageToggle(!languageToggle)}
          >
            <span className="flex items-center justify-center h-6 w-6">
              <img src={US} alt="flags img" className="h-4 w-6" />
            </span>
          </button>
          <div
            className={`absolute right-0 z-10 mt-5  w-40 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none ${
              languageToggle ? "hidden" : ""
            }`}
          >
            <a
              href="#;"
              className="flex items-center gap-2.5 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100"
            >
              <img src={Somali} alt="flags img" className="h-4" />
              <span className="align-middle">Somali</span>
            </a>
            <a
              href="#;"
              className="flex items-center gap-2.5 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100"
            >
              <img src={US} alt="flags img" className="h-4" />
              <span className="align-middle">English</span>
            </a>
          </div>
        </div>

        {/* <!-- Fullscreen Toggle Button --> */}
        <div className="md:flex sm:hidden xs:hidden">
          <button
            onClick={(e) => activateFullScreen(e)}
            type="button"
            className="nav-link p-2 waves-effect"
          >
            <span className="flex items-center justify-center h-6 w-6 ">
              <BsArrowsFullscreen />
            </span>
          </button>
        </div>

        {/* <!-- Profile Dropdown Button --> */}
        <div className="relative">
          <button
            data-fc-type="dropdown"
            data-fc-placement="bottom-end"
            type="button"
            className="nav-link flex items-center gap-2.5 waves-effect p-2"
            onClick={() => setAvatarMenueToglle(!avatarMenueToglle)}
          >
            <img src={user.avatar ? user.avatar: avatar} alt="user-image" className="rounded-full h-8 w-8" />
            <span className="md:flex items-center hidden">
              <span className="font-medium text-base mx-2">{user.username}</span>
              {avatarMenueToglle ? <FaAngleDown /> : <FaAngleUp />}
            </span>
          </button>
          <div
            className={`absolute right-0 z-10 mt-3 w-40 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none ${
              avatarMenueToglle ? "hidden" : ""
            }`}
          >
            <a
              className="flex items-center py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100"
              href="#"
            >
              Inbox
            </a>
            <a
              className="flex items-center py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100"
              href="#"
            >
              Profile
            </a>
            <a
              className="flex items-center py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100"
              href="#"
            >
              Settings
            </a>
            <a
              className="flex items-center py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100"
              href="#"
            >
              Lock Account
            </a>
            <Link
              className="flex items-center py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100"
              to="/"
              onClick={handleLogOut}
            >
              Log Out
            </Link>
          </div>
        </div>
      </div>
    </div>
    <div className={`fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm flex justify-start items-start z-30 ease-in duration-300 ${sidebarToggle ? "" : "hidden"}`} onClick={showToggle}>

   <Sidebar />

      </div>
      </>
  );
};

export default Header;
