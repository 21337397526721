import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { programs } from "../../../data/programs";
import _ from "lodash";
import { getAllStaffs } from "../../../services/employStaff";
import api from "../../../config.json";
const url = api.url;

const StaffList = () => {
  const [staffData, setStaffData] = useState([]);

  const { slug } = useParams();
  let staff;
  let filtered_staff;
  const program = _.find(programs[0].lists, ["slug", slug]);

  if (staffData) {
    staff = _.filter(staffData, function (s) {
      return s.department === program.tag;
    });
  }

  useEffect(() => {
    async function fetchData() {
      const response = await getAllStaffs();
      setStaffData(response.data);
    }
    fetchData();
  }, []);

  return (
    <div className="relative w-full text-black mt-10  flex justify-center items-center ">
      <div className="my-8 py-2 grid grid-cols-4 sm:grid-cols-2 xs:grid-cols-1 gap-5  w-[70%]">
        {staff &&
          staff.map((s) => (
            <div
              key={s._id}
              className="flex flex-col  items-center  space-y-1 rounded-lg border-2 w-fit"
            >
              <img
                src={url + s.staff.avatar}
                alt="avatar"
                className="h-[250px] w-[250px] "
              />
              <div className="flex flex-col p-5">
                <Link
                  to={`${s._id}`}
                  state={{ staff: s }}
                  className="font-semibold underline cursor-pointer text-blue-600"
                >
                  {s.staff.title}. {s.staff.fullName}
                </Link>
                <p className="text-base">{s.occupation}</p>
                <p className="text-base">{s.staff.email}</p>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default StaffList;
