import { http } from "./httpServices";
import api from "../config.json";

// const url = api.local + "/staff";
const url = api.global + "/staff";

// const url = apiUrl + "/Staff";

function StaffUrl(id) {
  return `${url}/${id}`;
}

export function getAllStaff() {
  return http.get(url);
}

export function deleteStaff(StaffId) {
  return http.delete(StaffUrl(StaffId));
}

export async function getStaff(StaffId) {
  return http.get(StaffUrl(StaffId));
}

export function saveStaff(staff) {
  return http.post(url, staff,
    {
      headers: {'Content-Type': 'multipart/form-data'}
    });
}

export function updateStaff(Staff) {
    return http.put(StaffUrl(Staff._id), Staff,{
      headers: {'Content-Type': 'multipart/form-data'}
    });

}
