import React from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { GrLinkPrevious } from "react-icons/gr";
import { programs } from "../../../data/programs";
import _ from "lodash";
import api from "../../../config.json";
const url = api.url;

const SingleStaff = (props) => {
  const navigate = useNavigate();
  let { state } = useLocation();
  // console.log(state.staff);
  const s = state.staff;
  // console.log(s);

  return (
    <div className="w-full  xl:pt-[95px] md:pt-[95px] sm:pt-[95px] xs:pt-[75px] relative group  flex flex-col justify-center items-center sm:mx-5 xs:mx-5">
      <div className="grid grid-rows-1 mt-20 text-black  md:w-[60%] xl:w-[60%]">
        <div>
          <div className="flex flex-row sm:flex-col xs:flex-col  space-y-1">
            <div>
              <img
                src={url + s.staff.avatar}
                alt="avatar"
                className="h-[250px] w-[250px]  rounded-2xl"
              />
            </div>
            <div className="mt-10 md:mx-8 xl:mx-8 space-y-3 flex flex-col justify-center ">
              <p className="text-base">
                {s.staff.title}. {s.staff.fullName}
              </p>
              <p className="text-base">{s.occupation}</p>
              <p className="text-base">{s.staff.mobileNumber}</p>
              <p className="text-base">{s.staff.email}</p>
            </div>
          </div>

          <div className="mt-5 ">
            <div>
              <p className="font-semibold text-[18px] my-3">Biography</p>
              <div className="w-[80%] text-[18px] tracking-wide">
                <p className=" md:ml-10 xl:ml-10  my-5 rounded-full xs:text-justify whitespace-pre-line">
                  {s.staff.bio}
                </p>
              </div>
            </div>

            {s.staff.reserachInterest.length > 0 && (
              <div>
                <p className="font-semibold text-[18px] my-4">Research Areas</p>
                <ol className="list-disc">
                  {s.staff.reserachInterest.map((d, i) => (
                    <li key={i} className=" ml-10 my-2 rounded-full">
                      {d}
                    </li>
                  ))}
                </ol>
              </div>
            )}
            {/* {s.pub.length > 0 && (
            <div>
              <p className="font-semibold text-[18px] my-4">Publications</p>
              <ol className="list-decimal">
                {s.pub.map((p) => (
                  <li key={p.id} className="ml-10 ">
                    <a
                      href={p.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className=" tracking-wide  text-[18px] my-2 hover:underline hover:text-blue-500"
                    >
                      {p.title}{" "}
                    </a>
                    <br />
                  </li>
                ))}
              </ol>
            </div>
          )} */}
          </div>
        </div>
      </div>
      <div className="w-full flex justify-start items-center p-6  text-2xl md:w-[60%] xl:w-[60%]">
        <p
          onClick={() => navigate(-1)}
          className="rounded-full w-fit border-2 border-blue-600 p-2 hover:bg-blue-400 bg-white"
        >
          <GrLinkPrevious />
        </p>
      </div>
    </div>
  );
};

export default SingleStaff;
