import {http} from "./httpServices";
import   api  from "../config.json";


// const url = api.local + "/users";
const url = api.global + "/users";

export const isUserExist = () => {
  return http.get(url)
}


export function register(user) {
  return http.post(url, {
    username: user.username,
    email: user.email,
    password: user.password,
  });
}



