import React from 'react'
import AboutNav from "./nav"

const AboutUs = ({children}) => {
const backet = 'https://bu-page-assets.s3.ap-northeast-1.amazonaws.com/static-assets';

  const imageList = [
    {id: 7, url: backet +"/students/DSC00573.png"},
  ]

  return (
    <div className='pt-20'>
      <div className="h-full flex  flex-col justify-center w-full  overflow-hidden  text-[#0843aafe] md:space-x-2 ">
            <div className='flex justify-center h-auto '>
              <AboutNav />
            </div>
            <div className='w-full h-auto  font-sans sm:mx-4 xs:mx-4'>
            <div className=''>
              {children}
            </div>
            </div>
            </div>
      </div>
  )
}

export default AboutUs