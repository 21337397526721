import { FaLongArrowAltRight } from "react-icons/fa";
import img1 from "../assets/images/profle/welcome/4.jpg"
import img2 from "../assets/images/profle/welcome/2.jpg"

import img3 from "../assets/images/profle/welcome/3.jpg"

export const profile = [
    {
        id: 1, 
        icon: <FaLongArrowAltRight/>,
        title: "Introduction", 
        desc: `Benadir University, established in September 2002, emerged from a dire need for qualified professionals in Somalia's key sectors like health, engineering, law, education, and technology.
        Initially focused on medicine and surgery, it has expanded to encompass a diverse range of 
    faculties. Despite facing challenges, BU has maintained academic excellence, producing skilled 
    professionals with integrity. Today, with over 5,000 students across multiple campuses, BU stands as a beacon of quality education in Somalia, offering accredited programs and fostering a culture of innovation and learning.`,
        img: [
        ]},
    {
        id: 2,
        icon: <FaLongArrowAltRight/>,
        title: "History: Making an Impact for 22 Years", 
        desc: `Over the past two decades, Benadir University has grown from its humble beginnings to become a nationally recognized institution. Through strategic planning and community engagement, BU has witnessed a surge in enrollment, with campuses spread across various regions. The university's expansion into diverse faculties, postgraduate programs, and research initiatives underscores its commitment to academic excellence and societal development. With over 10,000 alumni worldwide mostly in medicine and health related disciplines, BU continues to leave a profound impact on Somalia's education landscape.`,
        img: [
        ]
    },
    {
        id: 3, 
        icon: <FaLongArrowAltRight/>,
        title: "Vision", 
        desc: `Benadir University aspires to be a premier institution that equips students with the skills and knowledge necessary to thrive in a rapidly evolving world. By fostering a dynamic learning environment and promoting individual expression and creativity, BU aims to empower its community and contribute to sustainable development for future generations.`,
        img: [
        ]
    },
    {
        id: 4, 
        icon: <FaLongArrowAltRight/>,
        title: "Mission", 
        desc: `Driven by a commitment to pushing the boundaries of knowledge, BU aims to deliver practical, value-based education that nurtures competent, ethical leaders. Through continuous improvement and internationalization efforts, the university seeks to enhance student satisfaction, promote research excellence, and foster a sense of global citizenship.`,
        img: []},
    {
        id: 5,
        icon: <FaLongArrowAltRight/>, 
        title: "Strategy of BU", 
        desc: `Benadir University's strategy focuses on delivering quality education through modern teaching methods and research excellence. By prioritizing performance development, internationalization, and revenue diversification, BU aims to ensure its sustainability and relevance in the ever changing educational landscape`,
        img: []},
    {
        id: 6, 
        icon: <FaLongArrowAltRight/>,
        title: "Values", 
        desc: `BU upholds values of efficiency, transparency, punctuality, respect, integrity, and professionalism as the cornerstone of its academic and professional standards. These values guide the university's actions and cultivate a culture of excellence and accountability.
        `,
        img: []},

        {
            id: 7, 
            icon: <FaLongArrowAltRight/>,
            title: "Mandatory Objectives", 
            desc: `Benadir University aims to facilitate access to quality education, produce skilled professionals, 
            promote gender equality, ensure lifelong learning for graduates, advance research, contribute to community development, foster a vibrant academic environment, and diversify revenue sources.
            `,
            img: []},
            {
                id: 8, 
                icon: <FaLongArrowAltRight/>,
                title: "International Linkages and Collaborations", 
                desc: `Recognizing the importance of global partnerships, BU has established linkages with reputable 
                universities and organizations worldwide. Through these collaborations, the university enhances 
                its academic offerings, research capabilities, and international visibility, thereby enriching the 
                educational experience for its students and contributing to global knowledge exchange.`,
                img: [
                    {id: 1, img: img1},
                    {id: 2, img: img2},
                ]},
            {
                id: 9, 
                icon: <FaLongArrowAltRight/>,
                title: "BU Research and Community Engagement Program (BURCEP)", 
                desc: `The Benadir University Institute of Research and Development (BIRD) epitomizes BU's 
                commitment to advancing knowledge and engaging with the global research community. By 
                fostering research excellence, mentoring young scholars, and disseminating knowledge, BIRD 
                plays a pivotal role in shaping Somalia's intellectual landscape and contributing to global research 
                trends.`,
                img: [
                    {id: 1, img: img3},
                ]
            },
            {
                id: 10, 
                icon: <FaLongArrowAltRight/>,
                title: "Benadir University's Impactful Initiatives", 
                desc: `In recent years, Benadir University (BU) has undertaken various projects in collaboration with esteemed partners, contributing significantly to healthcare and sustainable development in Somalia.`,
                img: []},
            {
                id: 11, 
                icon: <FaLongArrowAltRight/>,
                title: "2019: National Cholera Strategy Developmen", 
                desc: `BU, in collaboration with the Federal Ministry of Health and the World Health Organization (WHO) – Somalia Office, developed the National Cholera Strategy for 2020–2022. This comprehensive strategy is currently awaiting cabinet approval, aiming to combat cholera outbreaks effectively`,
                img: []},
            {
                id: 12, 
                icon: <FaLongArrowAltRight/>,
                title: "2020: COVID-19 Response and Polio Campaign Assessment", 
                desc: `Amid the COVID-19 pandemic, BU conducted a rapid assessment of frontline health professionals' vulnerability and health facility capacities, funded by the WHO Somali office. Additionally, BU was selected by UNICEF Somalia to assess post-polio vaccination campaign communications, ensuring the smooth implementation of vaccination efforts.`,
                img: []},
            {
                id: 13, 
                icon: <FaLongArrowAltRight/>,
                title: "2020: Research on COVID-19 Knowledge and Perception", 
                desc: `BU, with WHO Somalia's support, conducted a cross-sectional survey in selected Mogadishu districts to assess COVID-19 knowledge, perception, and health-seeking behavior. This research aimed to address gaps in understanding and guide public health interventions.`,
                img: []},
            {
                id: 14, 
                icon: <FaLongArrowAltRight/>,
                title: "2020: Joint Training Programs and Capacity Building", 
                desc: `BU partnered with Karolinska Institute (KI) to conduct online training programs on sustainable health management for professionals in Somalia, Uganda, and the Democratic Republic of Congo (DRC), funded by SIDA. Additionally, BU collaborated with KI, Makerere University, and Kinshasa School of Public Health on the SDGCap project, focusing on capacity development for sustainable development in fragile states.`,
                img: []},
            {
                id: 15, 
                icon: <FaLongArrowAltRight/>,
                title: "2023: Establishment of the Innovation Hub", 
                desc: `With support from the SDGCap project and partner institutions, BU launched the Innovation Hub, promoting collaboration, entrepreneurship, and sustainable development among students. This hub fosters innovation and empowers youth to address global challenges effectively`,
                img: []},

            {
                id: 16, 
                icon: <FaLongArrowAltRight/>,
                title: "2023: Collaborative Research and Healthcare Projects", 
                desc: `BU initiated collaborative projects with WHO, including studies on MERS-CoV and Cholera Vaccine Effectiveness across Somalia's regions. Additionally, BU partners with PATH for Routine Immunization Strengthening in Polio (RISP), enhancing immunization coverage and learning programs.`,
                img: []},
        {
            id: 17, 
            icon: <FaLongArrowAltRight/>,
            title: "Establishment of the Disaster Risk Reduction Centre (DRRC)", 
            desc: `In response to recurrent disasters, BU established the DRRC to build multidisciplinary capacity and expertise in disaster resilience and risk reduction in Somalia. The center offers training, research, and advisory services, supported by BU's local resources and partnerships with regional and international disaster organizations.`,
            img: []},
        {
            id: 18, 
            icon: <FaLongArrowAltRight/>,
            title: "MERS-CoV study in Somalia: preliminary quantitative results", 
            desc: `The article presents preliminary quantitative results of a study on MERS-CoV in Somalia. The study employed statistical methods, including maximum likelihood estimate (MLE) and chi-squared tests, to analyze seroprevalence data collected from 778 participants across 8 regions and 4 facility types.`,
            img: []},

]