import { http } from "./httpServices";
import api from "../config.json";

// const url = api.local + "/news";
const url = api.global + "/news";


function newsUrl(id) {
  return `${url}/${id}`;
}

export function getAllNews() {
  return http.get(url);
}

export async function getNewsHeaders(){
  const news = await getAllNews()
  return news.data
}

export function deleteNews(newsId) {
  return http.delete(newsUrl(newsId));
}

export async function getNews(newsId) {
  return http.get(newsUrl(newsId));
}

export function saveNews(news) {
  return http.post(url, news,
    {
      headers: {'Content-Type': 'multipart/form-data'}
    });
}

export function updateNews(news) {
  // console.log(news)
    return http.put(newsUrl(news._id), news,{
      headers: {'Content-Type': 'multipart/form-data'}
    });

}
