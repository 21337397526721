import nw1 from "../assets/images/news/1.jpg"
import nw2 from "../assets/images/news/2.jpg"
import nw3 from "../assets/images/news/3.jpg"
import nw4 from "../assets/images/news/4.jpg"
import nw5 from "../assets/images/news/4.jpg"
import nw6 from "../assets/images/news/6.jpg"
import nw7 from "../assets/images/news/7.jpg"
import nw8 from "../assets/images/news/8.jpg"
import nw9 from "../assets/images/news/9.jpg"
import nw10 from "../assets/images/news/10.JPG"
import nw11 from "../assets/images/news/11.jpg"
import nw12 from "../assets/images/news/12.jpg"
import nw13 from "../assets/images/news/13.jpg"

import nw21_1 from "../assets/images/news/news21/1.jpg"
import nw21_2 from "../assets/images/news/news21/2.jpg"
import nw21_3 from "../assets/images/news/news21/3.jpg"
import nw21_4 from "../assets/images/news/news21/4.jpg"
import nw21_5 from "../assets/images/news/news21/5.jpg"
import nw21_6 from "../assets/images/news/news21/6.jpg"
import nw21_7 from "../assets/images/news/news21/7.jpg"
import nw21_8 from "../assets/images/news/news21/8.jpg"
import nw21_9 from "../assets/images/news/news21/9.jpg"
import nw21_10 from "../assets/images/news/news21/10.jpg"
import nw21_11 from "../assets/images/news/news21/11.jpg"
import nw21_12 from "../assets/images/news/news21/12.jpg"
import nw21_13 from "../assets/images/news/news21/13.jpg"
import nw21_14 from "../assets/images/news/news21/14.jpg"
import nw21_15 from "../assets/images/news/news21/15.jpg"
import nw21_16 from "../assets/images/news/news21/16.jpg"
import nw21_17 from "../assets/images/news/news21/17.jpg"

import nw22_1 from "../assets/images/news/news22/5.jpg"
import nw22_2 from "../assets/images/news/news22/1.jpg"
import nw22_3 from "../assets/images/news/news22/3.jpg"
import nw22_4 from "../assets/images/news/news22/2.jpg"
import nw22_5 from "../assets/images/news/news22/4.jpg"
import nw22_6 from "../assets/images/news/news22/6.jpg"
import nw22_7 from "../assets/images/news/news22/7.jpg"
import nw22_8 from "../assets/images/news/news22/7.jpg"
import nw22_9 from "../assets/images/news/news22/9.jpg"

import nw23_1 from "../assets/images/news/news23/1.jpg"


import nw24_1 from "../assets/images/news/news24/1.jpg"
import nw24_3 from "../assets/images/news/news24/3.png"


import nw25_1 from "../assets/images/news/news25/1.jpg"
import nw25_2 from "../assets/images/news/news25/2.jpg"
import nw25_3 from "../assets/images/news/news25/3.jpg"
import nw25_4 from "../assets/images/news/news25/4.jpg"
import nw25_5 from "../assets/images/news/news25/5.jpg"
import nw25_6 from "../assets/images/news/news25/6.jpg"
import nw25_7 from "../assets/images/news/news25/7.jpg"
import nw25_8 from "../assets/images/news/news25/8.jpg"
import nw25_9 from "../assets/images/news/news25/9.jpg"
import nw25_10 from "../assets/images/news/news25/10.jpg"
import nw25_11 from "../assets/images/news/news25/11.jpg"
import nw25_12 from "../assets/images/news/news25/12.jpg"
import nw25_13 from "../assets/images/news/news25/13.jpg"
import nw25_14 from "../assets/images/news/news25/14.jpg"
import nw25_15 from "../assets/images/news/news25/15.jpg"
import nw25_16 from "../assets/images/news/news25/16.jpg"




const backet =
  "https://bu-page-assets.s3.ap-northeast-1.amazonaws.com/static-assets";
// const img1 = backet + "/news/news01/1.jpg";
// const img2 = backet + "/news/news01/2.jpg";
const img3 = backet + "/news/news03/undergrad.PNG";

export const news = [
  {
    id: 4,
    title:
      "Fostering Student Confidence and Proficiency in Communication: The English Language Department at Benadir University Introduces Students to Public Speaking.",
    slug: "Fostering-Student-Confidence",
    titleImageUrl: img3,
    titleImageCaption: "BU Photo archiev",
    newsContent: `The English Language Department at Benadir University organized an Introduction to Public Speaking Event, held at the new Prof. Addow campus on May 6, 2023.

        The event was organized by the English language department and attracted a diverse group of students from a variety of academic disciplines offered at Benadir University, focusing on the theme "positive and negative effects of social media on education."

        The students were judged by a panel of well-experienced academic speakers who evaluated the speeches based on their content, delivery, and overall impact. Overall, students spoke about the positive and negative effects social media has on education.

        The event provided an opportunity for the students to develop their public speaking skills, build their confidence and proficiency in communication, and engage with their peers on important issues. It also highlighted the importance of effective communication and the role that public speaking can play in shaping public opinion and policy.

        Speaking at the event, the dean of the faculty of education, Prof. Jeilani Kassim Said, expressed his satisfaction with the turnout and the quality of the speeches delivered. He praised the participants for their passion and commitment to making a positive impact in their communities and encouraged them to continue to use their voices to effect change.`,
    imageList: [{ id: 1, url: backet + "/news/news03/undergrad.PNG" }],
    publishedby: "BU Communications Office",
    updatedby: "BU Communications Office",
    createdAt: "May 6, 2023",
    updatedAt: "May 6, 2023",
    tag: "",
  },
  {
    id: 5,
    title:
      '"A New Milestone in Education and Innovation in Somalia": Benadir University Launches Its Innovation Hub.',
    slug: "A-New-Milestone-in-Education-and-Innovation",
    titleImageUrl: backet + "/news/news01/1.jpg",
    titleImageCaption: "BU Photo archiev",
    newsContent: `The Benadir University Innovation Hub was officially launched, marking a new milestone in the development of Somalia’s education and innovation. 
        The event, which brought different dignitaries, including government officials and university staff members, was held at the university’s main headquarters in Hodan district, Mogadishu, Somalia.
        
        The innovation hub will function as a platform for students and entrepreneurs to develop innovative solutions that address challenges and find solutions related to sustainable development, including climate change, youth empowerment, and gender inequality, by promoting research, innovation, and entrepreneurship among the university's students and the wider community.

        Speaking at the opening ceremony, the rector of Benadir University, Prof. Dr. Mohamed Mohamoud Hassan (Biday), expressed how theInnovation Hub is a crucial addition to the university's academic and research offerings. He added that the center will provide students with hands-on experience in cutting-edge technologies and enable them to develop innovative solutions to real-world problems.
        "The Benadir University Innovation Hub is a place where students can turn their ideas into reality, and we want to inspire our students to be creative thinkers and problemsolvers," said Eng. Rage Mohamed Adem, Director, Benadir Innovation Hub.
         In his remarks, the chairperson of social affairs at Benadir University, Prof. Dr. Osman Mohamud Dufle, congratulated Benadir University on the opening of the Innovation Hub, saying that it was an important step towards building a knowledge-based economy in Somalia.
        “The Benadir University Innovation Hub is open to students, faculty, and the public and is expected to become a hub for innovation and entrepreneurship in Somalia,” said Eng. Rage.
        In his closing remarks, the university rector expressed gratitude to everyone who attended the opening ceremony and highlighted that, as the university board, they committed to investing in quality education and innovation. 
        “The opening of this Innovation Hub is proof of Benadir University's progress over the years,” said the rector as he officially launched the Innovation Hub to both students and the wider Somali community.`,
    imageList: [
      { id: 1, imageUrls: backet + "/news/news01/1.jpg" },
      { id: 2, imageUrls: backet + "/news/news01/2.jpg" },
      { id: 3, imageUrls: backet + "/news/news01/3.jpg" },
    ],
    publishedby: "Abdulrazak",
    updatedby: "Aweys",
    createdAt: "July 19, 2023 17:00pm",
    updatedAt: "July 19, 2023 17:00pm",
  },
  {
    id: 6,
    title:
      "MP Applauds Benadir University's Commitment to Education Excellence During Visit",
    slug: "MP-Applauds-Benadir-University's-Commitment-to-Education-Excellence-During-Visit",
    titleImageUrl: backet + "/news/news-02/1.jpg",
    titleImageCaption: "BU Photo archiev",
    newsContent: `On Tuesday, 26th, Dr. Abdurahman Odowaa, a Member of Parliament, applauded Benadir University for its unwavering dedication to providing quality education during his courtesy visit.

        The visit, which took place at the Prof. Addow campus, served as an opportunity for the MP and university rector, Prof. Dr. Mohamed M. Hassan (Biday), to engage in discussions regarding the university's present state and future aspirations. 

        The primary objective of the visit was to strengthen the bond between Benadir University and the federal government of Somalia, shedding light on the university's pivotal role within Somalia's educational landscape.

        During his time at the university, MP conducted a thorough inspection of the university facilities, carefully assessing their development and readiness for the admission of freshmen for the 2023–24 academic year.

        Furthermore, he visited various administrative offices and personally met with dedicated staff members who contribute to the smooth operation of the university.

        In his remarks, the MP underscored the government's commitment to enhancing the education sector and increasing the enrolment of young individuals. The MP emphasised the significance of institutions like Benadir University in shaping the nation's future and expressed support for their development through various programs.

        “The MP's visit served as a testament to the significance of collaboration between the government and educational institutions in driving progress and fostering a conducive environment for learning." said the rector.

        Concluding his visit, MP extended his gratitude to Prof. Dr. Mohamed M. Hassan (Biday), the rector of Benadir University, for his exceptional leadership and outstanding achievements during his visit.`,
    imageList: [
      { id: 1, imageUrls: backet + "/news/news-02/1.jpg" },
      { id: 2, imageUrls: backet + "/news/news-02/2.jpg" },
      { id: 3, imageUrls: backet + "/news/news-02/3.jpg" },
      { id: 4, imageUrls: backet + "/news/news-02/4.jpg" },
      { id: 5, imageUrls: backet + "/news/news-02/5.jpg" },
    ],
    publishedby: "Abdulkadir",
    updatedby: "Abdulkadir",
    createdAt: "Sep 28, 2023",
    updatedAt: "Sep 28, 2023",
  },
  {
    id: 7,
    title:
      "Former Somali Ambassador Visits BU: Emphasizes Importance of Social Science Discipline",
    slug: "Former-Somali-Ambassador-Visits-BU-Emphasizes-Importance-of-Social-Science-Discipline",
    titleImageUrl: backet + "/news/news04/1.jpg",
    titleImageCaption: "BU Photo archiev",
    newsContent: `In a significant development, a former ambassador of Somalia to Qatar, H.E. Omar Sheikh Ali Idiris, paid a visit to Benadir University on Friday, September 29, at the Prof. Addow campus.
        The distinguished guest was cordially received by the university's rector, Prof. Dr. Mohamed Mohamud Hassan (Biday). His visit follows a recent notable visit by another esteemed Member of Parliament from the federal government of Somalia.
        During his visit, the former ambassador was greeted with open arms by the BU family. He took the opportunity to explore various admissions offices and familiarise himself with the learning facilities at the university.
        Engaging in discussions with the rector, staff and students, the focus shifted to the significance of the social science discipline, with particular emphasis on the role of diplomacy in shaping a prosperous Somalia.
        The former ambassador stressed the need for comprehensive training in diplomacy and international relations. He acknowledged that a better understanding of these areas would equip future leaders with the necessary skills to navigate the complexities of international affairs.
        As a highlight of the visit, the former ambassador commended Prof. Dr. Mohamed Mohamud Hassan (Biday) for the exceptional work accomplished during his tenure as the university's rector. 
        In his final remarks, he emphasised the significance of diplomatic education in training the next generation of Somali leaders. His visit served as a reminder of the pivotal role BU plays in shaping enlightened leaders who can contribute to a brighter future for Somalia.
        `,
    imageList: [
      { id: 1, imageUrls: backet + "/news/news04/11.jpg" },
      { id: 2, imageUrls: backet + "/news/news04/2.jpg" },
      { id: 3, imageUrls: backet + "/news/news04/3.jpg" },
      { id: 4, imageUrls: backet + "/news/news04/4.jpg" },
      { id: 5, imageUrls: backet + "/news/news04/5.jpg" },
      { id: 6, imageUrls: backet + "/news/news04/6.jpg" },
      { id: 7, imageUrls: backet + "/news/news04/7.jpg" },
      { id: 8, imageUrls: backet + "/news/news04/8.jpg" },
      { id: 9, imageUrls: backet + "/news/news04/9.jpg" },
      { id: 10, imageUrls: backet + "/news/news04/10.jpg" },
    ],
    publishedby: "Abdulkadir",
    updatedby: "Abdulkadir",
    createdAt: "Oct 03, 2023",
    updatedAt: "Oct 03, 2023",
  },
  {
    id: 8,
    title: "Benadir University receives over 1112 students for placement exams",
    slug: "Benadir-University-receives-over-1112-students-for-placement-exams",
    titleImageUrl: backet + "/news/news06/1.jpeg",
    titleImageCaption: "BU Photo archiev",
    newsContent: `Over a period of two days, nearly 1,500 students successfully registered for placement exams at Benadir University. The exams serve as a crucial quality control measure to ensure that only the finest students are admitted to the esteemed institution.

        The first phase of the placement exams started on Monday, October 2, 2023, held at the Prof. Addow campus. The exams were headed by the staff members overseeing the entire process to maintain a smooth and organised environment. Their diligent supervision ensured that students adhered to the rules and guidelines set forth by the university.

        The university offered a diverse array of placement exams covering essential subjects like biology, chemistry, physics, and mathematics. These subjects form the fundamental building blocks for students pursuing scientific disciplines in higher education.

        According to the exam office, they expressed optimism about the number of students who participated, considering it a promising turnout. Besides, all students will receive final communication about the results of the placement exams.

        With the successful registration of hundreds of students, the university remains committed to upholding high standards and cultivating a competitive academic environment while prioritising the delivery of quality education.`,
    imageList: [
      { id: 1, imageUrls: backet + "/news/news06/6.jpeg" },
      { id: 2, imageUrls: backet + "/news/news06/2.jpeg" },
      { id: 3, imageUrls: backet + "/news/news06/3.jpeg" },
      { id: 4, imageUrls: backet + "/news/news06/4.jpeg" },
      { id: 4, imageUrls: backet + "/news/news06/5.jpeg" },
      { id: 4, imageUrls: backet + "/news/news06/5.jpeg" },
    ],
    publishedby: "Abdulkadir",
    updatedby: "Abdulkadir",
    createdAt: "Oct 05, 2023",
    updatedAt: "Oct 05, 2023",
  },
  {
    id: 9,
    title: "Rector Attends the ERFAN Regional Meeting in Tunis",
    slug: "Rector-Attends-the-ERFAN-Regional-Meeting-in-Tunis",
    titleImageUrl: backet + "/news/news05/1.jpg",
    titleImageCaption: "BU Photo archiev",
    newsContent: `Benadir University's rector, Prof. Dr. Mohamed Mohamud Hassan, successfully represented the republic of Somalia at the ERFAN (Enhancing Research for African Network) Regional Meeting in Tunis.

        The three-day event was held at the Golden Tulip, El Mechtel Hotel in Tunis, Tunisia, from October 3–5, 2023, and it brought together heads of partner institutions and representatives from international organisations active in the region.

        The event was organised by the ERFAN Secretariat in collaboration with the World Organization for Animal Health (WOAH) sub-regional representation for North Africa and the Italian Embassy in Tunis to foster cooperation and discuss ongoing programmes and projects in North Africa.

        The meeting commenced with a presentation highlighting the achievements of the ERFAN since its establishment in 2019. Over 30 African countries, including Somalia, expressed their appreciation for the advancements in scientific cooperation between Europe and Africa.

        During the first session, new partners, including Somalia, represented by Prof. Dr. Mohamed Mohamud Hassan (Biday) from Benadir University, Mr. Abdirahman Nur from the Central Veterinary Laboratory, and H.E. Mohamed Omara Nur, Director General of Somalia's Ministry of Animal Husbandry, presented their institutions' contributions to the regional development efforts.

        The second session focused on collaborations with other international organisations involved in North African programmes and projects. Representatives from the WOAH, the World Health Organization, and the World Bank presented their ongoing initiatives and discussed potential collaboration opportunities.
        
        Looking towards the future, the third session outlined the second phase of the "ERFAN project" towards 2024. The participants emphasised the significance of enhancing Italian African cooperation and fostering collaborations among African institutions for the region's sustainable development.

        The event concluded with a summary of discussions and ERFAN's next steps, emphasizing the importance of animal husbandry, addressing food security and public health issues in Northern Africa.
        `,
    imageList: [
      { id: 1, imageUrls: backet + "/news/news05/1.jpg" },
      { id: 2, imageUrls: backet + "/news/news05/2.jpg" },
      { id: 3, imageUrls: backet + "/news/news05/3.jpg" },
      { id: 4, imageUrls: backet + "/news/news05/4.jpg" },
    ],
    publishedby: "Abdulkadir",
    updatedby: "Abdulkadir",
    createdAt: "Oct 06, 2023",
    updatedAt: "Oct 06, 2023",
  },
  {
    id: 10,
    title:
      "Djibouti ambassador extends a warm invitation to the rector for a memorable lunch in Cuba.",
    slug: "Djibouti-ambassador-extends-a-warm-invitation-to-the-rector-for-a-memorable-lunch-in-Cuba.",
    titleImageUrl: backet + "/news/news07/1.jpg",
    titleImageCaption: "BU Photo archiev",
    newsContent: `In a bid to enhance collaboration between Djibouti and Cuba in various sectors, the ambassador of Djibouti to Cuba invited Prof. Dr. Mohamed Mohamud Hassan (Biday), the Rector of Benadir University, for a lunch on June 3, 2023.

        The university rector, Prof. Dr. Biday, had travelled to Cuba on official duties to explore potential partnerships with internationally recognised universities in the country, where he received an official request from the ambassador for a luncheon.

        The distinguished lunch gathering was attended by esteemed dignitaries from the Horn of Africa, including Uganda, Kenya, and Ethiopia. Together, they engaged in productive discussions on matters of great importance, representing the institutions they serve.
        The luncheon served as a significant step towards the university's goal of establishing collaborations and partnerships with renowned institutions. These collaborations aim to foster the development of the university through meaningful research in various fields, which Prof. Dr. Biday believes is essential for finding solutions to problems, expanding knowledge, and advancing our understanding of the world.

        This event highlighted the commitment of the rector to strengthen educational ties and promote academic excellence through international cooperation. The lunch meeting provided a platform for exchanging ideas and exploring opportunities for mutual growth and advancement in the field of higher education.

        As Benadir University continues its efforts to expand its academic horizons, collaborations with well-recognized institutions in Cuba are expected to contribute to the university's progress and the overall development of the education sector in Somalia.
        `,
    imageList: [{ id: 1, imageUrls: backet + "/news/news07/1.jpg" }],
    publishedby: "Abdulkadir",
    updatedby: "Abdulkadir",
    createdAt: "June 03, 2023",
    updatedAt: "June 03, 2023",
  },
  {
    id: 11,
    title:
      "Benadir University takes steps to strengthen collaboration with the US Embassy",
    slug: "Benadir-University-takes-steps-to-strengthen-collaboration-with-the-US-Embassy",
    titleImageUrl: backet + "/news/news08/1.jpg",
    titleImageCaption: "BU Photo archiev",
    newsContent: `On October 10th, 2023, a high-level delegation from Benadir University (BU), led by the esteemed rector, held a meeting to secure collaboration with the United States embassy in Mogadishu, Somalia. The team, consisting of prominent figures from the university, was warmly received by Mr. Taylor Tinny, the US Embassy's Public Affairs Officer and representative, at the meeting.

        The meeting, held at the US Embassy, aimed to establish a strong and enduring connection between BU and the Public Affairs Office of the US Embassy in Somalia. It sought to identify potential areas of collaboration and cooperation between the two institutions.
        The BU delegate team comprised distinguished individuals such as Dr. Mohamed Mohamud Ali (Fuji), the Dean of Postgraduate Studies and Research Center; Dr. Hassan Warsame Nor Senior Advisor on Environment and Climate Change; and Mrs. Ikram Abdulaziz, Coordinator of the Innovation Center.
        
        During his opening remarks, Prof. Dr. Biday expressed his sincere gratitude to Mr. Tinny for the warm reception and introduced the BU delegate members to the US Embassy Public Affairs Officer.

        Prof. Dr. Biday took the opportunity to provide a comprehensive overview of BU, underscoring its establishment in 2002 as a response to the devastating consequences of Somalia's destruction, which led to a severe shortage of qualified health professionals in the country.

        Mr. Taylor, the US Embassy public affairs officer, revealed several potential areas for collaboration, including education, civil society capacity, and English language programs. He emphasised the embassy's dedication to supporting Somali-led initiatives and comprehensive approaches to security, democracy, and humanitarian efforts.

        This meeting between BU and the US Embassy signifies a significant step towards the university's development and a strong bond between the institutions. It reflects their commitment to promoting education, research, and sustainable development in Somalia.
        `,
    imageList: [{ id: 1, imageUrls: backet + "/news/news08/1.jpg" }],
    publishedby: "Abdulkadir",
    updatedby: "Abdulkadir",
    createdAt: "Oct 10, 2023",
    updatedAt: "Oct 10, 2023",
  },
  {
    id: 12,
    title:
      "Benadir University strengthens ties with the Turkish Embassy in Mogadishu.",
    slug: "Benadir-University-strengthens-ties-with-the-Turkish-Embassy-in-Mogadish",
    titleImageUrl: backet + "/news/news09/1.jpg",
    titleImageCaption: "BU Photo archiev",
    newsContent: `To strengthen academic collaborations between Benadir University (BU) and the Turkish Embassy, the esteemed rector, Prof. Dr. Mohamed Mohamud Hassan (Biday), has led a BU delegation to a crucial meeting with the Turkish ambassador to Somalia.
        The meeting held on Tuesday, October 24, 2023, in Mogadishu, Somalia, aimed to enhance academic partnerships between Benadir University and Turkish academic institutions, as well as extend a warm welcome to the newly appointed Turkish ambassador, H.E. İbrahim Mete YAĞLI.
        Under the guidance of the esteemed rector, BU’s delegation discussed with the Turkish ambassador the importance of solidifying ties between Benadir University and the Turkish Embassy.
        As a university, BU has a history of successful collaborations with Turkish institutions, including Istanbul Medeniyet University, Ondokuz Mayis University, and others, thanks to its strong relationship with the previous ambassador. These partnerships have resulted in the university securing various scholarships at all levels of higher education for its students from Turkish universities.
        The meeting aimed to strengthen academic connections at Benadir University, enhancing student experience and promoting knowledge exchange and cultural understanding, while also strengthening ties with the Turkish embassy.
        During the meeting, Benadir University's rector pledged to enhance academic collaborations with Turkish institutions, aiming to offer a globally oriented education and contribute to the institution's overall development.
        The meeting concluded with both parties expressing their dedication to ongoing dialogue and cooperation in academia, paving the way for potential joint research projects, faculty exchanges, and student mobility programs.
        `,
    imageList: [{ id: 1, imageUrls: backet + "/news/news09/1.jpg" }],
    publishedby: "Abdulkadir",
    updatedby: "Abdulkadir",
    createdAt: "Oct 24, 2023",
    updatedAt: "Oct 24, 2023",
  },
  {
    id: 13,
    title:
      "Sudan's acting ambassador to Somalia welcomes a delegation from Benadir University.",
    slug: "Sudan's-acting-ambassador-to-Somalia-welcomes-a-delegation-from-Benadir-University",
    titleImageUrl: backet + "/news/news10/3.jpg",
    titleImageCaption: "BU Photo archiev",
    newsContent: `The Acting Ambassador of Sudan to Somalia, H.E. Ammar Ahmed Babiker Ali, cordially received a delegation from Benadir University (BU) for a familiarisation meeting held today at the embassy of Sudan in Mogadishu, Somalia.
    The university’s top administration was led by the esteemed university rector, Prof. Dr. Mohamed Mohamud Hassan (Biday), Dr. Abdirahman Hassan Omar Mr. Mohmed, deputy rector for administration and finance, and Dr. Mohamed Mohamud Ali (Fuji), the Dean of the School of Postgraduate Studies and Research Center.
    The meeting aimed to strengthen ties between Sudan's education institutions and BU, with the top administration's presence highlighting BU's commitment to collaboration and knowledge exchange.
    During the meeting, the acting ambassador and the BU administration engaged in productive discussions, exploring avenues for academic cooperation, joint research initiatives, and student exchange programs.
    As the university rector, Prof. Dr. Biday expressed his eagerness to strengthen educational bonds, fostering a spirit of unity and knowledge sharing between BU and education institutions in Sudan.
    The visit between Sudan’s ambassador and BU marks a significant milestone in educational excellence and cross-cultural understanding, paving the way for future collaboration and mutual growth.
    `,
    imageList: [
      { id: 1, imageUrls: backet + "/news/news10/1.jpg" },
      { id: 2, imageUrls: backet + "/news/news10/2.jpg" },
      { id: 3, imageUrls: backet + "/news/news10/3.jpg" },
    ],
    publishedby: "Razak",
    updatedby: "Razak",
    createdAt: "Oct 26, 2023",
    updatedAt: "Oct 26, 2023",
  },
  {
    id: 14,
    title:
      "Prof. Dr. Saleh Aidarous Nasir Alamoud receives a warm welcome at Benadir University.",
    slug: "Prof.-Dr.-saleh-aidarous-nasir-alamoud-receives-a-warm-welcome-at-benadir-university.",
    titleImageUrl: backet + "/news/news11/1.jpg",
    titleImageCaption: "BU Photo archiev",
    newsContent: `Prof. Dr. Saleh Aidarous Nasir Alamoud, a renowned doctor who served in the country from 1970 to 1990 and recently returned, was warmly welcomed at the headquarters of Benadir University.
    The esteemed doctor was warmly received by the university's rector, Prof. Dr. Mohamed M. Hassan (Dr. Biday), at the Prof. Addow campus in Mogadishu, Somalia, and the occasion was witnessed by several administrative staff members, including academics who had been mentored by Prof. Dr. Saleh.
    During his short visit, the rector engaged in discussions with the esteemed doctor, touching upon various topics. Prof. Dr. Saleh also provided a report on the university's growth and progress since its establishment in 2002.
    Expressing gratitude, Prof. Dr. Saleh commended the university's leadership, including the current and former rectors, for their exceptional contributions to the development of Benadir University. Their collective efforts have established the university as one of the leading educational institutions in the Horn of Africa.
    The warm reception of Prof. Dr. Saleh stresses the significance of his return and highlights the commitment of Benadir University to fostering collaboration and advancing medical education in the region.
    `,
    imageList: [
      { id: 1, imageUrls: backet + "/news/news11/1.jpg" },
      { id: 2, imageUrls: backet + "/news/news11/2.jpg" },
      { id: 3, imageUrls: backet + "/news/news11/3.jpg" },
      { id: 4, imageUrls: backet + "/news/news11/4.jpg" },
    ],
    publishedby: "Razak",
    updatedby: "Razak",
    createdAt: "Oct 11, 2023",
    updatedAt: "Oct 11, 2023",
  },
  {
    id: 15,
    title: "Renowned Somali Geo-Science Professors Visit Benadir University.",
    slug: "Renowned-Somali-Geo-Science-Professors-Visit-Benadir-University",
    titleImageUrl: backet + "/news/news13/1.jpg",
    titleImageCaption: "BU Photo archiev",
    newsContent: `On Saturday, October 29th, 2023, Prof. Ibrahim Farah, a pioneer in the Faculty of Geo-Sciences at the National Somali University, and Eng. Ali Abdullahi Mohamed (Hydrology), an esteemed expert in the geoscience discipline, paid a visit to Benadir University.
    The visit took place at BU’s Prof. Addow campus in Mogadishu, Somalia, and the distinguished professors were warmly received by the rector, Prof. Dr. Mohamed Mohamud Hassan (Biday), the university rector, and Eng. Mohamed Abukar Haji Goobdoon, the newly appointed Dean of the Faculty of Geoscience and Environment.
    The purpose of their visit was to foster closer ties, emphasising the importance of collaboration between BU and other universities in Somalia for the growth and advancement of Somalia’s educational landscape.
    During their visit, the esteemed academics were given a comprehensive tour of the university's main headquarters campus, including the offices of Geoscience. They also had the opportunity to meet with various staff members, fostering a sense of establishing academic connections.
    The visit by Prof. Ibrahim Farah and Eng. Ali Abdullahi Mohamed marked a significant step towards fostering collaboration and enhancing the academic landscape at BU. In addition, the rector believes visits by such professionals will contribute to the development of the university since they provide experience and expertise.
    `,
    imageList: [
      { id: 1, imageUrls: backet + "/news/news13/1.jpg" },
      { id: 2, imageUrls: backet + "/news/news13/2.jpg" },
      { id: 3, imageUrls: backet + "/news/news13/3.jpg" },
    ],
    publishedby: "Razak",
    updatedby: "Razak",
    createdAt: "Oct 29, 2023",
    updatedAt: "Oct 29, 2023",
  },
  {
    id: 16,
    title:
      "Top university rectors foster strong ties to shape the academic landscape in Somalia.",
    slug: "Top-university-rectors-foster-strong-ties-to-shape-the-academic-landscape-in-Somalia.",
    titleImageUrl: backet + "/news/news12/1.jpg",
    titleImageCaption: "BU Photo archiev",
    newsContent: `In a heartwarming display of the two rectors of the leading universities in Somalia, Dr. Ibrahim Mohamed Mursal, the esteemed rector of Mogadishu University, paid a courtesy visit to Prof. Dr. Mohamed Mohamud Hassan (Biday), his counterpart at Benadir University, on Saturday, October 4, 2023.
    The visit took place at the Prof. Addow campus in Mogadishu, Somalia, and witnessed the presence of top administrators at Benadir University, including Prof. Jeilani Kassim Said, Director of Planning, and Dr. Abdirahman Hassan Omar, deputy rector for administration and finance.
    As part of his visit, Dr. Mursal also took the opportunity to explore BU's cutting-edge innovation hub, an active hub for developing student-fresh ideas through organised workshops, seminars, and trainings.
    With both institutions being renowned educational pillars in Somalia, their leaders have enjoyed productive years of academic collaborations and partnerships, engaging in extensive research, and organising numerous annual conferences for scholars and students across diverse disciplines.
    According to Prof. Dr. Biday, this encounter further solidified the bond between the two universities, paving the way for future joint initiatives aimed at advancing educational excellence and fostering innovation.
    As the courtesy visit came to an end, it was evident that their shared vision of fostering intellectual growth, knowledge dissemination, and embracing technological advancements would continue to shape the academic and entrepreneurial landscape in Somalia for years to come.
    `,
    imageList: [
      { id: 1, imageUrls: backet + "/news/news12/1.jpg" },
      { id: 2, imageUrls: backet + "/news/news12/2.jpg" },
      { id: 3, imageUrls: backet + "/news/news12/3.jpg" },
    ],
    publishedby: "Razak",
    updatedby: "Razak",
    createdAt: "Nov 05, 2023",
    updatedAt: "Nov 05, 2023",
  },
  {
    id: 17,
    title:
      "Benadir University Honor Professor Dr. Saleh Aidarous Nasir Alamoudi",
    slug: "Benadir-University-Honor-Professor-Dr.-Saleh-Aidarous-Nasir-Alamoudi",
    titleImageUrl: backet + "/news/news14/1.jpg",
    titleImageCaption: "BU Photo archiev",
    newsContent: `On Wednesday, October 11, 2023, a distinguished gathering, comprising ministers, MPs from the Department of Foreign Affairs, and members of the Somali Medical Association, came together with the Benadir University Foundation and University Administration to pay honour to Prof. Dr. Saleh Aidarous Nasir Alamoudi, who had served in the country between 1970 and 1990.
    The special luncheon was held in Mogadishu, Somalia, and purposely aimed to acknowledge Prof. Dr. Saleh’s contributions to Somalia's educational progress as well as celebrate his return after a prolonged period overseas.
    The event was led by Prof. Dr. Mohamed Mohamud Hassan (Biday), the rector of Benadir University, and saw the attendance of prominent administrative personnel, including faculty deans from BU. 
    During the luncheon, the Benadir University Foundation bestowed the Medal of Honor upon Prof. Dr. Saleh, recognising his immense contributions to the Somali community as a dedicated doctor.
    The prestigious award acknowledges Prof. Dr. Saleh's exceptional service and commitment to the well-being of the Somali people. It serves as a testament to his remarkable achievements and the lasting impact he has made throughout his medical career.
    In his final remarks as the event concluded, Prof. Dr. Biday expressed that the honorary luncheon didn’t only celebrate the selfless dedication of Prof. Dr. Saleh but also highlighted the BU's commitment to recognising and honouring individuals who have made significant contributions to society.
    `,
    imageList: [
      { id: 1, imageUrls: backet + "/news/news14/1.jpg" },
      { id: 2, imageUrls: backet + "/news/news14/2.jpg" },
      { id: 3, imageUrls: backet + "/news/news14/3.jpg" },
      { id: 4, imageUrls: backet + "/news/news14/3.jpg" },
      { id: 5, imageUrls: backet + "/news/news14/5.jpg" },
    ],
    publishedby: "Razak",
    updatedby: "Razak",
    createdAt: "Nov 05, 2023",
    updatedAt: "Nov 05, 2023",
  },
  {
    id: 18,
    title: "Parents commend Benadir University during a special visit.",
    slug: "Parents-commend-Benadir-University-during-a-special-visit.",
    titleImageUrl: backet + "/news/news15/1.jpg",
    titleImageCaption: "BU Photo archiev",
    newsContent: `Benadir University (BU) received high praise and commendation during a special visit by esteemed parents of students enrolled at the university. The visit took place on October 22, 2023, at the Prof. Addow campus in Mogadishu.
    The visiting parents were warmly welcomed by the university rector, Prof. Dr. Mohamed Mohamud Hassan (Biday), Prof. Jeilani Kassim Said, Director of Planning, and Dr. Abdirahman Hassan Omar, deputy rector for administration and finance.

    The delegation of visiting parents was led by prominent figures such as Mr. Abdulwahab Maalim Mohamed, the Ambassador of DFS in Al-Jeriya; Director Abirizak Ali Yusuf (Bahlavi), former Director of the Ministry of Information; and Mr. Ali Mohamed Ahmed, Director of Tourism (Federal Government of Somalia).

    The purpose of the visit was to showcase the university's progress to the parents, many of whom had children currently studying at BU, while others were proud parents of graduates who successfully completed their studies in various disciplines.

    During the visit, the parents expressed their admiration for the university's leaders, administration, and teachers, recognising their dedicated efforts in nurturing students and equipping them with extensive knowledge in various disciplines.

    After the visit, the distinguished parents commended the remarkable progress achieved by Benadir University, praising the rector for his unwavering dedication and commitment to academic excellence at the institution.
    `,
    imageList: [
      { id: 1, imageUrls: backet + "/news/news15/1.jpg" },
      { id: 2, imageUrls: backet + "/news/news15/2.jpg" },
      { id: 3, imageUrls: backet + "/news/news15/3.jpg" },
      { id: 4, imageUrls: backet + "/news/news15/4.jpg" },
      { id: 5, imageUrls: backet + "/news/news15/5.jpg" },
      { id: 6, imageUrls: backet + "/news/news15/6.jpg" },
    ],
    publishedby: "Razak",
    updatedby: "Razak",
    createdAt: "Nov 07, 2023",
    updatedAt: "Nov 07, 2023",
  },
  {
    id: 19,
    title:
      "Benadir University wins the \"Best Health University\" award at the 2023 Somali Health Awards.",
    slug: "Benadir-University-wins-the-Best-Health-University-award-at-the-2023-Somali-Health-Awards",
    titleImageUrl: backet + "/news/news16/2.jpg",
    titleImageCaption: "BU Photo archiev",
    newsContent: `In an outstanding event organised by the Somali Health Award to celebrate exceptional accomplishments in the healthcare sector, Benadir University emerged as the esteemed winner of the “Best Health University” award at the 2023 Somali Health Awards.

    The distinguished honour of being the best health university was accepted by Dr. Tahliil Abdi Afrah, Dean of the Faculty of Medicine, and Surgery, who represented the university at the ceremony.

    The grand event took place on November 10, 2023, at the Jazeera Palace Hotel in Mogadishu, Somalia. The Somali Health Awards sought to recognise the outstanding contributions made by various institutions and organisations in advancing healthcare in the country.
    Benadir University's remarkable achievements and commitment to excellence in the field of health education and research propelled them to the top, earning them the recognition of "Best Health University." 
    
    According to Dr. Tahliil Abdi Afrah, this accolade serves as a testament to the university's unwavering dedication to nurturing future healthcare professionals and promoting innovative healthcare practises in Somalia.

    The Somali Health Award's recognition of Benadir University's exemplary efforts further solidifies their position as a leading institution in the healthcare sector, inspiring others to strive for excellence in the pursuit of improved healthcare services and outcomes for the Somali population.
    `,
    imageList: [
      { id: 1, imageUrls: backet + "/news/news16/1.jpg" },
      { id: 2, imageUrls: backet + "/news/news16/2.jpg" },
    ],
    publishedby: "Razak",
    updatedby: "Razak",
    createdAt: "Nov 12, 2023",
    updatedAt: "Nov 12, 2023",
  },
  {
    id: 20,
    title:
      "Prof. Dr. Biday delivers the closing speech at the OIC-COMSTECH training.",
    slug: "Prof.-Dr.-Biday-delivers-the-closing-speech-at-the-OIC-COMSTECH-training.",
    titleImageUrl: nw9,
    titleImageCaption: "BU Photo archiev",
    newsContent: `Benadir University, in collaboration with the Organization of Islamic Cooperation (OIC) and the OIC Standing Committee on Scientific and Technological Cooperation (COMSTECH), has successfully concluded a five-day intensive training program in histopathology.

    The program, titled "OIC-COMSTECH Training Course in Histopathology: A Hybrid Learning Experience," concluded today at Benadir University's Prof. Addow Campus in Mogadishu, Somalia.

    Over the course of 5 days, more than 80 professionals attended the training, including postgraduate students, researchers, gynecologists, orthopedic specialists, and related professionals, both in person and via Zoom.

    The training sessions were conducted by distinguished scholars led by Prof. Dr. Shahid Pervez, a COMSTECH Distinguished Scholar and professor; Dr. Nasir Ud Din, General Histopathology; and Dr. Zeeshan Uddin, assistant professor and consultant histopathologist, all from the Aga Khan University, Karachi, Pakistan.

    During the closing ceremony, Prof. Dr. Mohamed Mohamud Hassan (Biday), the Rector of Benadir University, Prof. Dr. Mohamed Mohamud Ali "Fuje," the Dean of the postgraduate school, and H.E. Shirwa Abdullahi Ibrahim, the ambassador of the Somali Embassy in Pakistan, delivered inspiring speeches calling for future collaborations between the two nations.
    
    In his closing remarks, Prof. Dr. Pervez expressed his surprise at the participants' remarkable knowledge and engagement with the topic of histopathology, and he urged participants to access his book to act as a guide through different diagnostic cases in pathology.
    `,
    imageList: [
      { id: 1, imageUrls: nw5 },
      { id: 2, imageUrls: nw2 },
      { id: 3, imageUrls: nw3 },
      { id: 4, imageUrls: nw4 },
      { id: 5, imageUrls: nw6 },
      { id: 6, imageUrls: nw7 },
      { id: 7, imageUrls: nw8 },
      { id: 8, imageUrls: nw1 },
      { id: 9, imageUrls: nw10 },
      { id: 10, imageUrls: nw11 },
      { id: 11, imageUrls: nw12 },
      { id: 12, imageUrls: nw13 },
    ],
    publishedby: "Razak",
    updatedby: "Razak",
    createdAt: "Feb 05, 2024",
    updatedAt: "Feb 05, 2024",
  },
  {
    id: 21,
    title:
      "Benadir University explores Turkiye-Somalia relations at Çankırı Karatekin University.",
    slug: "Benadir-University-explores-Turkiye-Somalia-relations-at-Çankırı-Karatekin-University.",
    titleImageUrl: nw21_2,
    titleImageCaption: "BU Photo archiev",
    newsContent: `On Wednesday, March 6, 2024, the Çankırı Karatekin University African Countries Cooperation Application Research Center and Erasmus Coordination jointly organized an event titled "Turkiye-Somalia Relations."
    The event, conducted in English, was held at the Faculty of Economics and Administrative Sciences Red Hall, Çankırı, Turkiye, and attracted a diverse audience comprising academicians and administrative staff from Benadir University, Somalia.
    This was held as part of the Erasmus exchange program, where Benadir University successfully exchanged students with the hosting university for the 2023–24 academic year from different faculties.
    Dr. Abdullah METİN, Director of the African Countries Cooperation Application Research Center, delivered an opening speech, moderated by Dr. Serkan ÜNAL, who interviewed Dr. Ahmed Abdikadir from Benadir University.
    At the event, Prof. Dr. Yavuz DEMİREL, the Vice Rector and Dean of the Faculty of Economics and Administrative Sciences at Çankırı Karatekin University, made a notable appearance and held great interest from academicians, administrative staff, and students.
    During the discussion, Dr. Ahmed discussed the economic, cultural, and diplomatic ties between Somalia and Turkey, highlighting Turkiye's aid efforts and the Turkish Cooperation and Coordination Agency’s (TIKA) work in Somalia. He highlighted Somalia's strategic location and abundant resources.
    Dr. Ahmed's visit to Çankırı Karatekin University is part of the Erasmus program, which provided him with an opportunity to present captivating visuals that highlight Somalia's promotional activities to the rest of the world.
    The event concluded with a memorable photo session, and the conversation on “Turkiye-Somalia Relations” held at Çankırı Karatekin University served as a platform for fostering mutual understanding and cooperation between the two nations.    
    `,
    imageList: [
      { id: 1, imageUrls: nw21_1 },
      { id: 2, imageUrls: nw21_2 },
      { id: 3, imageUrls: nw21_3 },
      { id: 4, imageUrls: nw21_4 },
      { id: 5, imageUrls: nw21_5 },
      { id: 6, imageUrls: nw21_6 },
      { id: 7, imageUrls: nw21_7 },
      { id: 8, imageUrls: nw21_8 },
      { id: 9, imageUrls: nw21_9 },
      { id: 10, imageUrls: nw21_10 },
      { id: 11, imageUrls: nw21_11 },
      { id: 12, imageUrls: nw21_12 },
      { id: 13, imageUrls: nw21_13 },
      { id: 14, imageUrls: nw21_14 },
      { id: 15, imageUrls: nw21_15 },
      { id: 16, imageUrls: nw21_16 },
      { id: 17, imageUrls: nw21_17 },
    ],
    publishedby: "Razak Dirie",
    updatedby: "Razak Dirie",
    createdAt: "March 07, 2024",
    updatedAt: "March 07, 2024",
  },
  {
    id: 22,
    title:
      "COMSTECH, Benadir University, and the Al-Shifa Trust team jointly conduct a free eye surgery camp in Somalia.",
    slug: "COMSTECH,-Benadir-University,-and-the-Al-Shifa-Trust-team-jointly-conduct-a-free-eye-surgery-camp-in-Somalia.",
    titleImageUrl: nw22_1,
    titleImageCaption: "BU Photo archiev",
    newsContent: `Benadir University, in collaboration with COMSTECH, organized a free eye cataract surgery camp in Mogadishu, a joint effort between the Ministerial Standing Committee on Scientific and Technological Cooperation (COMSTECH), Al-Shifa Trust Eye Hospital Pakistan, and the Al-Nur Foundation, Somalia.

    A 10-day free eye surgery care camp was held at Dalmar Specialized and Teaching Hospital in Mogadishu, Somalia, from March 1st to 10th, 2024, which aimed to eradicate avoidable blindness among the Somali population.

    The team from Mogadishu was led by Prof. Dr. Mohamed Mahmud Hassan (Bidey), Rector of Benadir University; Prof. Dr. Mohamed Mohamud Ali "Fuje," Dean of the postgraduate school and the focal point for COMSTECH; and Prof. Dr. Abdirisak Dalmar, former rector of Benadir University and ophthalmologist at Dalmar Hospital.

    The medical team from Al-Shifa Trust Eye Hospital in Pakistan, led by Dr. Abdur Rasheed from COMSTECH Health Initiative, comprised four ophthalmologists and two OT technicians who departed for Mogadishu under the guidance of COMSTECH Coordinator General, Prof. Dr. M. Iqbal Choudhary.

    The dedicated team from Al-Shifa Trust Eye Hospital reached out to more than 400 patients out of 4623 who were screened for surgeries. The patients were treated according to WHO protocols, prioritizing patient safety and quality of care. Additionally, the Al-Shifa team not only performed surgeries but also provided training to doctors at Dalmar Hospital in cataract surgeries, enhancing their skills.

    Mr. Hamza Adan Haadoow, Permanent Secretary of the Ministry of Foreign Affairs and International Cooperation of the Republic of Somalia, commended COMSTECH and Al-Shifa Trust Eye Hospital for their commendable efforts in eliminating avoidable blindness in Somalia.
    The eye surgery camp demonstrated that Benadir University is dedicated to quality education and community outreach, promoting healthcare accessibility, and making a positive impact in Somalia through collaborative initiatives. 
    `,
    imageList: [
      { id: 1, imageUrls: nw22_7 },
      { id: 2, imageUrls: nw22_2 },
      { id: 3, imageUrls: nw22_3 },
      { id: 4, imageUrls: nw22_4 },
      { id: 5, imageUrls: nw22_5 },
      { id: 6, imageUrls: nw22_6 },
      { id: 7, imageUrls: nw22_1 },
      { id: 8, imageUrls: nw22_8 },
      { id: 9, imageUrls: nw22_9 },
    ],
    publishedby: "Razak",
    updatedby: "Razak",
    createdAt: "March 11, 2024",
    updatedAt: "March 11, 2024",
  },
  {
    id: 24,
    title:
      "A Message for Ramadan.",
    slug: "A-Message-for-Ramadan",
    titleImageUrl: nw23_1,
    titleImageCaption: "BU Photo archiev",
    newsContent: `
In the name of the Most Gracious, the Most Merciful,
    
Assalam Alaykum and Ramadan Mubarak to all staff, faculty members, students, and the Somali community!.

On behalf of the entire Benadir University administration, I extend warm greetings and heartfelt wishes for a blessed month. May this Ramadan be a time of enlightenment and positive transformation for each one of you. 
As an esteemed institution of higher learning, we extend our heartfelt wishes for your fasting to be accepted, as we believe in the power of prayer. May Allah (SWT) strengthen your fasting, strengthen your virtuous acts, and bring healing and transformation to your mind, heart, and relationships.

During this sacred month, "Ramadan,” let us not only engage in spiritual reflection but also extend our prayers and aspirations for our beloved country. May Allah (SWT) grant peace, reconciliation, and harmony to our nation, and may our students be blessed with knowledge that enlightens their minds and empowers them to positively contribute to society.

May the blessings of Ramadan bring us closer to one another, foster understanding, unity, cooperation, and diversity within our institution, while also strengthening the bonds of love and brotherhood.

Ramadan Mubarak to you all!

Rectors’ Office

Benadir University
`,
    imageList: [
      { id: 1, imageUrls: nw23_1 },
    ],
    publishedby: "Razak",
    updatedby: "Razak",
    createdAt: "March 12, 2024",
    updatedAt: "March 12, 2024",
  }
,
{
  id: 23,
  title:
    "Benadir University backs ex-Foreign Minister Fozia Hagi Adam for AUC Chairperson.",
  slug: "Benadir-University-backs-ex-Foreign-Minister-Fozia-Hagi-Adam-for-AUC-Chairperson.",
  titleImageUrl: nw24_1,
  titleImageCaption: "BU Photo archiev",
  newsContent: `
In an exceptional move, Benadir University has officially joined in support of former Foreign Minister Fozia Hagi Adam for the African Union Commission (AUC) Chairperson Seat, scheduled to take place in 2025.

Benadir University's formal endorsement of Fozia Hagi Adam is part of a larger effort across Somalia aimed at rallying support for her candidacy to become the next AUC Chairman. The university's support is seen as a significant boost to her candidacy, highlighting her potential to effectively represent Somalia on the international stage.

Fozia Hagi Adam, who made history as Somalia's first female Foreign Minister during President Hassan Sheikh Mohamud's first term, now plans to run for the AUC chairman seat with the full support of her home government. The former foreign minister has a clear mission: to highlight African voices and advance the African agenda.

The former Foreign Minister expressed her deep love for Africa and her unwavering commitment to its growth and development in a recent address to the nation. “By running for the chairperson of the AU, I aim to strengthen the bonds between African countries and work towards a more united and integrated continent," she said.

Benadir University's endorsement recognizes Fozia Hagi Adam’s remarkable qualifications and ability to effectively lead the African Union Commission. The university's endorsement strengthens her candidacy and demonstrates her ability to handle the difficult processes of African diplomacy.
`,
  imageList: [
    { id: 1, imageUrls: nw24_3 },
    { id: 2, imageUrls: nw24_1 },
  ],
  publishedby: "Razak",
  updatedby: "Razak",
  createdAt: "April 03, 2024",
  updatedAt: "April 03, 2024",
},
{
  id: 25,
  title:
    "BU’s-6th-Ramadan-Iftar-celebrates-outstanding-administrators.",
  slug: "BU’s-6th-Ramadan-Iftar-celebrates-outstanding-administrators.",
  titleImageUrl: nw25_1,
  titleImageCaption: "BU Photo archiev",
  newsContent: `
  On April 5, 2024, Benadir University (BU) celebrated its 6th Ramadan Iftar, which highlighted the university's commitment to fostering unity and cultural bonding among its employees. The event was led by the esteemed rector, Prof. Dr. Mohamed Mohamud Hassan (Bidey), and it took place at the Silver Star Hotel in Mogadishu, Somalia.

  The evening commenced with a recitation of a Qur'anic verse from Surah-Baqarah Ayah 183 by Dr. Abdallah Mohamed Al-Bashir, a senior lecturer at the faculty of sharia and law. The verse emphasized the significance of fasting in attaining righteousness, setting the spiritual tone for the gathering.

  Dr. Abdirahman Hassan Omar, the deputy rector for administration and finance and Dean, Faculty of Shari’a and Law, delivered opening remarks, extending a warm welcome to all the university staff members in attendance. He expressed gratitude for their dedication to the institution and reminded them of the immense blessings associated with the holy month of Ramadan.

  Mr. Daud Mageni, a senior lecturer at the faculty of management and economics and a convert to Islam, took the opportunity to express his appreciation for the event's organization and offered heartfelt prayers for a blessed Ramadan on behalf of international lecturers.

  As rector of BU, Prof. Dr. Bidey, joined by all staff, hailed senior management administrators on their recent completion of Doctor of Philosophy (PhD) studies in different disciplines with recognition awards.

  Dr. Hassan Kafi, Senior Environment and Climate Change Advisor, BU, and Dr. Yonis Khalif Elmi, Dean of the Faculty of Engineering, were honored with prestigious awards at the ceremony. H.E. Professor Dr. Osman Mohamud Dufle, Senator of the Upper House of the Federal Parliament of Somalia and former dean of BU's postgraduate school, presented the recognition awards.

  In his closing remarks, Prof. Dr. Bidey expressed his gratitude to the BU administration and international lecturers from Uganda, Sudan, Cameroon, and Botswana. He acknowledged their significant contributions to the university's reputation and commitment to maintaining high education standards. 

  The 6th Ramadan Iftar at BU symbolized unity, cultural exchange, and academic excellence, uniting the BU family and promoting brotherhood during the holy month.
  `,
  imageList: [
    { id: 1, imageUrls: nw25_3 },
    { id: 2, imageUrls: nw25_1 },
    { id: 3, imageUrls: nw25_2 },
    { id: 4, imageUrls: nw25_4 },
    { id: 5, imageUrls: nw25_5 },
    { id: 6, imageUrls: nw25_6 },
    { id: 7, imageUrls: nw25_7 },
    { id: 8, imageUrls: nw25_8 },
    { id: 9, imageUrls: nw25_9 },
    { id: 10, imageUrls: nw25_10 },
    { id: 11, imageUrls: nw25_11 },
    { id: 12, imageUrls: nw25_12 },
    { id: 13, imageUrls: nw25_13 },
    { id: 14, imageUrls: nw25_14 },
    { id: 15, imageUrls: nw25_15 },
    { id: 16, imageUrls: nw25_16 },
  ],
  publishedby: "Razak",
  updatedby: "Razak",
  createdAt: "April 06, 2024",
  updatedAt: "April 06, 2024",
}


];
