import React, { useState } from 'react'
import _ from "lodash";
import ReactPlayer from "react-player"
import shahid from "../../../assets/images/profle/campus/1.jpg"
import adaw from "../../../assets/images/profle/campus/2.jpg"
import Library from "../../../assets/images/profle/campus/3.jpg"
const Innaguration = () => {
    const inna = `
    Dear esteemed guests, parents, faculty members, students, and staff,

I am Prof.Dr. Mohamed Mohamoud Hassan (Dr. Biday), a medical doctor from Somalia. I am the founder and rector of Benadir University, which was established in Mogadishu during the civil war period in Somalia in 2002. I have been part and parcel of the university's development, dedicating 22 years of service in various administrative positions.

Welcome to the inauguration ceremony of Benadir University! Today is a significant moment as we embark on a journey towards excellence in education, research, and community service.
This milestone is the result of the collective efforts and unwavering commitment of many individuals. Benadir University stands as a testament to their vision, dedication, and hard work in establishing an institution that upholds academic excellence and integrity.

Our mission at Benadir University is to create an environment that fosters intellectual curiosity, innovation, and inclusivity. We aim to empower future leaders, scholars, and changemakers who will make meaningful contributions to society with compassion, competence, and creativity.
As we begin this new chapter, let us reinforce our shared values of scholarship, collaboration, and service. Together, we will embrace opportunities for growth, learning, and discovery, working towards our vision of a brighter and more prosperous future.

We extend our heartfelt gratitude to our founders, benefactors, faculty, staff, students, and partners who have supported us throughout this journey. Your belief in our mission and unwavering support have played a pivotal role in reaching this important day.

As we inaugurate Benadir University, let us look forward with optimism, determination, and hope. Together, we will strive for excellence and make a lasting impact on the world.

Thank you for joining us on this momentous occasion. May this inauguration ceremony be a celebration of our achievements, a reaffirmation of our values, and an inspiration for the journey ahead.


Warm regards,

Prof.Dr. Mohamed Mohamoud Hassan,

Rector,

Benadir University.
` 
  return (
    <div className='flex flex-col justify-center items-center'>
      <div className='md:w-[800px] md:h-[500px] xl:w-[800px] xl:h-[500px]  my-3'>
      <ReactPlayer
                    url='https://www.youtube.com/watch?v=ClX_810xKX8'
                    width='100%'
                    height='100%'
                />
      </div>
       <p className='text-black text-xl xs:text-sm sm:text-sm font-montserrat whitespace-pre-line my-5 mx-5'>
        {inna}
       </p>
    </div>
)
}

export default Innaguration