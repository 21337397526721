import React, {useState, useEffect} from 'react'
import ActivityCard from "./activityCard"
import {Link, useNavigate} from 'react-router-dom'
import { news } from "../../../data/news"
import {getAllActivity} from "../../../services/activityServices"


const Activity = () => {
  const [activityList, setActivityList] = useState([]);

  useEffect( () => {

    async function fetchData() {
        const response = await getAllActivity();
        // const userNames = response.data.map(res => res.username)
        setActivityList(response.data);
      }
      fetchData();
  },[activityList])

  return (
    <div className='py-5 px-4 bg-slate-50 shadow-lg m-2 rounded-md'>
        <div className='space-x-4  py-3 px-3 mx-2  flex justify-between w-full'>
                <input
                    type="text"
                    placeholder="Search Here"
                    className="relative w-full py-2 px-3 outline-none rounded-md"
                />

            <Link to="/activityEntery" className="w-fit font-medium bg-blue-700 text-white dark:text-blue-500 hover:bg-blue-900 px-3 py-1 rounded-lg shadow-lg flex justify-center items-center whitespace-nowrap ">Create activity</Link>
        </div>
        <hr className='my-2 ' />
        <div className="relative w-full h-screen ">
           <ActivityCard  activity={activityList}/>
        </div>
    </div>
  )
}

export default Activity